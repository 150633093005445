import React, { ReactNode, useMemo } from 'react';
import { OfferDiscountDTO } from 'appAPITypes';
import { FrontCommonMessages } from 'appCommonTranslations';
import { groupBy, last, orderBy, sumBy } from 'lodash';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { getAllWithIds, getRecordValues } from 'utils/lodashUtils';
import {
  FloorPlanConfig,
  OrderAddress,
  ScanConfig,
  SelectedOffers,
  VideoConfig,
} from '../context/orderPageContextState';
import { isBrowser } from '../../../utils/gatsbyUtils';
import {
  ShCountryConfigs,
  ShCurrency,
  ShOfferPhotoOptionCategories,
  ShOfferPhotoOptionCategory,
  ShOfferTypes,
  ShShootingType,
} from '@shoootin/config';
import { useShTranslate } from '@shoootin/translations';
import {
  ShOfferType,
  ShOfferCustomDTO,
  ShOfferDTO,
  ShOfferFloorPlanDTO,
  ShOfferOptionDTO,
  ShOfferPhotoDTO,
  ShOfferScanDTO,
  ShOfferVideoDTO,
} from '@shoootin/api';

export const useOfferCustomComponentsString = (
  offer?: ShOfferCustomDTO,
): string => {
  const translate = useShTranslate();
  return useMemo(() => {
    return [
      offer &&
        offer.photo &&
        translate(FrontCommonMessages.nPhotos, { n: offer.photo.photosNb }),
      offer && offer.video && translate(FrontCommonMessages.video),
      offer && offer.scan && translate(FrontCommonMessages.scan),
      offer && offer.floorPlan && translate(FrontCommonMessages.floorPlan),
    ]
      .filter(Boolean)
      .join(' - ');
  }, [offer]);
};

export type OffersByType = {
  photo: ShOfferPhotoDTO[];
  video: ShOfferVideoDTO[];
  scan: ShOfferScanDTO | undefined;
  floorPlan: ShOfferFloorPlanDTO | undefined;
  custom: ShOfferCustomDTO[];
  drone: ShOfferCustomDTO[];
} & Record<ShOfferType, unknown>;

export const groupOffersByType = (
  offers: ShOfferDTO[],
  shootingTypeFilter?: ShShootingType,
): OffersByType => {
  const filteredOffers: ShOfferDTO[] = offers.filter(
    (offer) =>
      shootingTypeFilter
        ? offer.shootingTypes.includes(shootingTypeFilter)
        : true, // No filter provided: keep original list
  );

  const groupedOffersByOfferType = groupBy(
    filteredOffers,
    (offer) => offer.offerType,
  ) as any;

  const groupedOffers = groupBy(
    groupedOffersByOfferType.classic,
    (offer) => offer.type,
  ) as any;

  // There can be only one Matterport / Scan Offer so when there is more than 1 offer,
  // we need to filter the offer that is created specifically for the client

  const scanOffer = groupedOffers.scan
    ? groupedOffers.scan.length > 1
      ? groupedOffers.scan.filter((offer: ShOfferDTO) => offer.forClient)[0]
      : groupedOffers.scan[0]
    : undefined;

  const floorPlanOffer = groupedOffers.floorPlan
    ? groupedOffers.floorPlan.length > 1
      ? groupedOffers.floorPlan.filter(
          (offer: ShOfferDTO) => offer.forClient,
        )[0]
      : groupedOffers.floorPlan[0]
    : undefined;

  return {
    photo: groupedOffers.photo || [],
    video: groupedOffers.video || [],
    scan: scanOffer,
    floorPlan: floorPlanOffer,
    custom: groupedOffers.custom || [],
    drone: groupedOffersByOfferType.aerial || [],
  };
};

export type OfferOptionByCategory = Partial<
  Record<ShOfferPhotoOptionCategory, ShOfferOptionDTO[]>
>;
export const groupOfferOptionsByCategory = (
  options: ShOfferOptionDTO[],
  shootingTypeFilter?: ShShootingType,
): OfferOptionByCategory => {
  const filteredOptions: ShOfferOptionDTO[] =
    options &&
    options.filter(
      (option) =>
        shootingTypeFilter
          ? option.shootingTypes.includes(shootingTypeFilter)
          : true, // No filter provided: keep original list
    );
  const groupedOffers = groupBy(filteredOptions, (offer) => offer.category);
  return groupedOffers as OfferOptionByCategory;
};

export const computePhotoTotalNb = (
  photoOffer: ShOfferPhotoDTO,
  options: ShOfferOptionDTO[],
): number => photoOffer.photo.photosNb + sumBy(options, (o) => o.photosNb);

export const computePhotoTotalTime = (
  photoOffer: ShOfferPhotoDTO,
  options: ShOfferOptionDTO[],
): number => photoOffer.baseTime + sumBy(options, (o) => o.baseTime);

export const computePhotoTotalPrice = (
  photoOffer: ShOfferPhotoDTO,
  options: ShOfferOptionDTO[],
): number => photoOffer.basePrice + sumBy(options, (o) => o.basePrice);

const computeVideoTotalPrice = (videoOffer: ShOfferVideoDTO) =>
  videoOffer.basePrice; // TODO option music

const computeVideoTotalTime = (videoOffer: ShOfferVideoDTO) =>
  videoOffer.baseTime;

export const computeScanElements = (
  scanOffer: ShOfferCustomDTO | ShOfferScanDTO,
  scanConfig: ScanConfig,
): ReactNode[] => {
  const elements: ReactNode[] = [];
  const metricSystem = ShCountryConfigs[scanOffer.country].metricSystem;
  if (scanConfig.surface > 0) {
    elements.push(
      <>
        Surface : {scanConfig.surface}{' '}
        {metricSystem ? (
          <>
            m<sup>2</sup>
          </>
        ) : (
          <>sqft</>
        )}
      </>,
    );
  }

  if (scanConfig.floorPlan) {
    elements.push(<>Plan 2D</>);
  }
  if (scanOffer.scan!.addExtraModel && scanConfig.extraSpace > 0) {
    elements.push(<>{scanConfig.extraSpace} Extra spaces</>);
  }

  return elements;
};

export const computeScanMaxSurface = (scanOffer: ShOfferScanDTO) =>
  last(scanOffer.scan.priceRanges)!.upperRange - 1; // -1 because range 900-1000 is actually 900 to 999

export const computeScanSurfacePrice = (
  scanOffer: ShOfferScanDTO | ShOfferCustomDTO,
  scanConfig: ScanConfig,
) => {
  const { surface } = scanConfig;
  const range = scanOffer.scan!.priceRanges.find(
    (r) => surface >= r.lowerRange && surface < r.upperRange,
  );
  if (range) {
    return range.priceWith3D;
  } else {
    throw new Error(`Range not found for surface=${surface}`);
  }
};

const computeScanTotalPrice = (
  scanOffer: ShOfferScanDTO | ShOfferCustomDTO,
  scanConfig: ScanConfig,
) => {
  const { floorPlanPrice, extraModelPrice } = scanOffer.scan!;
  const { floorPlan, extraSpace } = scanConfig;
  const rangePrice = computeScanSurfacePrice(scanOffer, scanConfig);
  const planPrice = floorPlan ? floorPlanPrice : 0;
  const spacesPrice = extraSpace * extraModelPrice;
  return rangePrice + planPrice + spacesPrice;
};

const computeScanTotalTime = (
  offer: ShOfferScanDTO | ShOfferCustomDTO,
  scanConfig: ScanConfig,
) => {
  const init = offer.scan!.timeCalculatorInitWith3D;
  const multi = offer.scan!.timeCalculatorMultiWith3D;

  const min = offer.type === 'custom' ? 0 : 45;
  const minutes = Math.max(min, multi * scanConfig.surface + init);
  const step = 15;
  const inv = 1.0 / step;
  console.log('computeScanTotalTime', {
    offer,
    minutes,
    surface: scanConfig.surface,
    round: offer.baseTime + Math.ceil(minutes * inv) / inv,
  });
  return offer.baseTime + Math.ceil(minutes * inv) / inv;
};

// Floor plan computation

export const computeFloorPlanElements = (
  floorPlanOffer: ShOfferCustomDTO | ShOfferFloorPlanDTO,
  floorPlanConfig: FloorPlanConfig,
): ReactNode[] => {
  const elements: ReactNode[] = [];
  const metricSystem = ShCountryConfigs[floorPlanOffer.country].metricSystem;
  if (floorPlanConfig.surface > 0) {
    elements.push(
      <>
        Surface : {floorPlanConfig.surface}{' '}
        {metricSystem ? (
          <>
            m<sup>2</sup>
          </>
        ) : (
          <>sqft</>
        )}
      </>,
    );
  }

  return elements;
};

export const computeFloorPlanMaxSurface = (offer: ShOfferFloorPlanDTO) =>
  last(offer.floorPlan.priceRanges)!.upperRange - 1; // -1 because range 900-1000 is actually 900 to 999

export const computeFloorPlanSurfacePrice = (
  floorPlanOffer: ShOfferFloorPlanDTO | ShOfferCustomDTO,
  floorPlanConfig: FloorPlanConfig,
) => {
  const { surface } = floorPlanConfig;
  const range = floorPlanOffer.floorPlan!.priceRanges.find(
    (r) => surface >= r.lowerRange && surface < r.upperRange,
  );
  if (range) {
    return range.priceWith3D;
  } else {
    throw new Error(`Range not found for surface=${surface}`);
  }
};

const computeFloorPlanTotalPrice = (
  floorPlanOffer: ShOfferFloorPlanDTO | ShOfferCustomDTO,
  floorPlanConfig: FloorPlanConfig,
) => {
  return computeFloorPlanSurfacePrice(floorPlanOffer, floorPlanConfig);
};

const computeFloorPlanTotalTime = (
  offer: ShOfferFloorPlanDTO | ShOfferCustomDTO,
  floorPlanConfig: FloorPlanConfig,
) => {
  const init = offer.floorPlan!.timeCalculatorInit;
  const multi = offer.floorPlan!.timeCalculatorMulti;

  const min = 0; // TODO for the moment floor plan is only with combined offers  : offer.type === 'custom' ? 0 : 45;
  const minutes = Math.max(min, multi * floorPlanConfig.surface + init);
  const step = 15;
  const inv = 1.0 / step;
  console.log('computeFloorPlanTotalTime', {
    offer,
    minutes,
    surface: floorPlanConfig.surface,
    round: offer.baseTime + Math.ceil(minutes * inv) / inv,
  });
  return offer.baseTime + Math.ceil(minutes * inv) / inv;
};

// The order summary is generally fetched from backend to be sure we are in sync
// Yet we need to compute prices locally for UX reasons where we don't want to wait for a fetch to have the local price updated
export type OrderSummaryComputed = {
  offers: {
    photo: ShOfferPhotoDTO | undefined;
    photoOptions: ShOfferOptionDTO[];
    video: ShOfferVideoDTO | undefined;
    videoConfig: VideoConfig | undefined;
    scan: ShOfferScanDTO | undefined;
    scanConfig: ScanConfig | undefined;
    floorPlan: ShOfferFloorPlanDTO | undefined;
    floorPlanConfig: FloorPlanConfig | undefined;
    custom: ShOfferCustomDTO | undefined;
  };
  photoTotalNb: number;
  photoTotalPrice: number;
  videoTotalPrice: number;
  scanTotalPrice: number;
  floorPlanTotalPrice: number;
  photoTotalTime: number;
  videoTotalTime: number;
  scanTotalTime: number;
  floorPlanTotalTime: number;
  totalPrice: number;
  totalTime: number;
  discountValue: number;
  extraCharge: number;
};

const EmptyOrderSummary: OrderSummaryComputed = {
  offers: {
    photo: undefined,
    photoOptions: [],
    video: undefined,
    videoConfig: undefined,
    scan: undefined,
    scanConfig: undefined,
    floorPlan: undefined,
    floorPlanConfig: undefined,
    custom: undefined,
  },
  photoTotalNb: 0,
  photoTotalPrice: 0,
  photoTotalTime: 0,
  videoTotalPrice: 0,
  videoTotalTime: 0,
  scanTotalPrice: 0,
  scanTotalTime: 0,
  floorPlanTotalTime: 0,
  floorPlanTotalPrice: 0,
  totalPrice: 0,
  totalTime: 0,
  discountValue: 0,
  extraCharge: 0,
};

export const computeOrderSummary = (
  offers: ShOfferDTO[],
  options: ShOfferOptionDTO[],
  selectedOffers: SelectedOffers,
  shootingType: ShShootingType,
  discount: OfferDiscountDTO,
  extraCharge: number,
): OrderSummaryComputed => {
  const offersByType = groupOffersByType(offers, shootingType);

  const photoOffer = offersByType.photo.find(
    (o) => o.id === selectedOffers.photoOfferId,
  );
  const videoOffer = offersByType.video.find(
    (o) => o.id === selectedOffers.videoOfferId,
  );
  const scanOffer = [offersByType.scan].find(
    (o) => !!o && o.id === selectedOffers.scanOfferId,
  );

  const floorPlanOffer = [offersByType.floorPlan].find(
    (o) => !!o && o.id === selectedOffers.floorPlanOfferId,
  );

  const customOffer = offersByType.custom.find(
    (o) => o.id === selectedOffers.customOfferId,
  );

  const droneOffer = offersByType.drone.find(
    (o) => o.id === selectedOffers.customOfferId,
  );

  const photoOptionIds = getRecordValues(selectedOffers.photoConfig.options);
  const photoOptions = orderBy(
    getAllWithIds(options, photoOptionIds),
    (option) => getCategoryPosition(option.category),
  );
  if (photoOffer || videoOffer || scanOffer || floorPlanOffer) {
    return computeOrderSummaryForCombined(
      photoOffer,
      videoOffer,
      scanOffer,
      floorPlanOffer,
      photoOptions,
      selectedOffers,
      discount,
      extraCharge,
    );
  } else if (customOffer) {
    return computeOrderSummaryForCustom(
      customOffer,
      selectedOffers,
      extraCharge,
    );
  } else if (droneOffer) {
    return computeOrderSummaryForCustom(
      droneOffer,
      selectedOffers,
      extraCharge,
    );
  } else {
    console.log('empty order summary');
    return EmptyOrderSummary;
  }
};

export const computeOrderSummaryForCombined = (
  photoOffer: ShOfferPhotoDTO | undefined,
  videoOffer: ShOfferVideoDTO | undefined,
  scanOffer: ShOfferScanDTO | undefined,
  floorPlanOffer: ShOfferFloorPlanDTO | undefined,
  photoOptions: ShOfferOptionDTO[],
  selectedOffers: SelectedOffers,
  discount: OfferDiscountDTO,
  extraCharge: number,
): OrderSummaryComputed => {
  const photoTotalNb = photoOffer
    ? computePhotoTotalNb(photoOffer, photoOptions)
    : 0;
  const photoTotalPrice = photoOffer
    ? computePhotoTotalPrice(photoOffer, photoOptions)
    : 0;
  const photoTotalTime = photoOffer
    ? computePhotoTotalTime(photoOffer, photoOptions)
    : 0;

  const videoTotalPrice = videoOffer ? computeVideoTotalPrice(videoOffer) : 0;
  const videoTotalTime = videoOffer ? computeVideoTotalTime(videoOffer) : 0;

  const scanTotalPrice = scanOffer
    ? computeScanTotalPrice(scanOffer, selectedOffers.scanConfig)
    : 0;
  const scanTotalTime = scanOffer
    ? computeScanTotalTime(scanOffer, selectedOffers.scanConfig)
    : 0;

  const floorPlanTotalPrice = floorPlanOffer
    ? computeFloorPlanTotalPrice(floorPlanOffer, selectedOffers.floorPlanConfig)
    : 0;
  const floorPlanTotalTime = floorPlanOffer
    ? computeFloorPlanTotalTime(floorPlanOffer, selectedOffers.floorPlanConfig)
    : 0;

  let discountValue;
  if (photoOffer && videoOffer && scanOffer) {
    discountValue = discount.discountPhotoScanVideo;
  } else if (photoOffer && videoOffer) {
    discountValue = discount.discountPhotoVideo;
  } else if (photoOffer && scanOffer) {
    discountValue = discount.discountPhotoScan;
  } else if (scanOffer && videoOffer) {
    discountValue = discount.discountPhotoScanVideo;
  } else {
    discountValue = 0;
  }

  const totalPrice =
    photoTotalPrice +
    videoTotalPrice +
    scanTotalPrice +
    floorPlanTotalPrice -
    discountValue +
    extraCharge;
  const totalTime =
    photoTotalTime + videoTotalTime + scanTotalTime + floorPlanTotalTime;

  return {
    offers: {
      photo: photoOffer,
      photoOptions,
      video: videoOffer,
      videoConfig: selectedOffers.videoConfig,
      scan: scanOffer,
      scanConfig: selectedOffers.scanConfig,
      floorPlan: floorPlanOffer,
      floorPlanConfig: selectedOffers.floorPlanConfig,
      custom: undefined,
    },
    photoTotalNb,
    photoTotalPrice,
    photoTotalTime,
    videoTotalPrice,
    videoTotalTime,
    scanTotalPrice,
    scanTotalTime,
    floorPlanTotalPrice,
    floorPlanTotalTime,
    totalPrice,
    totalTime,
    discountValue,
    extraCharge,
  };
};

export const computeOrderSummaryForCustom = (
  customOffer: ShOfferCustomDTO,
  selectedOffers: SelectedOffers,
  extraCharge: number,
): OrderSummaryComputed => {
  const photoTotalNb =
    customOffer.photo && customOffer.photo.photosNb
      ? customOffer.photo.photosNb
      : 0;
  const totalPrice =
    customOffer.basePrice +
    extraCharge +
    (customOffer.scan
      ? computeScanTotalPrice(customOffer, selectedOffers.scanConfig)
      : 0) +
    (customOffer.floorPlan
      ? computeFloorPlanTotalPrice(customOffer, selectedOffers.floorPlanConfig)
      : 0);
  const totalTime =
    (customOffer.scan
      ? computeScanTotalTime(customOffer, selectedOffers.scanConfig)
      : 0) +
    (customOffer.floorPlan
      ? computeFloorPlanTotalTime(customOffer, selectedOffers.floorPlanConfig)
      : 0);

  return {
    offers: {
      photo: undefined,
      photoOptions: [],
      video: undefined,
      videoConfig: selectedOffers.videoConfig,
      scan: undefined,
      scanConfig: selectedOffers.scanConfig,
      floorPlan: undefined,
      floorPlanConfig: selectedOffers.floorPlanConfig,
      custom: customOffer,
    },
    photoTotalNb,
    photoTotalPrice: 0,
    photoTotalTime: 0,
    videoTotalPrice: 0,
    videoTotalTime: 0,
    scanTotalPrice: 0,
    scanTotalTime: 0,
    floorPlanTotalPrice: 0,
    floorPlanTotalTime: 0,
    totalPrice,
    totalTime,
    discountValue: 0,
    extraCharge,
  };
};

export const getCategoryPosition = (category: ShOfferPhotoOptionCategory) =>
  ShOfferPhotoOptionCategories.indexOf(category);
export const sortOptionCategories = (
  categories: ShOfferPhotoOptionCategory[],
) => orderBy(categories, getCategoryPosition);

// maybe we should optimize this
export const useOrderCurrency = (): ShCurrency => {
  return useOrderPageContext().state.currency;
};

export const getOfferId = (
  selectedOffers: SelectedOffers,
  offerType: ShOfferType,
): string | undefined => {
  switch (offerType) {
    case 'photo':
      return selectedOffers.photoOfferId;
    case 'video':
      return selectedOffers.videoOfferId;
    case 'scan':
      return selectedOffers.scanOfferId;
    case 'floorPlan':
      return selectedOffers.floorPlanOfferId;
    case 'custom':
      return selectedOffers.customOfferId;
    default:
      throw new Error(`unhandled offerType=${offerType}`);
  }
};

export const hasAnyOffer = (selectedOffers: SelectedOffers) =>
  ShOfferTypes.some((offerType) => !!getOfferId(selectedOffers, offerType));
const getOffsetBottom = (wrapper: any): number => {
  return (
    window.pageYOffset +
    wrapper.getBoundingClientRect().top +
    wrapper.offsetHeight
  );
};

export const getStickyStyle = ({
  wrapper,
  wrapperFirstElement,
  wrapperFirstElementSetMinHeight = false,
  header,
  cart,
}: {
  wrapper: string;
  wrapperFirstElement: string;
  // set minHeight to wrapper first element, hacky and mostly useful
  // on stepBook which has sticky cart bigger than calendar form
  wrapperFirstElementSetMinHeight?: boolean;
  header: string;
  cart: string;
}): any => {
  if (isBrowser()) {
    const headerEl: HTMLElement | null = document.querySelector(header);
    const cartEl: HTMLElement | null = document.querySelector(cart);
    const wrapperEl: HTMLElement | null = document.querySelector(wrapper);

    const computedStyle: any = {};
    if (headerEl && cartEl && wrapperEl) {
      const pageOffset = window.pageYOffset;
      const firstWrapperEl: HTMLElement | null = wrapperEl.querySelector(
        wrapperFirstElement,
      );

      const topPosition = headerEl.offsetHeight + 20;
      const topLimit =
        firstWrapperEl!.getBoundingClientRect().top + pageOffset - topPosition;

      const bottomLimit = wrapperEl.getBoundingClientRect().bottom + pageOffset;

      const cartHeight = cartEl.offsetHeight;

      const isAtBottom =
        pageOffset + headerEl.offsetHeight + cartHeight + 20 > bottomLimit;

      const isAtTop = topLimit <= pageOffset;
      const isFixed = isAtTop && !isAtBottom;
      const isOutOfBounds = cartHeight + 110 > bottomLimit - topLimit;

      computedStyle.top = null;
      computedStyle.bottom = null;
      computedStyle.width = cartEl.offsetWidth;

      if (isFixed && !isOutOfBounds) {
        computedStyle.position = 'fixed';
        computedStyle.top = topPosition;
      }
      if (isAtBottom && !isOutOfBounds) {
        computedStyle.position = 'absolute';
        computedStyle.bottom = cartHeight;
      }

      if (wrapperFirstElementSetMinHeight) {
        firstWrapperEl!.style.minHeight = `${cartHeight}px`;
      }

      return computedStyle;
    }
    return computedStyle;
  }
  return {};
};

export const placeToOrderAddress = (
  place: google.maps.places.PlaceResult,
): OrderAddress => {
  return {
    address: place.formatted_address!,
    latitude: place.geometry!.location.lat(),
    longitude: place.geometry!.location.lng(),
  };
};
