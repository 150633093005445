import React, { useEffect } from 'react';
import { H4 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';

import { useOrderCurrency } from 'page/order/utils/orderPageUtils';
import StepOrderSwitchBox, {
  useStepOrderBasicOfferSwitchBox,
} from './stepOrderSwitchBox';

import idx from 'idx';
import { ShText } from '@shoootin/translations';
import { FrontCommonMessages } from '../../../../appCommonTranslations';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import { ShPrice, ShTitle } from '@shoootin/components-web';
import StepOrderFloorPlanForm from './stepOrderFloorPlanForm';
import { ShOfferFloorPlanDTO } from '@shoootin/api';

const StepOrderFloorPlan = ({
  floorPlanOffer,
}: {
  floorPlanOffer: ShOfferFloorPlanDTO;
}) => {
  const {
    state: { selectedOffers, validationErrors },
    api: { selectFloorPlanOffer, mutateFloorPlanConfig, deleteFloorPlanOffer },
    computed: { orderSummary },
  } = useOrderPageContext();
  const currency = useOrderCurrency();
  const [opened, setOpened] = useStepOrderBasicOfferSwitchBox(
    selectedOffers,
    'floorPlan',
  );
  const isAdmin = useIsOrderAdminMode();

  const hasMatterport = orderSummary?.offers.scan ?? false;
  const hasNoOffer =
    !orderSummary?.offers.scan &&
    !orderSummary?.offers.photo &&
    !orderSummary?.offers.video;
  const cannotChooseFloorPlan = hasMatterport || hasNoOffer;

  useEffect(() => {
    if (cannotChooseFloorPlan) {
      deleteFloorPlanOffer();
      setOpened(false);
    }
  }, [cannotChooseFloorPlan]);

  return (
    <StepOrderSwitchBox
      key="floorplan"
      service={'floorplan'}
      opened={opened}
      onOpenedChange={(value) => {
        if (!cannotChooseFloorPlan) {
          if (value) {
            selectFloorPlanOffer(floorPlanOffer);
            setOpened(value);
          } else {
            deleteFloorPlanOffer();
            setOpened(value);
          }
        }
      }}
      label={
        <ShTitle size={4} color={opened ? 'white' : 'black'}>
          <ShText message={FrontCommonMessages.floorPlan} />
        </ShTitle>
      }
      labelRight={
        <ShTitle size={4} color={'white'}>
          <ShPrice
            price={orderSummary!.floorPlanTotalPrice}
            currency={currency}
          />
        </ShTitle>
      }
      footer={
        cannotChooseFloorPlan && (
          <small>
            <ShText message={FrontCommonMessages.floorPlanUnvailable} />
          </small>
        )
      }
    >
      <>
        {isAdmin && (
          <div className="field">
            <b>
              <small>
                {floorPlanOffer.forClient
                  ? '* Client special offer'
                  : '* Public Offer'}
                <br />
                {floorPlanOffer.admin!.comment && (
                  <>
                    {floorPlanOffer.admin!.comment}
                    <br />
                  </>
                )}
              </small>
            </b>
          </div>
        )}
        <StepOrderFloorPlanForm
          floorPlanOffer={floorPlanOffer}
          floorPlanConfig={selectedOffers.floorPlanConfig}
          mutateFloorPlanConfig={mutateFloorPlanConfig}
          floorPlanValidationErrors={idx(
            validationErrors,
            (_) => _.offers.floorPlanErrors,
          )}
        />
        {/* <SwitchHelp modalName="offerScan" /> */}
      </>
    </StepOrderSwitchBox>
  );
};

export default StepOrderFloorPlan;
