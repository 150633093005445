import { useCurrentClientUser } from '../../../state/currentUserState';
import { usePageParams } from '../../../layout/appContext';
import { ShCustomOrder, ShCustomOrders } from '@shoootin/config';

export const useOrderPageBranding = (): ShCustomOrder | undefined => {
  const { pageBranding } = usePageParams();
  const currentUser = useCurrentClientUser();
  if (
    pageBranding &&
    ShCustomOrders.includes(pageBranding.toUpperCase() as any)
  ) {
    return pageBranding.toUpperCase() as ShCustomOrder;
  }
  if (
    currentUser &&
    currentUser.branding &&
    ShCustomOrders.includes(currentUser.branding)
  ) {
    return currentUser.branding;
  }
};
