import { useMemo } from 'react';

import {
  ShCountry,
  ShCurrency,
  ShCustomOrder,
  ShOfferPhotoOptionCategory,
  ShPaymentMethod,
  ShShootingType,
  ShTimeZone,
} from '@shoootin/config';
import {
  OfferDiscountDTO,
  OrderAddressDTO,
  OrderAdminClientUserDTO,
  OrderSummaryDTO,
  ShootingRestoreDTO,
} from 'appAPITypes';
import { Omit } from 'utility-types';
import { isDev } from 'appEnv';
import { freezeDevObject, ShMoment } from '@shoootin/utils';
import { Moment } from 'moment';

import { useFrontCountry } from '../../../layout/frontContext';
import { RegisterFormValues } from '../../login/components/registerForm';
import useAwesomeState from '../../../hooks/useAwesomeState';
import { useIsOrderAdminMode } from '../utils/orderPageAdminUtils';
import { useAppTimezone, useLocation } from '../../../layout/appContext';

import {
  ShBillingEntityDTO,
  ShInfosDTO,
  ShOfferDTO,
  ShOfferOptionDTO,
  ShShootingFieldDTO,
  ShShootingOrderAvailabilities,
  ShShootingSlotAdminDetailsPhotographerDTO,
  ShShootingSlotDTO,
} from '@shoootin/api';
import { DroneErrors, DroneState } from './orderPageContextSpecificDroneState';
import { useOrderPageBranding } from '../utils/orderPageBrandings';
import { useCurrentClientUser } from 'state/currentUserState';

// This can be provided as a html5 history state to initialize the order process
// Mostly useful when the order process is started from another page, like the home order form input...
export type AppOrderLocationState = Pick<
  OrderPageState,
  'address' | 'country' | 'selectedOffers' | 'shootingType'
>;

const useOrderLocationState = (): AppOrderLocationState | undefined => {
  const location = useLocation();
  return location.state as AppOrderLocationState;
};

const debug = isDev && false;

// The order state already countains the country so we don't want to duplicate the country in state
export type OrderAddress = Omit<OrderAddressDTO, 'country'>;

export type PhotoConfig = {
  // Options are stored by category because
  // there can only be one option selected per category
  options: Partial<Record<ShOfferPhotoOptionCategory, string>>;
};
export const EmptyPhotoConfig: PhotoConfig = {
  options: {},
};

export type VideoConfig = {
  music: boolean;
};
export const EmptyVideoConfig: VideoConfig = {
  music: false,
};

export type ScanConfig = {
  floorPlan: boolean;
  surface: number;
  extraSpace: number;
  zonesToScan: string;
};
export const EmptyScanConfig: ScanConfig = {
  floorPlan: false,
  surface: debug ? 42 : 0,
  extraSpace: 0,
  zonesToScan: debug ? 'dev zones' : '',
};

export type FloorPlanConfig = {
  surface: number;
};
export const EmptyFloorPlanConfig: FloorPlanConfig = {
  surface: debug ? 42 : 0,
};

export type SelectedOffers = {
  photoOfferId?: string;
  photoConfig: PhotoConfig;
  videoOfferId?: string;
  videoConfig: VideoConfig;
  scanOfferId?: string;
  scanConfig: ScanConfig;
  floorPlanOfferId?: string;
  floorPlanConfig: FloorPlanConfig;
  customOfferId?: string;
  shootingFields: Record<string, string | boolean>;
};

const basicPhotoOfferId = '55a387a79b3901d7b8028401';
const basicScanOfferId = '5a5004c1e724740c87f38ed6';
const customOfferId = '577be41ae4b0995454a2903e';

export const EmptySelectedOffers: SelectedOffers = {
  photoOfferId: undefined,
  // photoOfferId: debug ? basicPhotoOfferId : undefined,
  photoConfig: EmptyPhotoConfig,
  videoOfferId: undefined,
  videoConfig: EmptyVideoConfig,
  scanOfferId: undefined, // debug ? basicScanOfferId : undefined,
  scanConfig: EmptyScanConfig,
  floorPlanOfferId: undefined, // debug ? basicScanOfferId : undefined,
  floorPlanConfig: EmptyFloorPlanConfig,
  customOfferId: undefined, // debug ? customOfferId : undefined,
  shootingFields: {},
};

export type OrderAddressValidationErrors = {
  country?: string;
  address?: string;
};
export type OrderInfosValidationErrors = {
  typeOfShooting?: string;
  accessInformations?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  reference?: string;
};
export type OrderOffersScanValidationErrors = {
  surface?: string;
  zonesToScan?: string;
};
export type OrderOffersFloorPlanValidationErrors = {
  surface?: string;
};
export type OrderOffersValidationErrors = {
  general?: string;
  scanErrors?: OrderOffersScanValidationErrors;
  floorPlanErrors?: OrderOffersFloorPlanValidationErrors;
  shootingFields?: Record<string, string>;
};
export type OrderRegistrationValidationErrors = {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmPassword?: string;
  mobilePhone?: string;
  country?: string;
  companyName?: string;
  companyId?: string;
};
export type OrderValidationErrors = {
  offerForbiddenForUser?: boolean;
  address?: OrderAddressValidationErrors;
  infos?: OrderInfosValidationErrors;
  offers?: OrderOffersValidationErrors;
  registration?: OrderRegistrationValidationErrors;
  clientExtraFields?: Record<string, string>;
  paymentErrors?: string;
  droneErrors?: DroneErrors;
};

export type OrderPageRestorationState = {
  shootingId: string;
  restoredFrom?: ShootingRestoreDTO;
  // The data that was used to restore the form or
  // can be null when we only set shootingId when payment fails
};
export type OrderAdminSettings = {
  photographerRadiusSearch: number;
  adminConstraintsActive: boolean;
  godMode: boolean;
};
export type OrderPageAdminState = {
  clientUser?: OrderAdminClientUserDTO;
  photographer?: ShShootingSlotAdminDetailsPhotographerDTO;
  settings?: OrderAdminSettings;
  extraPayment: number;
};
const EmptyOrderPageAdminState = {
  extraPayment: 0,
};

export type OrderUserDTO = {
  id: string;
  name: string;
  email: string;
  mobilePhone: string;
  billingEntityId: string;
};

export type OrderPageState = {
  admin?: OrderPageAdminState;
  // Remote/fetched data
  restoration?: OrderPageRestorationState;
  offers?: ShOfferDTO[];
  options?: ShOfferOptionDTO[];
  currency: ShCurrency; // Should we make this possibly undefined?
  offerDiscount: OfferDiscountDTO; // Should we make this possibly undefined?
  availabilities?: ShShootingOrderAvailabilities;
  isSelectedSlotValid: boolean;
  clientExtraFields: ShShootingFieldDTO[];
  clientBillingEntities: ShBillingEntityDTO[];
  canAddBillingEntity: boolean;
  users: OrderUserDTO[];
  userId?: string;
  orderSummary?: OrderSummaryDTO;
  validationErrors?: OrderValidationErrors;
  // User input/form data
  country: ShCountry;
  address?: OrderAddress;
  shootingType?: ShShootingType;
  infos: ShInfosDTO;
  selectedOffers: SelectedOffers;
  timeZone: ShTimeZone;
  selectedDay: Moment;
  selectedSlot?: ShShootingSlotDTO;
  registration?: RegisterFormValues;
  selectedPaymentMethod?: ShPaymentMethod;
  selectedCreditCardId?: string;
  saveCreditCard: boolean;
  reduceTotalWithCredit: boolean;
  discountCode?: string;
  unavailableSlot?: ShShootingSlotDTO; // the slot the user wanted that is not available anymore (for restore or order)
  clientExtraFieldsMap: Record<string, string | boolean>;
  extraCharge: number;
  billingEntity?: string;

  branding?: ShCustomOrder;

  // Drone offer with a different validation state
  droneState?: DroneState;
};

export const EmptyInfos: ShInfosDTO = {
  comment: debug
    ? 'Commentaires machin Lorem ipsum machin est alea jacta est'
    : '',
  reference: debug ? 'Ref 1234' : '',
  meetDownstairs: true,
  accessInformations: '',
  contactOnSite: true,
  contactName: '',
  contactPhoneNumber: '',
};

export const EmptyOfferDiscout: OfferDiscountDTO = {
  discountPhotoVideo: 0,
  discountPhotoScan: 0,
  discountVideoScan: 0,
  discountPhotoScanVideo: 0,
};

const DefaultOrderPageStateStatics: Omit<
  OrderPageState,
  'admin' | 'selectedDay' | 'timeZone' | 'country' // Those are iniialized dynamically
> = {
  // Remote/fetched data
  restoration: undefined,
  offers: undefined,
  options: undefined,
  currency: 'EUR', // todo TOimprove init default currency from page context AppLocale?
  offerDiscount: EmptyOfferDiscout,
  availabilities: undefined,
  isSelectedSlotValid: false,
  orderSummary: undefined,
  clientExtraFields: [],
  clientBillingEntities: [],
  canAddBillingEntity: false,
  users: [],
  // User input/form data
  address: debug
    ? {
        address: 'Paris, France',
        latitude: 48.85661400000001,
        longitude: 2.3522219000000177,
      }
    : undefined,
  shootingType: debug ? 'residentialRealEstate' : undefined,
  infos: EmptyInfos,
  selectedOffers: EmptySelectedOffers,
  selectedSlot: undefined,
  registration: undefined,
  selectedPaymentMethod: 'UNLIMITED',
  saveCreditCard: true,
  reduceTotalWithCredit: false,
  unavailableSlot: undefined,
  clientExtraFieldsMap: {},
  billingEntity: undefined,
  extraCharge: 0,
};
freezeDevObject(DefaultOrderPageStateStatics);

export const useInitialOrderPageState = (): OrderPageState => {
  const orderLocationState = useOrderLocationState();
  const country = useFrontCountry();
  const timeZone = useAppTimezone();
  const isAdminMode = useIsOrderAdminMode();
  const branding = useOrderPageBranding();
  const currentUser = useCurrentClientUser();
  return useMemo(
    () =>
      freezeDevObject({
        ...DefaultOrderPageStateStatics,
        ...orderLocationState,
        country,
        admin: isAdminMode ? EmptyOrderPageAdminState : undefined,
        timeZone,
        selectedDay: ShMoment(), // This can't be static because we need to ensure moment has been initialized first,
        branding,
        userId: isAdminMode ? undefined : currentUser?.id,
      }),
    [timeZone],
  );
};

export const useOrderPageState = (initialState?: OrderPageState) => {
  const initialOrderPageState = useInitialOrderPageState();
  return useAwesomeState(initialState || initialOrderPageState);
};
