import {
  OrderPageAdminState,
  OrderPageState,
  SelectedOffers,
} from '../context/orderPageContextState';
import {
  ShootingOrderDTO,
  ShootingOrderOffersDTO,
  ValidateAddressDTO,
  ValidateOffersDroneDTO,
  ValidateOffersDTO,
  ValidateOrderDTO,
} from '../../../appAPITypes';
import idx from 'idx';

export const createValidateAddressDTO = (
  state: OrderPageState,
): ValidateAddressDTO => ({
  admin: createAdminDTO(state.admin),
  address: {
    ...state.address!,
    country: state.country,
  },
  branding: state.branding,
});

// The offer config objects should not be sent to backend if offer component is not selected
// even if they are set in form state for conveniency
// We filter these data on frontend currently and not on backend (asked by Dara)
const createOrderOffersDTO = (
  state: OrderPageState,
): ShootingOrderOffersDTO => {
  const selectedOffers = state.selectedOffers;
  if (state.selectedOffers.customOfferId) {
    const customOffer = state.offers!.find(
      (o) => o.id === state.selectedOffers.customOfferId,
    )!;
    return {
      ...selectedOffers,
      ...(!customOffer.photo ? { photoConfig: undefined } : null),
      ...(!customOffer.video ? { videoConfig: undefined } : null),
      ...(!customOffer.scan ? { scanConfig: undefined } : null),
      ...(!customOffer.floorPlan ? { floorPlanConfig: undefined } : null),
    };
  } else {
    return {
      ...selectedOffers,
      ...(!selectedOffers.photoOfferId ? { photoConfig: undefined } : null),
      ...(!selectedOffers.videoOfferId ? { videoConfig: undefined } : null),
      ...(!selectedOffers.scanOfferId ? { scanConfig: undefined } : null),
      ...(!selectedOffers.floorPlanOfferId
        ? { floorPlanConfig: undefined }
        : null),
    };
  }
};

export const createValidateOffersDTO = (
  state: OrderPageState,
): ValidateOffersDTO => ({
  ...createValidateAddressDTO(state),
  infos: { shootingType: state.shootingType!, ...state.infos! },
  offers: createOrderOffersDTO(state),
  timeZone: state.timeZone,
  start: state.selectedDay,
  clientExtraFieldsMap: state.clientExtraFieldsMap,
  billingEntity: state.billingEntity,
  shootingId: state.restoration ? state.restoration.shootingId : undefined,
  extraCharge: state.extraCharge,
  userId: state.userId,
});

export const createValidateOffersDroneDTO = (
  state: OrderPageState,
): ValidateOffersDroneDTO => ({
  ...createValidateAddressDTO(state),
  infos: { shootingType: state.shootingType!, ...state.infos! },
  offers: createOrderOffersDTO(state),
  timeZone: state.timeZone,
  start: state.selectedDay,
  clientExtraFieldsMap: state.clientExtraFieldsMap,
  billingEntity: state.billingEntity,
  shootingId: state.restoration ? state.restoration.shootingId : undefined,
  extraCharge: state.extraCharge,
  droneState: state.droneState,
});

export const createValidateOrderDTO = (
  state: OrderPageState,
): ValidateOrderDTO => ({
  ...createValidateOffersDTO(state),
  admin: createAdminDTO(state.admin),
  shootingSlot: state.selectedSlot!,
  discountCode: state.discountCode,
  registration: state.registration,
  reduceTotalWithCredit: state.reduceTotalWithCredit,
});

// For now it's the same payload as ValidateOrderDTO
export const createConfirmOrderDTO = (
  state: OrderPageState,
): ShootingOrderDTO => createValidateOrderDTO(state);

export const createAdminDTO = (admin: OrderPageAdminState | undefined) =>
  admin
    ? {
        clientId: admin.clientUser!.clientId,
        userId: admin.clientUser!.userId,
        photographerId: idx(admin, (_) => _.photographer.id) || null,
        photographerRadiusSearch:
          idx(admin, (_) => _.settings.photographerRadiusSearch) || null,
        adminConstraintsActive: !!idx(
          admin,
          (_) => _.settings.adminConstraintsActive,
        ),
        extraPayment: admin.extraPayment,
      }
    : undefined;
