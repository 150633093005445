import React, { useState } from 'react';
import { H4 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';

import {
  ShBillingEntitySelect,
  ShClientBillingEntityForm,
  ShClientBillingEntityFormValues,
  ShModal,
} from '@shoootin/components-web';
import { ShBillingEntityAPI, ShBillingEntityDTO } from '@shoootin/api';

import { last } from 'lodash';
import { isDev } from '../../../../appEnv';
import { ShText } from '@shoootin/translations';

const generateRandomEntityValues = (): ShClientBillingEntityFormValues => {
  const random = Math.round(Math.random() * 10000);
  return {
    id: undefined,
    name: `Name ${random}`,
    defaultEntity: false,
    company: `Company ${random}`,
    department: `Department ${random}`,
    address: `Address ${random}`,
    companyId: '',
    taxNumber: '',
    extraInfos: '',
    countryCode: 'FR',
    emails: [`${random}test@shooot.in`],
    paymentDelay: 7,
    specialInvoicing: false,
  };
};

const AddBillingEntityButton = ({
  onSubmit,
  canAddBillingEntity,
}: {
  onSubmit: (values: ShBillingEntityDTO) => Promise<void>;
  canAddBillingEntity: boolean;
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <a
        onClick={() => setOpened(true)}
        style={{
          cursor: 'pointer',
          marginTop: 10,
          display: 'block',
          fontSize: '0.75rem',
        }}
      >
        <ShText message={'client_billingEntity_orderNotInListAdd'} />
      </a>

      {opened && (
        <ShModal
          size={canAddBillingEntity ? 'l' : 's'}
          onClose={() => setOpened(false)}
          title={<ShText message={'client_billingEntity_add'} />}
          content={
            canAddBillingEntity ? (
              <div
                css={{
                  minWidth: '40vw',
                }}
              >
                <ShClientBillingEntityForm
                  initialValues={
                    isDev ? generateRandomEntityValues() : undefined
                  }
                  onSubmit={async (form: ShBillingEntityDTO) => {
                    await onSubmit(form);
                    setOpened(false);
                  }}
                  onBack={() => setOpened(false)}
                />
              </div>
            ) : (
              <ShText message={'client_billingEntity_cannotAdd'} />
            )
          }
        />
      )}
    </>
  );
};

export const StepOrderBillingWrapper = ({
  billingEntity,
  clientBillingEntities,
  setBillingEntity,
  setBillingEntities,
  canAddBillingEntity,
}: {
  billingEntity?: string;
  clientBillingEntities: ShBillingEntityDTO[];
  setBillingEntity: (entityId: string) => void;
  setBillingEntities: (entities: ShBillingEntityDTO[]) => void;
  canAddBillingEntity: boolean;
}) => {
  return (
    <>
      <ShBillingEntitySelect
        css={{ flexGrow: 1 }}
        currentEntityId={billingEntity}
        entities={clientBillingEntities}
        onChange={(entity: ShBillingEntityDTO) => setBillingEntity(entity.id)}
      />

      <AddBillingEntityButton
        onSubmit={async (form: ShBillingEntityDTO) => {
          await ShBillingEntityAPI.save(form);
          const result = await ShBillingEntityAPI.getAll();
          setBillingEntities(result.billingEntities);
          setBillingEntity(last(result.billingEntities)!.id);
        }}
        canAddBillingEntity={canAddBillingEntity}
      />
    </>
  );
};

const StepOrderBilling = () => {
  const {
    state: { billingEntity, clientBillingEntities, canAddBillingEntity },
    api: { setBillingEntity, setBillingEntities },
  } = useOrderPageContext();

  return (
    <>
      {clientBillingEntities.length > 0 && (
        <div className="form-order__section">
          <H4>
            <ShText message={'client_billingEntity_orderTitle'} />
          </H4>
          <StepOrderBillingWrapper
            billingEntity={billingEntity}
            clientBillingEntities={clientBillingEntities}
            setBillingEntity={setBillingEntity}
            setBillingEntities={setBillingEntities}
            canAddBillingEntity={canAddBillingEntity}
          />
        </div>
      )}
    </>
  );
};
export default StepOrderBilling;
