import React, { ReactNode, useEffect, useRef } from 'react';
import ChevronUpSvg from './svg/picto/chevronUpSvg';
import classnames from 'classnames';

// @ts-ignore
// import CollapseLegacyJs from 'legacyJs/collapse';

const Collapse = ({
  opened = true,
  onChange,
  label,
  children,
}: {
  opened?: boolean; // TODO make this required when possible
  onChange?: (opened: boolean) => void; // TODO make this required when possible
  label: string;
  children: ReactNode;
}) => {
  const collapseRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // TODO rewire legacy js + transition animation correctly (not so easy)
    // CollapseLegacyJs(collapseRef.current)
  }, []);
  return (
    <div className={classnames('collapse', { opened })} ref={collapseRef}>
      <div
        className="collapse-header collapse-trigger title"
        onClick={onChange ? () => onChange(!opened) : undefined}
      >
        {label} {onChange && <ChevronUpSvg />}
      </div>
      <div className="collapse-panel" ref={panelRef} style={{}}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;
