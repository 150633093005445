import React from 'react';
import { ShPrice } from '@shoootin/components-web';
import { useOrderCurrency } from '../../utils/orderPageUtils';
import { ShOfferDTO } from '@shoootin/api';

const StepOrderOfferAdminInformations = ({ offer }: { offer: ShOfferDTO }) => {
  const currency = useOrderCurrency();
  return (
    <div css={{ marginTop: 10 }}>
      <div css={{ fontSize: 14 }}>
        <b>Internal informations : </b>
      </div>
      <div css={{ fontSize: 12 }}>
        <div>
          {offer.forClient ? '* Client special offer' : '* Public Offer'}
        </div>
        {offer.admin!.comment && <div>{offer.admin!.comment}</div>}
        {offer.admin!.basePayment > 0 && (
          <div>
            Photographer payment{' '}
            <ShPrice price={offer.admin!.basePayment} currency={currency} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StepOrderOfferAdminInformations;
