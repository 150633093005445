import React, { ComponentProps, useEffect, useRef, useState } from 'react';
import { SwitchBoxCloseAnimationTime } from 'components/switch/switchBox';
import { SelectedOffers } from 'page/order/context/orderPageContextState';
import { getOfferId } from 'page/order/utils/orderPageUtils';
import useIsMounted from 'hooks/useIsMounted';
import { scrollElementToTop } from 'utils/domUtils';
import { useHeaderSize } from 'layout/mainLayout';
import { ShOfferCustomDTO, ShOfferType } from '@shoootin/api';
import { ShSwitchBox } from '@shoootin/components-web';

const scrollSwitchBoxHeaderIntoView = (
  header: HTMLDivElement,
  offset: number,
) => {
  // Need to wait for other order switchbox close animation completion,
  // as they affect the scroll position of the current switchbox we want to scroll into view...
  const delay = SwitchBoxCloseAnimationTime + 100; // :S +100 seems required to work properly
  setTimeout(() => {
    scrollElementToTop(header, offset);
  }, delay);
};

const useScrollSwitchboxIntoViewWhenOpened = (opened: boolean) => {
  const headerRef = useRef<HTMLDivElement>(null);
  // In case multiple switchboxes are opened on mount we don't want scrollIntoView behavior
  // (shooting restoration process, or dev with fake test state...)
  const isMounted = useIsMounted();
  // We want the switchbox header to appear just under the main layout header
  const offset = -useHeaderSize().height!;
  useEffect(() => {
    if (isMounted && opened) {
      scrollSwitchBoxHeaderIntoView(headerRef.current!, offset);
    }
  }, [opened]);
  return {
    headerRef,
  };
};

const StepOrderSwitchBox = (props: ComponentProps<typeof ShSwitchBox>) => {
  const { headerRef } = useScrollSwitchboxIntoViewWhenOpened(props.opened);
  return <ShSwitchBox {...props} headerRef={headerRef} />;
};

export default StepOrderSwitchBox;

// A SwitchBox can be opened without an offer being selected
// But there are cases where we want the switchbox to be closed
// We notably don't want basic and custom switchboxes to be opened at the same time
// TODO it would be simpler to achieve that if the switchbox states was in global state
const useStepOrderSwitchBox = (
  initialState: boolean,
  canBeOpen: boolean,
): [boolean, (opened: boolean) => void] => {
  const [opened, setOpened] = useState<boolean>(initialState);

  useEffect(() => {
    if (!canBeOpen && opened) {
      setOpened(false);
    }
  }, [canBeOpen]);

  return [opened, setOpened];
};

export const useStepOrderBasicOfferSwitchBox = (
  selectedOffers: SelectedOffers,
  offerType: ShOfferType,
) => {
  const initialState = !!getOfferId(selectedOffers, offerType);
  const canBeOpen = !selectedOffers.customOfferId;
  return useStepOrderSwitchBox(initialState, canBeOpen);
};

export const useStepOrderCustomOfferSwitchBox = (
  selectedOffers: SelectedOffers,
  customOffer: ShOfferCustomDTO,
) => {
  const initialState = selectedOffers.customOfferId === customOffer.id;
  const canBeOpen = initialState;
  return useStepOrderSwitchBox(initialState, canBeOpen);
};

export const useStepOrderCustomDroneOfferSwitchBox = (
  selectedOffers: SelectedOffers,
) => {
  const initialState = !!selectedOffers.customOfferId;
  const canBeOpen = !(
    selectedOffers.photoOfferId ||
    selectedOffers.videoOfferId ||
    selectedOffers.scanOfferId
  );
  return useStepOrderSwitchBox(initialState, canBeOpen);
};
