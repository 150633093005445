import React from 'react';

import Collapse from 'components/collapse';
import TrashSvg from 'components/svg/icon/trashSvg';
import Options from 'page/order/components/options';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import {
  computePhotoTotalNb,
  computePhotoTotalPrice,
  computePhotoTotalTime,
  OfferOptionByCategory,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import { ShText, useShTranslate } from '@shoootin/translations';
import { FrontCommonMessages } from 'appCommonTranslations';
import { getRecordValues } from 'utils/lodashUtils';
import StepOrderSwitchBox, {
  useStepOrderBasicOfferSwitchBox,
} from './stepOrderSwitchBox';
import { ShootingFields } from './stepOrderFields';
import {
  getFrontMessage,
  getFrontMessageUnsafe,
} from '../../../../appMessageDefinitions';
import {
  ShHelpModalDiv,
  ShIntercomArticles,
  ShOfferCollapse,
  ShPrice,
  ShSwitchBoxElementWrapper,
  ShSwitchHelp,
  ShTitle,
} from '@shoootin/components-web';
import StepOrderOfferAdminInformations from './stepOrderOfferAdminInformations';
import { ShOfferPhotoDTO } from '@shoootin/api';
import { groupBy } from 'lodash';
import { ShOfferGroup, ShOfferGroupsConfig } from '@shoootin/config';
import { minuteToHours } from '@shoootin/utils';

const PhotoOffer = ({ offer }: { offer: ShOfferPhotoDTO }) => {
  const {
    state: { selectedOffers },
    api: { selectPhotoOffer },
  } = useOrderPageContext();
  const currency = useOrderCurrency();
  const translate = useShTranslate();
  const opened = offer.id === selectedOffers.photoOfferId;

  const defaultOptionsList = getRecordValues(offer.photo.defaultOptions);

  // We compute the number/price/time, including the default options
  const nb = computePhotoTotalNb(offer, defaultOptionsList);
  const price = computePhotoTotalPrice(offer, defaultOptionsList);
  const time = computePhotoTotalTime(offer, defaultOptionsList);

  const isAdmin = !!useOrderPageContext().state.admin;
  const label = `${translate(FrontCommonMessages.nPhotos, { n: nb })} — ${
    offer.name
  }`;

  return (
    <ShOfferCollapse
      label={label} // TODO reactNode ?
      value={opened}
      onChange={(value) => {
        value && selectPhotoOffer(offer);
      }}
      service={'photo'}
      sublabel={
        <>
          <div>
            <ShText message={getFrontMessage('common_order_duration')} />{' '}
            {minuteToHours(time)}
            {isAdmin && <StepOrderOfferAdminInformations offer={offer} />}
          </div>
          <span className="text-price">
            <ShPrice price={price} currency={currency} />
          </span>
        </>
      }
    >
      {offer.description && <p>{offer.description}</p>}
      <ShSwitchBoxElementWrapper>
        <ShootingFields typeOfComponent="photo" fields={offer.shootingFields} />
      </ShSwitchBoxElementWrapper>
      {/* <ul className="collapse-radio__select"> TOimprove later
        <li className="collapse-radio__option">1h</li>
        <li className="collapse-radio__option">1h15</li>
        <li className="collapse-radio__option">1h30</li>
        <li className="collapse-radio__option">1h45</li>
        <li className="collapse-radio__option">2h00</li>
        <li className="collapse-radio__option">2h15</li>
        <li className="collapse-radio__option">2h30</li>
      </ul> */}
    </ShOfferCollapse>
  );
};

const PhotoOfferOptionsCollapsible = ({
  offer,
  options,
}: {
  offer: ShOfferPhotoDTO;
  options: OfferOptionByCategory;
}) => {
  const currency = useOrderCurrency();
  const {
    state: { selectedOffers },
    api: { togglePhotoOfferOption },
    computed: { orderSummary, optionCategories },
  } = useOrderPageContext();

  const selectedOptions = orderSummary!.offers.photoOptions;
  const translate = useShTranslate();
  /*
  TOimprove rewrite properly the collapse comp when it can integrate nicely with SwitchBox...
  SwitchBox does not recompute its height if content changes...
  const [opened, setOpened] = useState(false);
  // Automatically open options on mount or when offer is changed
  useEffect(
    () => {
      setOpened(true);
    },
    [offer.id],
  );
  */

  return (
    <Collapse label={translate(getFrontMessage('common_order_options'))}>
      {selectedOptions.map((option) => (
        <div key={option.id} className="form-options">
          <p className="form-options__label">
            <ShText
              message={getFrontMessageUnsafe(
                `enums_optionCategories_${option.category}`,
              )}
            />
          </p>
          <p className="form-options__title">
            <span className="title">
              <ShText
                message={FrontCommonMessages.nPhotos}
                values={{ n: option.photosNb }}
              />
            </span>
            <TrashSvg onClick={() => togglePhotoOfferOption(option)} />
            <span className="text-price">
              <ShPrice price={option.basePrice} currency={currency} />
            </span>
          </p>
          <p>
            <ShText message={getFrontMessage('common_order_duration')} />{' '}
            {minuteToHours(option.baseTime)}
          </p>
        </div>
      ))}
      {optionCategories.length > 0 && (
        <Options
          options={options}
          availableCategories={optionCategories}
          selectedOffers={selectedOffers}
          togglePhotoOfferOption={togglePhotoOfferOption}
        />
      )}
    </Collapse>
  );
};

const PhotoGroupTitle = ({ group }: { group: ShOfferGroup }) => {
  const intercomArticle = ShOfferGroupsConfig[group].intercomArticle;
  return (
    <div
      css={{
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        paddingTop: '1.5rem',
        // marginTop: 10,
        paddingBottom: '1.5rem',
        // marginBottom: 10,
        borderTop: '1px solid #e5e5e5',
        borderBottom: '1px solid #e5e5e5',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ShTitle size={4}>
          <ShText message={ShOfferGroupsConfig[group].title} />
        </ShTitle>
        <div>
          {intercomArticle && (
            <ShIntercomArticles id={intercomArticle}>
              <div css={{ padding: 7 }}>
                <ShHelpModalDiv />
              </div>
            </ShIntercomArticles>
          )}
        </div>
      </div>
      <div css={{ paddingTop: 10 }}>
        <ShText message={ShOfferGroupsConfig[group].description} />
      </div>
    </div>
  );
};

type OfferGroup = ShOfferGroup & '__NoGroup';

const StepOrderPhoto = ({
  photoOffers,
}: {
  photoOffers: ShOfferPhotoDTO[];
}) => {
  const {
    state: { selectedOffers },
    api: { deletePhotoOffer, deleteCustomOffer },
    computed,
  } = useOrderPageContext();

  const [opened, setOpened] = useStepOrderBasicOfferSwitchBox(
    selectedOffers,
    'photo',
  );
  const orderSummary = computed.orderSummary!;
  const { optionsByCategory, optionCategories } = computed;
  const hasAvailableOptions = optionCategories.length > 0;
  const selectedPhotoOffer = orderSummary.offers.photo;

  const groupOfferByGroup: Record<OfferGroup, ShOfferPhotoDTO[]> = groupBy(
    photoOffers,
    (offer: ShOfferPhotoDTO) => (offer.group ?? '__NoGroup') as OfferGroup,
  ) as Record<OfferGroup, ShOfferPhotoDTO[]>;

  console.log({ groupOfferByGroup });

  const offerGroups: OfferGroup[] = Object.keys(
    groupOfferByGroup,
  ).sort() as OfferGroup[];

  const switchBoxLabel = selectedPhotoOffer ? (
    <ShText
      message={FrontCommonMessages.nPhotos}
      values={{ n: orderSummary.photoTotalNb }}
    />
  ) : (
    <ShText message={FrontCommonMessages.photo} />
  );
  return (
    <StepOrderSwitchBox
      key="photo"
      service={'photo'}
      onOpenedChange={(opened: boolean) => {
        setOpened(opened);
        if (opened) {
          deleteCustomOffer();
        } else {
          deletePhotoOffer();
        }
      }}
      label={
        <ShTitle size={4} color={opened ? 'white' : 'black'}>
          {switchBoxLabel}
        </ShTitle>
      }
      opened={opened}
    >
      <div>
        {offerGroups.length > 0 &&
          offerGroups.map((offerGroup: OfferGroup) => (
            <div key={`offerPhotoGroup${offerGroup}`}>
              {offerGroup !== '__NoGroup' && (
                <PhotoGroupTitle group={offerGroup} />
              )}
              {(groupOfferByGroup[offerGroup] as ShOfferPhotoDTO[]).map(
                (offer: ShOfferPhotoDTO) => (
                  <PhotoOffer key={offer.id} offer={offer} />
                ),
              )}
            </div>
          ))}
      </div>
      {selectedPhotoOffer && hasAvailableOptions && (
        <PhotoOfferOptionsCollapsible
          offer={selectedPhotoOffer}
          options={optionsByCategory}
        />
      )}

      <ShSwitchHelp modalName="offerPhoto" />
    </StepOrderSwitchBox>
  );
};

export default StepOrderPhoto;
