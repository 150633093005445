import React, { useState } from 'react';
import {
  OfferOptionByCategory,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import classnames from 'classnames';
import { SelectedOffers } from 'page/order/context/orderPageContextState';
import { getRecordValues } from '../../../utils/lodashUtils';
import {
  getFrontMessage,
  getFrontMessageUnsafe,
} from '../../../appMessageDefinitions';
import { FrontCommonMessages } from '../../../appCommonTranslations';
import { ShText, useShTranslate } from '@shoootin/translations';
import { ShOfferPhotoOptionCategory } from '@shoootin/config';
import { ShPrice, ShRadioField } from '@shoootin/components-web';
import { ShOfferOptionDTO } from '@shoootin/api';
import { minuteToHours } from '@shoootin/utils';

const PhotoOptionFieldset = ({
  options,
  category,
  activeCategory,
  selectedOffers,
  togglePhotoOfferOption,
}: {
  options: ShOfferOptionDTO[];
  category: ShOfferPhotoOptionCategory;
  activeCategory?: ShOfferPhotoOptionCategory;
  selectedOffers: SelectedOffers;
  togglePhotoOfferOption: (option: ShOfferOptionDTO) => void;
}) => {
  const currency = useOrderCurrency();
  const selectedOptionIds = getRecordValues(selectedOffers.photoConfig.options);
  const translate = useShTranslate();
  return (
    <fieldset
      className={classnames(
        'tabset-panel',
        category === activeCategory ? 'active' : '',
      )}
    >
      {options.map((option) => (
        <div key={option.id} className="form-options__option">
          <ShRadioField
            value={selectedOptionIds.includes(option.id)}
            service={'photo'}
            label={
              <ShText
                message={FrontCommonMessages.nPhotos}
                values={{
                  n: option.photosNb,
                }}
              />
            }
            onChange={() => togglePhotoOfferOption(option)}
            bold={true}
            invert={true}
          />
          <div className="form-options__option__label">
            <ShText message={getFrontMessage('common_order_duration')} />{' '}
            {minuteToHours(option.baseTime)}
            <span className="text-price">
              <ShPrice price={option.basePrice} currency={currency} />
            </span>
          </div>
        </div>
      ))}
    </fieldset>
  );
};

const Options = ({
  options,
  availableCategories,
  selectedOffers,
  togglePhotoOfferOption,
}: {
  options: OfferOptionByCategory;
  availableCategories: ShOfferPhotoOptionCategory[];
  selectedOffers: SelectedOffers;
  togglePhotoOfferOption: (option: ShOfferOptionDTO) => void;
}) => {
  const [activeCategory, setActiveCategory] = useState<
    ShOfferPhotoOptionCategory
  >(availableCategories[0]);

  if (availableCategories.length === 0) {
    return null;
  }
  return (
    <div className="form-options__select tabset">
      <div className="tabset-nav swiper-container">
        <div className="swiper-wrapper">
          {availableCategories.map((category) => (
            <div key={category} className="swiper-slide">
              <a
                className={classnames(
                  'link',
                  activeCategory === category ? 'active' : '',
                )}
                onClick={() => setActiveCategory(category)}
              >
                <ShText
                  message={getFrontMessageUnsafe(
                    `enums_optionCategories_${category}`,
                  )}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="tabset-content form-options__options">
        {availableCategories.map((category) => (
          <PhotoOptionFieldset
            key={category}
            options={options[category]!}
            category={category}
            activeCategory={activeCategory}
            selectedOffers={selectedOffers}
            togglePhotoOfferOption={togglePhotoOfferOption}
          />
        ))}
      </div>
    </div>
  );
};
export default Options;
