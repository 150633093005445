/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo } from 'react';
import {
  ShButton,
  ShInputAutocomplete,
  ShInputAutocompleteOption,
  ShInputText,
  useLocalInputState,
  useShSearch,
} from '@shoootin/components-web';
import { ShAdminSlackApi, ShSlackChannelDTO } from '@shoootin/api';
import { useAsync } from 'react-async-hook';
import { ShColors } from '@shoootin/design-tokens';
import { ShIntercomArticles } from '../intercomArticles/ShIntercomArticles';
import { ShHelpModalDiv } from '../../components/help/ShHelpModalButton';

const useSlackChannels = () => {
  return useAsync(async () => {
    return ShAdminSlackApi.getChannels();
  }, []);
};

const SlackChannelAutoComplete = ({
  setSlackChannel,
}: {
  setSlackChannel: (slackChannel?: ShSlackChannelDTO) => void;
}) => {
  const [inputValue, setInputValue] = useLocalInputState<string>('');
  const channelsAsync = useSlackChannels();
  const options = useChannelOptions(channelsAsync);

  const filteredOptions = useShSearch({
    text: inputValue,
    docs: options,
    minText: 1,
    createIndexOptions: () => ({
      keys: ['text', 'value'],
    }),
  });

  return (
    <div css={{ width: 200 }}>
      <label>Slack channel</label>
      <ShInputAutocomplete
        allowFreeText
        value={inputValue}
        placeholder={'Search'}
        options={filteredOptions.length > 0 ? filteredOptions : null}
        onChange={(newText, option) => {
          setInputValue(newText);
          if (option) {
            setSlackChannel({
              name: option.text!,
              id: option.value,
            });
          } else if (newText === '') {
            setSlackChannel(undefined);
          }
        }}
      />
    </div>
  );
};

const SlackChannelField = ({
  slackChannel,
  setSlackChannel,
}: {
  slackChannel?: ShSlackChannelDTO;
  setSlackChannel: (slackChannel?: ShSlackChannelDTO) => void;
}) => {
  const value = slackChannel?.name;
  return (
    <div css={{ width: 200 }}>
      <label>Slack channel</label>
      <div
        css={{
          display: 'flex',
        }}
      >
        <ShInputText
          value={value ? `#${value}` : ''}
          onChange={() => console.log('should not')}
          disabled={true}
        />
        {value && (
          <ShButton onClick={() => setSlackChannel(undefined)} variant={'red'}>
            Clear
          </ShButton>
        )}
      </div>
    </div>
  );
};

const useChannelOptions = (channelsAsync: any): ShInputAutocompleteOption[] => {
  return useMemo(() => {
    if (!channelsAsync.result) {
      return [];
    }
    return channelsAsync.result.channels.map((channel: ShSlackChannelDTO) => ({
      value: channel.id,
      text: channel.name,
      content: <div css={{ fontSize: 14 }}>#{channel.name}</div>,
    }));
  }, [channelsAsync]);
};

export const ShSlackChannelForm = ({
  slackChannel,
  setSlackChannel,
}: {
  slackChannel?: ShSlackChannelDTO;
  setSlackChannel: (slackChannel?: ShSlackChannelDTO) => void;
}) => {
  const hasNoChannel = !slackChannel?.name;

  return (
    <div
      css={{
        border: `1px solid ${ShColors.pattern}`,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        gap: 20,
      }}
    >
      {!hasNoChannel && (
        <SlackChannelField
          slackChannel={slackChannel}
          setSlackChannel={setSlackChannel}
        />
      )}
      {hasNoChannel && (
        <SlackChannelAutoComplete setSlackChannel={setSlackChannel} />
      )}
      <div css={{ marginTop: 23 }}>
        <ShIntercomArticles id={7852361}>
          <ShHelpModalDiv />
        </ShIntercomArticles>
      </div>
      <div css={{ marginTop: 23 }}>
        <small>
          Set a channel to get notified on booking. You need to invite @ShBot to
          the channel by tiping <b>/invite @ShBot</b> in the channel.
        </small>
      </div>
    </div>
  );
};
