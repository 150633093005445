import React, { useState, ReactNode } from 'react';

import { H4 } from 'primitives/appTitle';

import { useOrderPageContext } from '../../context/orderPageContext';
import classnames from 'classnames';
import { CartOffersPart } from '../../components/cartProgress';
import { useCurrentClientUser } from 'state/currentUserState';

import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';

import { useOrderCurrency } from '../../utils/orderPageUtils';
import { OrderSummaryAdmin } from '../../../../appAPITypes';
import { ShText } from '@shoootin/translations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { OrderStepPayment } from '../../orderPageTranslations';
import { getCountryFlag } from '../../../../appConstants';
import { StepOrderBillingWrapper } from '../order/stepOrderBilling';
import { ShBillingEntityDTO } from '@shoootin/api';
import { ShPrice } from '@shoootin/components-web';
import { ShMoment } from '@shoootin/utils';
import { ShMediaQueries, ShFonts } from '@shoootin/design-tokens';

const OrderSummaryItem = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <div
      css={{
        lineHeight: 2,
        [ShMediaQueries.ShMobileOnly]: {
          fontSize: 13,
        },
      }}
    >
      <dt
        css={{
          [ShMediaQueries.ShDesktopsOnly]: {
            display: 'inline',
          },
        }}
      >
        {label}
      </dt>
      <dd
        css={{
          fontFamily: ShFonts.secondary,
          marginLeft: 0,
          [ShMediaQueries.ShDesktopsOnly]: {
            display: 'inline',
            marginLeft: 20,
          },
        }}
      >
        {value}
      </dd>
    </div>
  );
};

const OrderSummaryContact = () => {
  const { state } = useOrderPageContext();
  const currentUser = useCurrentClientUser();
  const isAdmin = useIsOrderAdminMode();

  const contactOnSite = state.infos.contactOnSite;

  let contactName;
  let contactPhone;
  if (contactOnSite) {
    if (isAdmin) {
      const admin = state.admin!.clientUser!;
      contactName = admin.userInfos;
      contactPhone = '';
    } else {
      if (currentUser) {
        const isSameUser = currentUser.id === state.userId;
        if (isSameUser) {
          contactName = `${currentUser.firstName} ${currentUser.lastName}`;
          contactPhone = '';
        } else {
          const user = state.users.find(u => u.id === state.userId);
          contactName = user?.name;
          contactPhone = user?.mobilePhone;
        }
      }
      if (state.registration) {
        contactName = `${state.registration.firstName} ${state.registration.lastName}`;
        contactPhone = state.registration.mobilePhone;
      }
    }
  } else {
    contactName = state.infos.contactName;
    contactPhone = state.infos.contactPhoneNumber;
  }

  return (
    <OrderSummaryItem
      label={
        <ShText
          message={getFrontMessage('form_shootingInfos_contactCoordinates')}
        />
      }
      value={`${contactName} ${contactPhone}`}
    />
  );
};

export const OrderSummaryContent = ({ canGoBack }: { canGoBack: boolean }) => {
  const { state, steps } = useOrderPageContext();

  return (
    <>
      <H4>
        {ShMoment(state.selectedSlot!.start)
          .tz(state.timeZone)
          .format('LLLL')}{' '}
        -{' '}
        {ShMoment(state.selectedSlot!.end)
          .tz(state.timeZone)
          .format('LT')}{' '}
      </H4>
      <small>({state.timeZone})</small>
      {/*{canGoBack && (
        <a
          className="form-payment-modify"
          onClick={() => {
            steps.goTo('book');
          }}
        >
          <ShText message={getFrontMessage('common_actions_modify')} />
        </a>
      )}*/}
      <dl css={{ marginTop: 20, marginBottom: 20 }}>
        <OrderSummaryItem
          label={
            <ShText
              message={getFrontMessage('form_address_address_PlaceHolder')}
            />
          }
          value={state.address!.address}
        />
        <OrderSummaryContact />
        <OrderSummaryItem
          label={<ShText message={OrderStepPayment.rdv} />}
          value={
            state.infos.meetDownstairs ? (
              <ShText
                message={getFrontMessage('form_shootingInfos_meetDownstairs')}
              />
            ) : (
              state.infos.accessInformations
            )
          }
        />
        {state.infos.reference && (
          <OrderSummaryItem
            label={
              <ShText
                message={getFrontMessage(
                  'form_shootingInfos_referenceLabel_Label',
                )}
              />
            }
            value={state.infos.reference}
          />
        )}

        {state.infos.comment && (
          <OrderSummaryItem
            label={
              <ShText
                message={getFrontMessage('form_shootingInfos_comment_Label')}
              />
            }
            value={state.infos.comment}
          />
        )}
      </dl>
    </>
  );
};

const BillingEntitySummary = ({
  billingEntity,
  billingEntities,
  setBillingEntity,
  setBillingEntities,
  canAddBillingEntity,
}: {
  billingEntity: ShBillingEntityDTO;
  billingEntities: ShBillingEntityDTO[];
  setBillingEntity: (entityId: string) => void;
  setBillingEntities: (entities: ShBillingEntityDTO[]) => void;
  canAddBillingEntity: boolean;
}) => {
  const [changeBillingEntity, setChangeBillingEntity] = useState(false);

  return (
    <article className="cart-item">
      <p className="cart-item__title">
        <b>
          <ShText message={'client_billingEntity_orderTitle'} />
        </b>
      </p>
      <div className="cart-item__detail">
        {!changeBillingEntity && (
          <div css={{ display: 'flex' }}>
            <div
              css={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            >
              {billingEntity.name && <>{billingEntity.name}</>}
              <div>
                <img
                  src={getCountryFlag(billingEntity.countryCode)}
                  css={{
                    width: 20,
                    objectFit: 'contain',
                    verticalAlign: 'sub',
                  }}
                />{' '}
                {billingEntity.company}
              </div>

              <small>{billingEntity.address}</small>
            </div>
            <div css={{ textAlign: 'right', paddingLeft: 20 }}>
              <a
                onClick={() => {
                  setChangeBillingEntity(true);
                }}
                css={{ cursor: 'pointer' }}
              >
                <ShText message={'client_billingEntity_orderChange'} />
              </a>
            </div>
          </div>
        )}
        {changeBillingEntity && (
          <div css={{ display: 'flex' }}>
            <div
              css={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            >
              <StepOrderBillingWrapper
                billingEntity={billingEntity.id}
                clientBillingEntities={billingEntities}
                setBillingEntity={id => {
                  setBillingEntity(id);
                  setChangeBillingEntity(false);
                }}
                setBillingEntities={setBillingEntities}
                canAddBillingEntity={canAddBillingEntity}
              />
            </div>

            <div css={{ textAlign: 'right', paddingLeft: 20 }}>
              <a
                onClick={() => {
                  setChangeBillingEntity(false);
                }}
                css={{ cursor: 'pointer' }}
              >
                <ShText message={'common_actions_cancel'} />
              </a>
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export const OrderSummaryWrapper = () => {
  const [summaryPanelOpened, setSummaryPanelOpened] = useState(true);
  const {
    state,
    computed,
    api: { changeBillingEntityInSummary, setBillingEntities },
  } = useOrderPageContext();
  const orderSummary = computed.orderSummary!;
  const isAdmin = useIsOrderAdminMode();
  const adminSummary = isAdmin ? state.orderSummary!.admin : undefined;

  const displayBillingEntity =
    state.billingEntity && state.clientBillingEntities.length > 0;
  const billingEntity = displayBillingEntity
    ? state.clientBillingEntities.find(
        entity => entity.id === state.billingEntity,
      )
    : undefined;
  return (
    <div className={'form-payment-summary'}>
      <div className="form-payment-summary__header ">
        <div>
          <OrderSummaryContent canGoBack={true} />
        </div>
        {!isAdmin && (
          <a
            css={{
              cursor: 'pointer',
              textAlign: 'right',
            }}
          >
            {!summaryPanelOpened && (
              <span
                className="show"
                onClick={() => setSummaryPanelOpened(!summaryPanelOpened)}
              >
                <ShText message={OrderStepPayment.showDetails} />
              </span>
            )}
            {summaryPanelOpened && (
              <span
                className="hide"
                onClick={() => setSummaryPanelOpened(!summaryPanelOpened)}
              >
                <ShText message={OrderStepPayment.hideDetails} />
              </span>
            )}
          </a>
        )}
      </div>
      <div
        className="form-payment-summary__panel"
        css={{ display: summaryPanelOpened ? 'block' : 'none' }}
      >
        {billingEntity && (
          <BillingEntitySummary
            billingEntity={billingEntity}
            billingEntities={state.clientBillingEntities}
            setBillingEntity={changeBillingEntityInSummary}
            setBillingEntities={setBillingEntities}
            canAddBillingEntity={state.canAddBillingEntity}
          />
        )}
        <CartOffersPart orderSummary={orderSummary} />
        {isAdmin && <StepPaymentSummaryAdmin adminSummary={adminSummary!} />}
      </div>
    </div>
  );
};

const StepPaymentSummaryAdmin = ({
  adminSummary,
}: {
  adminSummary: OrderSummaryAdmin;
}) => {
  const currency = useOrderCurrency();

  return (
    <>
      <article className="cart-item">
        <p className="cart-item__title">
          <b>Photographer payment</b>
          <span className="cart-item__price">
            <ShPrice
              currency={currency}
              price={adminSummary.photographerPayment}
            />
          </span>
        </p>
        <p className="cart-item__detail">{adminSummary.photographerName}</p>
      </article>
    </>
  );
};
