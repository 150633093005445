import React from 'react';
import { AppSection } from 'primitives/appSection';
import { H2, H3 } from 'primitives/appTitle';

import {
  computeScanElements,
  OrderSummaryComputed,
  useOfferCustomComponentsString,
  useOrderCurrency,
  computeFloorPlanElements,
} from 'page/order/utils/orderPageUtils';
import { useOrderPageContext } from '../context/orderPageContext';
import {
  FloorPlanConfig,
  ScanConfig,
  VideoConfig,
} from '../context/orderPageContextState';
import { OrderSummaryDTO } from '../../../appAPITypes';

import { ShText } from '@shoootin/translations';
import { FrontCommonMessages } from '../../../appCommonTranslations';
import {
  OrderStepConfirmation,
  OrderStepOrder,
} from '../orderPageTranslations';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { OrderSummaryContent } from './payment/stepPaymentSummary';
import { adminShootingUrl, clientHomeUrl } from 'appEnv';
import { useCurrentClientUser } from '../../../state/currentUserState';
import { useIsOrderAdminMode } from '../utils/orderPageAdminUtils';
import { ShPaymentMethod } from '@shoootin/config';
import { ShPaymentMethodTilesConfig, ShPrice } from '@shoootin/components-web';
import { gtagEventPurchase } from '../../../appAnalytics';
import {
  ShOfferCustomDTO,
  ShOfferFloorPlanDTO,
  ShOfferOptionDTO,
  ShOfferPhotoDTO,
  ShOfferScanDTO,
  ShOfferVideoDTO,
} from '@shoootin/api';

const StepConfirmationPayment = ({
  orderSummary,
  selectedPaymentMethod,
}: {
  orderSummary: OrderSummaryDTO;
  selectedPaymentMethod: ShPaymentMethod;
}) => {
  const currency = useOrderCurrency();
  return (
    <article className="cart-item">
      <p className="cart-item__line">
        <ShText message={getFrontMessage('common_order_payment')} />
        <span>{ShPaymentMethodTilesConfig[selectedPaymentMethod].label}</span>
      </p>
      <p className="cart-item__line">
        <ShText
          message={getFrontMessage('common_order_totalPriceWithoutTax')}
        />
        <span>
          <ShPrice price={orderSummary!.priceWithoutTax} currency={currency} />
        </span>
      </p>
      {orderSummary!.combinedDiscount > 0 && (
        <p className="cart-item__line">
          <ShText message={OrderStepOrder.cartDiscount} />

          <span>
            <ShPrice
              price={orderSummary!.combinedDiscount}
              currency={currency}
            />
          </span>
        </p>
      )}
      {orderSummary!.discountAmount > 0 && (
        <p className="cart-item__line">
          <ShText message={getFrontMessage('form_offer_coupon_Label')} />{' '}
          {orderSummary!.discountCode}
          <span>
            <ShPrice price={orderSummary!.discountAmount} currency={currency} />
          </span>
        </p>
      )}

      {orderSummary!.reduceWithCreditAmount > 0 && (
        <p className="cart-item__line">
          <ShText
            message={getFrontMessage('common_order_includingCreditWithoutTax')}
          />
          <span>
            <ShPrice
              price={orderSummary!.reduceWithCreditAmount}
              currency={currency}
            />
          </span>
        </p>
      )}
      <p className="cart-item__line">
        <ShText message={getFrontMessage('common_order_tax')} />
        <span>
          <ShPrice price={orderSummary!.tax} currency={currency} />
        </span>
      </p>
      <p className="cart__total title">
        <ShText message={getFrontMessage('common_order_totalPriceWithTax')} />{' '}
        <span className="cart__price">
          <ShPrice price={orderSummary!.priceWithTax} currency={currency} />
        </span>
      </p>
    </article>
  );
};

const StepConfirmationSummaryPhotoOffer = ({
  photoTotalNb,
  offerPhoto,
  options,
}: {
  photoTotalNb: number;
  offerPhoto: ShOfferPhotoDTO;
  options: ShOfferOptionDTO[];
}) => {
  return (
    <p className="form-payment-summary__line">
      <b>
        <ShText message={FrontCommonMessages.photo} />
      </b>
      <ShText
        message={FrontCommonMessages.nPhotos}
        values={{ n: photoTotalNb }}
      />{' '}
      — {offerPhoto.name}
      {options.length > 0 && (
        <>
          <br />
          <b>
            <small>Options</small>
          </b>{' '}
          including
          {options.map((option: ShOfferOptionDTO, i) => (
            <React.Fragment key={i}>
              {' '}
              <ShText
                message={FrontCommonMessages.nPhotos}
                values={{ n: option.photosNb }}
              />{' '}
              — {option.category},
            </React.Fragment>
          ))}
        </>
      )}
    </p>
  );
};

const StepConfirmationSummaryVideoOffer = ({
  offerVideo,
  offerVideoConfig,
}: {
  offerVideo: ShOfferVideoDTO;
  offerVideoConfig: VideoConfig;
}) => {
  return (
    <p className="form-payment-summary__line">
      <b>
        <ShText message={FrontCommonMessages.video} />
      </b>
      {offerVideo.name}
      {offerVideoConfig.music && <>+ Option musique</>}
    </p>
  );
};

const StepConfirmationSummaryScanOffer = ({
  offerScan,
  offerScanConfig,
}: {
  offerScan: ShOfferScanDTO;
  offerScanConfig: ScanConfig;
}) => {
  const elements = computeScanElements(offerScan, offerScanConfig);
  return (
    <p className="form-payment-summary__line">
      <b>
        <ShText message={FrontCommonMessages.scan} />
      </b>
      {elements.map((element, index) => (
        <React.Fragment key={index}>
          {element}
          {index !== elements.length - 1 ? ' — ' : ''}
        </React.Fragment>
      ))}
    </p>
  );
};

const StepConfirmationSummaryFloorPlanOffer = ({
  offerFloorPlan,
  offerFloorPlanConfig,
}: {
  offerFloorPlan: ShOfferFloorPlanDTO;
  offerFloorPlanConfig: FloorPlanConfig;
}) => {
  const elements = computeFloorPlanElements(
    offerFloorPlan,
    offerFloorPlanConfig,
  );
  return (
    <p className="form-payment-summary__line">
      <b>
        <ShText message={FrontCommonMessages.floorPlan} />
      </b>
      {elements.map((element, index) => (
        <React.Fragment key={index}>
          {element}
          {index !== elements.length - 1 ? ' — ' : ''}
        </React.Fragment>
      ))}
    </p>
  );
};

const StepConfirmationSummaryCustomOffer = ({
  offerCustom,
  offerScanConfig,
  offerFloorPlanConfig,
}: {
  offerCustom: ShOfferCustomDTO;
  offerScanConfig: ScanConfig | undefined;
  offerFloorPlanConfig: FloorPlanConfig | undefined;
}) => {
  const offerComponents = useOfferCustomComponentsString(offerCustom);
  const scanElements =
    !!offerScanConfig &&
    !!offerCustom.scan &&
    computeScanElements(offerCustom, offerScanConfig);

  const floorPlanElements =
    !!offerFloorPlanConfig &&
    !!offerCustom.floorPlan &&
    computeFloorPlanElements(offerCustom, offerFloorPlanConfig);

  return (
    <p className="form-payment-summary__line">
      <b>
        <ShText message={getFrontMessage('form_offer_tailorMadeOffer')} />
      </b>
      {offerCustom.name} <br />
      {offerComponents} <br />
      {scanElements &&
        scanElements.map((element, i) => (
          <React.Fragment key={i}>
            {element}
            <br />
          </React.Fragment>
        ))}
      {floorPlanElements &&
        floorPlanElements.map((element, i) => (
          <React.Fragment key={i}>
            {element}
            <br />
          </React.Fragment>
        ))}
    </p>
  );
};

const StepConfirmationSummary = ({
  orderSummary,
}: {
  orderSummary: OrderSummaryComputed;
}) => {
  return (
    <article className="cart-item">
      {orderSummary.offers!.photo && (
        <StepConfirmationSummaryPhotoOffer
          photoTotalNb={orderSummary.photoTotalNb}
          offerPhoto={orderSummary.offers.photo}
          options={orderSummary.offers.photoOptions}
        />
      )}
      {orderSummary.offers!.video && (
        <StepConfirmationSummaryVideoOffer
          offerVideo={orderSummary.offers.video}
          offerVideoConfig={orderSummary.offers.videoConfig!}
        />
      )}
      {orderSummary.offers!.scan && (
        <StepConfirmationSummaryScanOffer
          offerScan={orderSummary.offers.scan}
          offerScanConfig={orderSummary.offers.scanConfig!}
        />
      )}

      {orderSummary.offers!.floorPlan && (
        <StepConfirmationSummaryFloorPlanOffer
          offerFloorPlan={orderSummary.offers.floorPlan}
          offerFloorPlanConfig={orderSummary.offers.floorPlanConfig!}
        />
      )}
      {orderSummary.offers!.custom && (
        <StepConfirmationSummaryCustomOffer
          offerCustom={orderSummary.offers.custom}
          offerScanConfig={orderSummary.offers.scanConfig!}
          offerFloorPlanConfig={orderSummary.offers.floorPlanConfig!}
        />
      )}
    </article>
  );
};

export {};

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const StepConfirmation = () => {
  const { state, computed, isCheckout } = useOrderPageContext();
  const orderSummary = computed.orderSummary!;
  const currentUser = useCurrentClientUser();
  const isAdmin = useIsOrderAdminMode();

  gtagEventPurchase(
    computed.orderSummary!,
    state.currency,
    state.discountCode,
    state.restoration?.shootingId,
  );

  return (
    <>
      <AppSection
        header={
          <H2>
            <ShText message={OrderStepConfirmation.title} />
          </H2>
        }
        className="form-payment left"
      >
        <p className="form-payment-intro">
          {state.selectedPaymentMethod === 'ORDER' && (
            <ShText message={OrderStepConfirmation.introOrdered} />
          )}
          {state.selectedPaymentMethod !== 'ORDER' && (
            <ShText message={OrderStepConfirmation.introConfirmed} />
          )}
        </p>

        <H3>
          <ShText message={getFrontMessage('common_shooting_yourShoot')} />
        </H3>
        <div className="form-payment-summary">
          <div className="form-payment-summary__header">
            <div>
              <OrderSummaryContent canGoBack={false} />
            </div>
          </div>
          <div className="form-payment-summary__panel">
            <StepConfirmationSummary orderSummary={orderSummary} />

            <StepConfirmationPayment
              orderSummary={state.orderSummary!}
              selectedPaymentMethod={state.selectedPaymentMethod!}
            />
          </div>
        </div>

        {isAdmin && state.restoration && state.restoration.shootingId && (
          <a
            href={adminShootingUrl(state.restoration.shootingId)}
            css={{ textDecoration: 'none' }}
          >
            <button className="btn form-payment-back btn large">
              Go to BO
            </button>
          </a>
        )}

        {!isAdmin && !isCheckout && currentUser && (
          <a href={clientHomeUrl()} css={{ textDecoration: 'none' }}>
            <button className="btn form-payment-back btn large">
              <ShText message={getFrontMessage('front_common_myAccount')} />
            </button>
          </a>
        )}
      </AppSection>
    </>
  );
};

export default StepConfirmation;
