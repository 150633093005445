import React from 'react';
import AppImage from '../../../../primitives/appImage';
import { ShMediaQueries } from '@shoootin/design-tokens';
import { ShCustomOrder, ShCustomOrdersConfig } from '@shoootin/config';

const StepOrderBranding = ({ branding }: { branding: ShCustomOrder }) => {
  console.log('StepOrderBranding', branding, ShCustomOrdersConfig[branding]);
  const { image, subtitle } = ShCustomOrdersConfig[branding];
  return (
    <div className="container" css={{ padding: '0 !important', marginTop: 30 }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          [ShMediaQueries.ShMobileOnly]: {
            flexWrap: 'wrap',
          },
        }}
      >
        <div
          css={{
            [ShMediaQueries.ShMobileOnly]: {
              textAlign: 'center',
              width: '100%',
              marginBottom: 20,
            },
          }}
        >
          <AppImage
            src={image}
            lazy={false}
            css={{
              maxWidth: 300,
              maxHeight: 140,
            }}
          />
        </div>
        <div
          css={{
            flexGrow: 1,
            justifyContent: 'center',
            marginLeft: 50,
            [ShMediaQueries.ShMobileOnly]: {
              textAlign: 'center',
              width: '100%',
            },
          }}
        >
          {subtitle && (
            <div css={{ fontSize: '1rem', fontFamily: 'Montserrat' }}>
              {subtitle}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepOrderBranding;
