import React from 'react';
import { useOrderPageContext } from 'page/order/context/orderPageContext';

import { useOrderCurrency } from 'page/order/utils/orderPageUtils';
import StepOrderScanForm from './stepOrderScanForm';
import StepOrderSwitchBox, {
  useStepOrderBasicOfferSwitchBox,
} from './stepOrderSwitchBox';

import idx from 'idx';
import { ShootingFields } from './stepOrderFields';
import { ShText } from '@shoootin/translations';
import { FrontCommonMessages } from '../../../../appCommonTranslations';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import {
  ShPrice,
  ShSwitchBoxElementPanelHeader,
  ShSwitchHelp,
  ShTitle,
} from '@shoootin/components-web';
import StepOrderOfferAdminInformations from './stepOrderOfferAdminInformations';
import { ShOfferScanDTO } from '@shoootin/api';

const StepOrderScan = ({ scanOffer }: { scanOffer: ShOfferScanDTO }) => {
  const {
    state: { selectedOffers, validationErrors },
    api: { selectScanOffer, mutateScanConfig, deleteScanOffer },
    computed: { orderSummary },
  } = useOrderPageContext();
  const currency = useOrderCurrency();
  const [opened, setOpened] = useStepOrderBasicOfferSwitchBox(
    selectedOffers,
    'scan',
  );
  const isAdmin = useIsOrderAdminMode();

  return (
    <StepOrderSwitchBox
      key="matterport"
      service="matterport"
      opened={opened}
      onOpenedChange={(value) => {
        if (value) {
          selectScanOffer(scanOffer);
          setOpened(value);
        } else {
          deleteScanOffer();
          setOpened(value);
        }
      }}
      label={
        <ShTitle size={4} color={opened ? 'white' : 'black'}>
          <ShText message={FrontCommonMessages.scan} />{' '}
        </ShTitle>
      }
      labelRight={
        <ShTitle size={4} color={'white'}>
          <ShPrice price={orderSummary!.scanTotalPrice} currency={currency} />
        </ShTitle>
      }
    >
      {isAdmin && (
        <ShSwitchBoxElementPanelHeader>
          <StepOrderOfferAdminInformations offer={scanOffer} />
        </ShSwitchBoxElementPanelHeader>
      )}
      <>
        <StepOrderScanForm
          scanOffer={scanOffer}
          scanConfig={selectedOffers.scanConfig}
          mutateScanConfig={mutateScanConfig}
          scanValidationErrors={idx(
            validationErrors,
            (_) => _.offers.scanErrors,
          )}
        />

        {scanOffer.shootingFields.length > 0 && (
          <div
            css={{
              paddingLeft: '1.5rem',
              paddingRight: '1.5rem',
            }}
          >
            <ShootingFields
              typeOfComponent="scan"
              fields={scanOffer.shootingFields}
            />
          </div>
        )}
        <ShSwitchHelp modalName="offerScan" />
      </>
    </StepOrderSwitchBox>
  );
};

export default StepOrderScan;
