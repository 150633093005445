import React, { useMemo, useState } from 'react';
import { AppSection } from 'primitives/appSection';
import { H2, H4 } from 'primitives/appTitle';
import LoginForm from '../../login/components/loginForm';
import RegisterForm, {
  useEmptyRegisterForm,
} from '../../login/components/registerForm';
import { useOrderPageContext } from '../context/orderPageContext';
import { AppButton } from '../../../primitives/appButton';
import { useCurrentClientUser } from '../../../state/currentUserState';
import { useLogoutAction } from '../../../appAuth';
import OrderBack from '../components/orderBack';
import { ShText } from '@shoootin/translations';
import { OrderStepLogin } from '../orderPageTranslations';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { reportError } from '../../../appAnalytics';

const OrderRegisterFormSection = ({
  onToggleClick,
}: {
  onToggleClick: () => void;
}) => {
  const {
    state,
    api: { handleRegistrationSubmit },
  } = useOrderPageContext();

  // We assume that the registration country is the same as the first shooting country
  const shootingCountry = state.country;
  const emptyRegisterForm = useEmptyRegisterForm(shootingCountry);

  const initialValues = {
    ...emptyRegisterForm,
    ...state.registration,
  };

  return (
    <AppSection
      className=""
      header={
        <H2>
          <ShText message={OrderStepLogin.signup_title} />
        </H2>
      }
    >
      <div className="form-section half">
        <OrderBack />
      </div>
      <RegisterForm
        initialValues={initialValues}
        submitAppForm={handleRegistrationSubmit}
        extractFormErrors={responseData => responseData.registration}
      />
      <div className="form-section half">
        <H4>
          <ShText message={OrderStepLogin.signup_subtitle_alreadyAccount} />
        </H4>
        <div className="field-row">
          <AppButton modifier="large" onClick={onToggleClick}>
            <ShText message={getFrontMessage('common_actions_login')} />
          </AppButton>
        </div>
      </div>
    </AppSection>
  );
};

const OrderLoginFormSection = ({
  onToggleClick,
}: {
  onToggleClick: () => void;
}) => {
  const {
    api: { handleLoginSubmit },
  } = useOrderPageContext();
  return (
    <AppSection
      header={
        <H2>
          <ShText message={OrderStepLogin.login_title} />
        </H2>
      }
    >
      <div className="form-section half">
        <OrderBack />
      </div>
      <LoginForm submitAppForm={handleLoginSubmit} />
      <div className="form-section half">
        <H4>
          <ShText message={OrderStepLogin.login_subtitle} />
        </H4>
        <div className="field-row">
          <AppButton modifier="large" onClick={onToggleClick}>
            <ShText message={OrderStepLogin.login_createAccount} />
          </AppButton>
        </div>
      </div>
    </AppSection>
  );
};

// Need this because if user starts ordering logged-out, he has this login step
// To avoid weird UX, we don't "remove" that step in the breadcrumb after a successful login (would be strange to go from 5 steps to 4)
// The login step remains accessible after a successful login if the user goes back or click the step in the breadcrumb
// So we need to handle this case where user goes back to this step while he's already logged
const AlreadyLoggedSection = ({ onLogout }: { onLogout: () => void }) => {
  const { steps } = useOrderPageContext();
  const currentUser = useCurrentClientUser()!;
  const logoutAction = useLogoutAction();

  const doLogout = async () => {
    try {
      await logoutAction();
    } catch (e) {
      reportError(e);
    }
    // call onLogout anyway even if async request fails anyway
    onLogout();
  };
  return (
    <AppSection
      header={
        <H2>
          <ShText message={OrderStepLogin.logged_title} />
        </H2>
      }
    >
      <div className="form-section half">
        {/*<H4>*/}
        {/*<AppText message={OrderStepLogin.logged_subtitle} />*/}
        {/*</H4>*/}
        <div>
          {currentUser && (
            <ShText
              message={OrderStepLogin.logged_message}
              values={{
                firstName: currentUser.firstName,
                email: currentUser.email,
              }}
            />
          )}
        </div>
        <div className="field-row" css={{ marginBottom: 30 }}>
          <AppButton modifier="light" onClick={() => doLogout()}>
            <ShText message={OrderStepLogin.logged_changeAccount} />
          </AppButton>
        </div>
        <div className="field-row">
          <AppButton modifier="large" onClick={() => steps.next()}>
            <ShText message={OrderStepLogin.logged_keepAccount} />
          </AppButton>
        </div>
      </div>
    </AppSection>
  );
};

type StepLoginMode = 'login' | 'signup' | 'logged';

const StepLogin = () => {
  const currentUser = useCurrentClientUser();
  const [mode, setMode] = useState<StepLoginMode>(() => {
    return currentUser ? 'logged' : 'signup';
  });
  return (
    <>
      {mode === 'signup' && (
        <OrderRegisterFormSection onToggleClick={() => setMode('login')} />
      )}
      {mode === 'login' && (
        <OrderLoginFormSection onToggleClick={() => setMode('signup')} />
      )}
      {mode === 'logged' && (
        <AlreadyLoggedSection onLogout={() => setMode('login')} />
      )}
    </>
  );
};

export default StepLogin;
