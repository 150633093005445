import React from 'react';
import { OrderPageState } from './orderPageContextState';
import { OrderPageAPI } from './orderPageContextAPI';
import { isDev } from 'appEnv';
import { AwesomeState } from '../../../hooks/useAwesomeState';

// Use this hook to help your debugging / development only
// This may help you restore manually a hardcoded shooting state to help you do your dev faster
const useOrderPageContextDebug = (
  api: OrderPageAPI,
  awesomeState: AwesomeState<OrderPageState>,
) => {
  // only enabled in dev
  // yes we shouldn't do an if in a hook but it's safe because isDev is constant
  if (!isDev) {
    return;
  }

  // Do fancy stuff here, to help you setup the form in the state you want faster
};

export default useOrderPageContextDebug;
