/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';

import LockSvg from '../../../../components/svg/icon/LockSvg';
import { ShInputText, ShInputTextProps } from '../ShInputText/ShInputText';

export type ShInputPasswordProps = Omit<
  ShInputTextProps,
  'type' | 'icon' | 'required' | 'placeholder'
>;

export const ShInputPassword = (props: ShInputPasswordProps) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const ShInputPasswordIcon = () => <LockSvg width={24} color={'black'} />;
  return (
    <ShInputText
      {...props}
      type={isPasswordVisible ? 'text' : 'password'}
      icon={{
        component: ShInputPasswordIcon,
        onClick: () => setPasswordVisible(!isPasswordVisible),
      }}
      required={false}
      placeholder={'Mot de passe'}
    />
  );
};
