import React, { useEffect, useState } from 'react';
import { AppSection } from 'primitives/appSection';
import { H2 } from 'primitives/appTitle';
import { AppMap, AppMapMarker } from 'primitives/maps/appMapElements';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { getFrontMessage } from 'appMessageDefinitions';
import idx from 'idx';
import StepOrderAdminClientSelection from './stepOrderAdminClientSelection';
import { ShText, useShTranslate } from '@shoootin/translations';
import { OrderStepLabels, OrderStepOrder } from '../../orderPageTranslations';
import { useGoogleMapsLoaded } from '../../../../primitives/maps/appMapLoader';
import {
  ShCountriesForOrder,
  ShCountry,
  ShCountryConfigs,
  ShCustomOrdersConfig,
} from '@shoootin/config';
import { useOrderPageBranding } from '../../utils/orderPageBrandings';
import {
  ShAddressAutocomplete,
  ShCountrySelect,
  ShSpinner,
} from '@shoootin/components-web';
import { getGooglePlaceDetails } from '@shoootin/utils';
import usePrevious from 'hooks/usePrevious';

const StepOrderMap = () => {
  const {
    state: { country, address, validationErrors, admin },
    api: { setCountry, selectAddress, deleteAddress },
  } = useOrderPageContext();
  const [addressText, setAddressText] = useState(
    address ? address.address : '',
  );
  const mapsLoaded = useGoogleMapsLoaded();
  const canShowInputs = !admin || (admin && admin.clientUser);

  // Empty input if address is removed,
  // unless address got removed due to user typing in the input
  const previousAddressText = usePrevious(addressText);
  useEffect(() => {
    if (!address && previousAddressText === addressText) {
      setAddressText('');
    }
  }, [address]);

  const branding = useOrderPageBranding();

  const defaultCenter =
    branding &&
    ShCustomOrdersConfig[branding].lat &&
    ShCustomOrdersConfig[branding].lng
      ? {
          lat: ShCustomOrdersConfig[branding].lat,
          lng: ShCustomOrdersConfig[branding].lng,
        }
      : ShCountryConfigs[country ?? 'FR'].centerLatLng;

  const mapCenter = address
    ? { lat: address.latitude, lng: address.longitude }
    : defaultCenter;

  const defaultZoom =
    branding && ShCustomOrdersConfig[branding].zoom
      ? ShCustomOrdersConfig[branding].zoom
      : ShCountryConfigs[country ?? 'FR'].zoom;

  const mapZoom = address ? 15 : defaultZoom;

  const tr = useShTranslate();

  console.log({ mapZoom, mapCenter });

  return (
    <AppSection
      className="left form-order-location"
      header={
        <H2>
          <ShText message={OrderStepLabels.order} />
        </H2>
      }
    >
      {!mapsLoaded && <ShSpinner />}
      {mapsLoaded && (
        <div className="form-order-location__content">
          <p className="form-order-location__text mobile">
            <ShText message={OrderStepOrder.intro} />
          </p>
          <div className="form-order-location__caption">
            {admin && <StepOrderAdminClientSelection />}
            {canShowInputs && (
              <>
                <div
                  // don't know why css props won't work in env=production here...
                  style={{
                    marginBottom: '1em',
                  }}
                >
                  <ShCountrySelect
                    countries={ShCountriesForOrder}
                    value={country}
                    onChange={(country?: ShCountry) => {
                      if (country !== undefined) {
                        setAddressText(''); // Reset input on country change
                        setCountry(country);
                      }
                    }}
                    placeholder={tr(
                      getFrontMessage('form_address_country_PlaceHolder'),
                    )}
                  />
                </div>

                {/*{false && country && (
                  <AppGooglePlaceAutocompleteInput
                    key={country} // reset input on country change
                    value={addressText}
                    onChange={(text) => {
                      setAddressText(text);
                      // On input change we erase previously selected address, to force the user to reselect
                      address && deleteAddress();
                    }}
                    country={country}
                    modifier="large"
                    required
                    error={idx(validationErrors, (_) => _.address.address)}
                    onPlaceSelected={async (place) => {
                      const placeDetails = await getPlaceDetails(
                        place!.place_id,
                      );
                      await selectAddress(placeToOrderAddress(placeDetails));
                    }}
                  />
                )}*/}

                {country && (
                  <ShAddressAutocomplete
                    allowFreeText={false}
                    key={country + '_'}
                    placeholder={tr(
                      getFrontMessage('form_address_address_PlaceHolder'),
                    )}
                    value={addressText}
                    onChange={async (newValue, option) => {
                      console.debug('onChange', { newValue, option });
                      setAddressText(newValue);
                      if (option) {
                        const place = await getGooglePlaceDetails(
                          option.suggestion.placeId,
                        );
                        const location = place?.geometry?.location;
                        console.log('location', newValue, location);
                        if (location) {
                          setAddressText(newValue);
                          selectAddress({
                            address: newValue,
                            latitude: location.lat(),
                            longitude: location.lng(),
                          });
                        } else {
                          console.log('no location');
                          deleteAddress();
                        }
                      } else {
                        console.log('no option');
                        deleteAddress();
                      }
                    }}
                    country={country}
                    required
                    error={idx(validationErrors, (_) => _.address.address)}
                    themeSize={'l'}
                  />
                )}
                <p className="form-order-location__text desktop">
                  <ShText message={OrderStepOrder.intro} />
                </p>
              </>
            )}
          </div>
          <div className="form-order-location__map">
            <AppMap center={mapCenter} zoom={mapZoom}>
              {address && (
                <AppMapMarker
                  position={{ lat: address.latitude, lng: address.longitude }}
                />
              )}
            </AppMap>
          </div>
        </div>
      )}
    </AppSection>
  );
};

export default StepOrderMap;
