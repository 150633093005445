import React from 'react';

const ChevronUpSvg = (props: any) => (
  <svg
    className="chevron-up"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    fill="none"
    {...props}
  >
    <polyline points="2,11 8,5 14,11 " />
  </svg>
);

export default ChevronUpSvg;
