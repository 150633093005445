/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';

import { ShButton, ShButtonVariant, ShButtonSize } from '../ShButton/ShButton';
import { ShButtonLink } from '../ShButtonLink/ShButtonLink';

export const ShButtonBarSpacing = {
  none : 0,
  xs: 5,
  s: 10,
  m: 20,
  l: 40,
  xl: 80,
} as const;

export type ShButtonBarSpacingSize = keyof typeof ShButtonBarSpacing;
export const ShButtonBarSpacingSizeList = Object.keys(
  ShButtonBarSpacing,
) as ShButtonBarSpacingSize[];

type ShButtonBarButton = {
  type: 'button';
  children: ReactNode;
  onClick: () => void;
};
type ShButtonBarButtonLink = {
  type: 'link';
  children: ReactNode;
  href: string;
};

export type ShButtonBarItem = ShButtonBarButton | ShButtonBarButtonLink;

export type ShButtonBarProps = {
  items: ShButtonBarItem[];
  spacing?: ShButtonBarSpacingSize;
  variant?: ShButtonVariant;
  size?: ShButtonSize;
  disabled?: boolean;
};
export const ShButtonBar = ({
  items,
  spacing = 's',
  variant,
  size,
  disabled,
}: ShButtonBarProps) => {
  return (
    <ul
      css={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        listStyleType: 'none',
        margin: 0,
      }}
    >
      {items.map((button, i) => (
        <li
          key={i}
          css={{
            display: 'list-item',
            marginRight: `${ShButtonBarSpacing[spacing]}px`,
          }}
        >
          {button.type === 'button' ? (
            <ShButton
              variant={variant}
              size={size}
              disabled={disabled}
              onClick={button.onClick}
            >
              {button.children}
            </ShButton>
          ) : (
            <ShButtonLink
              href={button.href}
              theme={variant}
              size={size}
              disabled={disabled}
            >
              {button.children}
            </ShButtonLink>
          )}
        </li>
      ))}
    </ul>
  );
};
