import React, { useMemo } from 'react';
import { AppSection } from 'primitives/appSection';
import { H3 } from 'primitives/appTitle';
import { flatMap, intersection, uniq } from 'lodash';
import { ShText } from '@shoootin/translations';
import { OrderStepOrder } from '../../orderPageTranslations';
import { ShShootingType, ShShootingTypes } from '@shoootin/config';
import { ShOfferDTO } from '@shoootin/api';
import { ShSvgShootingType, ShTiles } from '@shoootin/components-web';

const getShootingTypes = (offers: ShOfferDTO[]): ShShootingType[] => {
  const typesFound: ShShootingType[] = uniq(
    flatMap(offers, (offer) => offer.shootingTypes),
  );
  const typesOrdered = intersection(ShShootingTypes, typesFound);
  return typesOrdered;
};

const StepOrderShootingType = ({
  offers,
  shootingType,
  setShootingType,
  canDisplayPortraitTile,
}: {
  offers: ShOfferDTO[];
  shootingType?: ShShootingType;
  setShootingType: (shootingType: ShShootingType) => void;
  canDisplayPortraitTile: boolean;
}) => {
  const tiles = useMemo(() => {
    const tiles = getShootingTypes(offers);
    if (canDisplayPortraitTile) {
      tiles.push('portrait');
    }
    return tiles;
  }, [offers, canDisplayPortraitTile]);

  return (
    <AppSection
      className="left form-order-types"
      header={
        <H3>
          <ShText message={OrderStepOrder.shootingType} />
        </H3>
      }
    >
      <ShTiles<ShShootingType>
        tiles={tiles}
        activeTiles={shootingType ? [shootingType] : []}
        onTilePress={setShootingType}
        renderTileLabel={(key) => (
          <ShText message={`enums_typesOfShooting_short_${key}`} />
        )}
        renderTileIcon={(key) => <ShSvgShootingType shootingType={key} />}
      />
    </AppSection>
  );
};

export default StepOrderShootingType;
