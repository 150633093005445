import React from 'react';

import {
  computeFloorPlanMaxSurface,
  computeFloorPlanSurfacePrice,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import {
  ShNumberRangeField,
  ShPrice,
  ShSwitchBoxElementField,
} from '@shoootin/components-web';
import {
  FloorPlanConfig,
  OrderOffersFloorPlanValidationErrors,
} from '../../context/orderPageContextState';
import idx from 'idx';
import { ShText, useShTranslate } from '@shoootin/translations';
import { OrderStepOrder } from '../../orderPageTranslations';
import { getFrontMessage } from 'appMessageDefinitions';
import { useIntercom } from 'utils/intercomUtils';
import { ShCountryConfigs } from '@shoootin/config';
import { ShOfferFloorPlanDTO } from '@shoootin/api';

const StepOrderFloorPlanForm = ({
  floorPlanOffer,
  floorPlanConfig,
  mutateFloorPlanConfig,
  floorPlanValidationErrors,
}: {
  floorPlanOffer: ShOfferFloorPlanDTO;
  floorPlanConfig: FloorPlanConfig;
  mutateFloorPlanConfig: (
    updater: (scanConfig: FloorPlanConfig) => void,
  ) => void;
  floorPlanValidationErrors?: OrderOffersFloorPlanValidationErrors | null;
}) => {
  const intercom = useIntercom();
  const currency = useOrderCurrency();
  const surfacePrice = computeFloorPlanSurfacePrice(
    floorPlanOffer,
    floorPlanConfig,
  );
  const maxSurface = computeFloorPlanMaxSurface(floorPlanOffer);
  const translate = useShTranslate();
  const metricSystem = ShCountryConfigs[floorPlanOffer.country].metricSystem;
  return (
    <ShSwitchBoxElementField>
      <ShNumberRangeField
        value={floorPlanConfig.surface}
        onChange={(value) =>
          mutateFloorPlanConfig((config) => {
            config.surface = value;
          })
        }
        label={{
          label: translate(getFrontMessage('common_order_surfaceToScan'), {
            metric: metricSystem ? 'm\u00B2' : 'sqft',
          }),
          legend: <ShText message={OrderStepOrder.scanSurfaceLegend} />,
          error: floorPlanConfig.surface >= maxSurface && (
            <>
              <ShText message={OrderStepOrder.scanSurfaceError} />{' '}
              <a
                css={{ cursor: 'pointer' }}
                onClick={intercom.openWithEmptyMessage}
              >
                <ShText message={getFrontMessage('common_actions_contactUs')} />
              </a>
            </>
          ),
          price: surfacePrice > 0 && (
            <ShPrice price={surfacePrice} currency={currency} />
          ),
        }}
        max={maxSurface}
        step={50}
        error={idx(floorPlanValidationErrors, (_) => _.surface)}
        service={'floorplan'}
      />
    </ShSwitchBoxElementField>
  );
};

export default StepOrderFloorPlanForm;
