import React from 'react';
import { ShText } from '@shoootin/translations';
import OrderErrorBanner from '../../components/orderErrorBanner';
import { ShShootingSlotDTO } from '@shoootin/api';
import { ShOrderStepBook } from '@shoootin/components-web';
import { ShMoment } from '@shoootin/utils';

export const UnavailableSlotBanner = ({
  unavailableSlot,
  timeZone,
}: {
  unavailableSlot: ShShootingSlotDTO;
  timeZone: string;
}) => {
  return (
    <OrderErrorBanner>
      <ShText
        message={ShOrderStepBook.unavailable}
        values={{
          initialSlot: ShMoment(unavailableSlot!.start)
            .tz(timeZone)
            .format('LLL'),
        }}
      />
    </OrderErrorBanner>
  );
};
