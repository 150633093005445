import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import {
  OrderStep,
  useOrderSteps,
} from 'page/order/context/orderPageContextSteps';
import { OrderPageState, useOrderPageState } from './orderPageContextState';
import { useOrderPageAPI } from './orderPageContextAPI';
import { useOrderPageComputed } from './orderPageContextComputed';
import useOrderPageContextDebug from './orderPageContextDebug';
import { FormErrorSubmitCountProvider } from '../../../primitives/input/formError';
import { reportError } from '../../../appAnalytics';

const useOrderPageContextValue = (
  config: {
    initialState?: OrderPageState;
    initialStep?: OrderStep;
    isCheckout?: boolean;
  } = {},
) => {
  const [state, awesomeState] = useOrderPageState(config.initialState);
  const steps = useOrderSteps(config.initialStep);
  const api = useOrderPageAPI(awesomeState, steps);
  const computed = useOrderPageComputed(state);

  const isCheckout = !!config.isCheckout;

  useOrderPageContextDebug(api, awesomeState);

  // There are a few cases where we enter the order process with an address in state:
  // - when coming from homapage address input (address is passed through location.state)
  // - when restoring a shooting
  // - in DEV, where we might hardcode an address for conveniency
  // We want to select the address so that shootingTypes get displayed under the address
  // /!\ this erase later form state, so this should not be done for restoration case
  useEffect(() => {
    const state = awesomeState.getState();
    if (!state.restoration && state.address) {
      api.validateAddress().catch(reportError);
    }
  }, []);

  return useMemo(() => {
    const contextValue = {
      state,
      api,
      steps,
      computed,
      isCheckout,
    };
    console.debug('context value change', contextValue);
    return contextValue;
  }, [state, api, steps, computed, isCheckout]);
};

type OrderPageContextValue = ReturnType<typeof useOrderPageContextValue>;

const OrderPageContext = React.createContext<OrderPageContextValue>(
  null as any,
);

export const OrderPageContextProvider = ({
  initialState,
  initialStep,
  isCheckout,
  children,
}: {
  initialState?: OrderPageState;
  initialStep?: OrderStep;
  isCheckout?: boolean;
  children: ReactNode;
}) => {
  const contextValue = useOrderPageContextValue({
    initialState,
    initialStep,
    isCheckout,
  });
  return (
    <OrderPageContext.Provider value={contextValue}>
      {children}
    </OrderPageContext.Provider>
  );
};

export const useOrderPageContext = (): OrderPageContextValue => {
  const contextValue = useContext(OrderPageContext);
  if (!contextValue) {
    throw new Error(
      'useOrderPageContext returns null => you have to use the OrderPageContextProvider',
    );
  }
  return contextValue;
};
