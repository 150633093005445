import { ShInputFileUploadResult } from '@shoootin/components-web';
import { ShMomentType } from '@shoootin/utils';
import { isDev } from '../../../appEnv';

export type DroneState = {
  comment?: string;
  autorisationFile?: ShInputFileUploadResult;
  autorisationFileLoading?: boolean;
  missionLetterFile?: ShInputFileUploadResult;
  missionLetterFileLoading?: boolean;
  extraInfosFile?: ShInputFileUploadResult;
  extraInfosFileLoading?: boolean;
  dates: ShMomentType[];
  bounds: google.maps.LatLngLiteral[];
  cgv: boolean;

  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;

  success?: boolean;
};

const debug = true;
export const defaultDroneState: DroneState = {
  autorisationFileLoading: false,
  missionLetterFileLoading: false,
  extraInfosFileLoading: false,
  dates: [],
  bounds: [],
  cgv: false,
  firstName: isDev && debug ? 'John' : undefined,
  lastName: isDev && debug ? 'Doe' : undefined,
  email: isDev && debug ? 'john@shooot.in' : undefined,
  mobilePhone: isDev && debug ? '+33612345678' : undefined,
};

export type DroneErrors = {
  bounds?: string;
  dates?: string;
  autorisationFile?: string;
  missionLetterFile?: string;
  extraInfosFile?: string;
  cgv?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
};
