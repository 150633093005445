import React from 'react';
import { AppInputTextFormik } from 'primitives/input/appInputText';
import AppInputPassword from 'primitives/input/appInputPassword';
import { isDev } from 'appEnv';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { useAppFormik } from '../../../primitives/input/formikUtils';
import { CountryOptionsForOrder } from '../../../appConstants';
import { AppInputSelectFormik } from 'primitives/input/appInputSelect';
import * as yup from 'yup';
import { AppButton } from '../../../primitives/appButton';
import { ShText } from '@shoootin/translations';
import { FormikProvider } from 'formik';
import { FormError } from '../../../primitives/input/formError';
import { Omit } from 'utility-types';
import { ShCountriesForOrder, ShCountry } from '@shoootin/config';
import { ShCheckboxField, ShCol, ShRow } from '@shoootin/components-web';
import { ShMediaQueriesDynamic } from '@shoootin/design-tokens';

export type RegisterFormValues = {
  pro: boolean;
  companyName?: string;
  country: ShCountry;
  companyId?: string;
  vat?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  confirmPassword: string;
};

const RegisterFormValidation = yup.object().shape<RegisterFormValues>({
  pro: yup.boolean(),
  companyName: yup.string().trim(),
  companyId: yup.string().trim(),
  vat: yup.string().trim(),
  country: yup.mixed().oneOf(ShCountriesForOrder as any),
  firstName: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_firstName_RequiredError').id),
  lastName: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_lastName_RequiredError').id),
  email: yup
    .string()
    .trim()
    .email(getFrontMessage('form_userInfos_email_InvalidError').id)
    .required(getFrontMessage('form_userInfos_email_RequiredError').id),
  mobilePhone: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_mobilePhone_RequiredError').id),
  password: yup.string().trim(),
  confirmPassword: yup.string().trim(),
});

const debug = false;

const EmptyRegisterFormStatics: Omit<RegisterFormValues, 'country'> = {
  pro: isDev && debug,
  companyName: isDev && debug ? 'stripetest' : undefined,
  companyId: isDev && debug ? '82082645100024' : undefined,
  vat: '',
  email:
    isDev && debug
      ? `stripe${Math.floor(Math.random() * 10000)}@shooot.in`
      : '',
  password: isDev && debug ? '00000000' : '',
  firstName: isDev && debug ? 'Stripe' : '',
  lastName: isDev && debug ? 'Test' : '',
  mobilePhone: isDev && debug ? '000' : '',
  confirmPassword: isDev && debug ? '00000000' : '',
};

export const useEmptyRegisterForm = (
  shootingCountry: ShCountry,
): RegisterFormValues => {
  return {
    ...EmptyRegisterFormStatics,
    country: shootingCountry,
  };
};

const RegisterForm = ({
  initialValues,
  submitAppForm,
  extractFormErrors,
}: {
  initialValues: RegisterFormValues;
  submitAppForm: (values: RegisterFormValues) => any;
  extractFormErrors?: (responseData: any) => any;
}) => {
  const { form, globalError } = useAppFormik<RegisterFormValues>({
    validationSchema: RegisterFormValidation,
    initialValues,
    submitAppForm,
    extractFormErrors,
  });
  return (
    <FormikProvider value={form}>
      <div>
        <div className="form-section half">
          {globalError && <FormError error={globalError} />}

          <ShCheckboxField
            value={form.values.pro}
            onChange={value => form.setFieldValue('pro', value)}
            label={<ShText message={'form_registration_pro_Label'} />}
            bold={true}
            size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
          />
          {form.values.pro && (
            <>
              <ShRow className="field-row space">
                <ShCol xs={60} md={30}>
                  <AppInputSelectFormik
                    form={form}
                    placeholderMessage={getFrontMessage(
                      'form_address_country_PlaceHolder',
                    )}
                    name="country"
                    options={CountryOptionsForOrder}
                    onOptionSelected={option => {
                      form.setFieldValue('country', option.key);
                    }}
                  />
                </ShCol>
                <ShCol xs={60} md={30}>
                  <AppInputTextFormik
                    form={form}
                    placeholderMessage={getFrontMessage(
                      'form_registration_companyName_PlaceHolder',
                    )}
                    modifier="large"
                    name="companyName"
                  />
                </ShCol>
              </ShRow>
              <ShRow className="field-row  wrap-s">
                <ShCol xs={60} md={30}>
                  <AppInputTextFormik
                    form={form}
                    name="companyId"
                    placeholderMessage={getFrontMessage(
                      'form_registration_companyId_PlaceHolder',
                    )}
                    modifier="large"
                  />
                </ShCol>
                {!!form.values.country && form.values.country !== 'FR' && (
                  <ShCol xs={60} md={30}>
                    <AppInputTextFormik
                      form={form}
                      name="vat"
                      placeholderMessage={getFrontMessage(
                        'form_registration_vat_PlaceHolder',
                      )}
                      modifier="large"
                    />
                  </ShCol>
                )}
              </ShRow>
            </>
          )}
          <ShRow className="field-row gap wrap-s">
            <ShCol xs={60} md={30}>
              <AppInputTextFormik
                form={form}
                name="firstName"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_firstName_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
            <ShCol xs={60} md={30}>
              <AppInputTextFormik
                form={form}
                name="lastName"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_lastName_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
          </ShRow>

          <ShRow className="field-row wrap-s">
            <ShCol xs={60} md={30}>
              <AppInputTextFormik
                form={form}
                name="email"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_email_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
            <ShCol xs={60} md={30}>
              <AppInputTextFormik
                form={form}
                name="mobilePhone"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_mobilePhone_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
          </ShRow>

          <ShRow className="field-row gap wrap-s">
            <ShCol xs={60} md={30}>
              <AppInputPassword
                form={form}
                name="password"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_password_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
            <ShCol xs={60} md={30}>
              <AppInputPassword
                form={form}
                name="confirmPassword"
                placeholderMessage={getFrontMessage(
                  'form_userInfos_confirmPassword_PlaceHolder',
                )}
                modifier="large"
              />
            </ShCol>
          </ShRow>

          <ShRow className="field-row gap">
            <AppButton modifier="large" onClick={() => form.submitForm()}>
              <ShText message={getFrontMessage('common_actions_signup')} />
            </AppButton>
          </ShRow>
        </div>
      </div>
    </FormikProvider>
  );
};

export default RegisterForm;
