import React, { useMemo, useState } from 'react';
import { H2 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { AppSection } from '../../../../primitives/appSection';
import {
  AppInputTextAutocompleteDropdown,
  AppInputTextAutocompleteSelect,
} from '../../../../primitives/input/appInputTextAutocomplete';
import { OrderAdminClientUserDTO } from '../../../../appAPITypes';
import { OrderAPI } from '../../../../appAPI';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import {
  AppInputSelectOptionListWrapper,
  AppInputSelectOptionWrapper,
} from '../../../../primitives/input/appInputSelect';
import { getCountryFlag } from '../../../../appConstants';

const fetchAdminClientUsers = async (
  text: string,
): Promise<OrderAdminClientUserDTO[]> => {
  if (text.length === 0) {
    return [];
  } else {
    return (await OrderAPI.searchAdminClientUsers(text)).clientUsers;
  }
};

const useAdminClientUserAutocomplete = (text: string) => {
  const fetchAdminClientsDebounced = useConstant(() =>
    AwesomeDebouncePromise(fetchAdminClientUsers, 300),
  );
  const clientsAsync = useAsync(fetchAdminClientsDebounced, [text], {
    setLoading: asyncState => ({ ...asyncState, loading: true }),
  });
  return clientsAsync.result || [];
};

const ClientUserDisplay = ({
  clientUser,
}: {
  clientUser: OrderAdminClientUserDTO;
}) => {
  return (
    <div>
      <img
        height={20}
        src={getCountryFlag(clientUser.countryCode)}
        css={{ marginRight: 10, verticalAlign: 'text-bottom' }}
      />
      {clientUser.companyName}
      <br />
      {clientUser.userInfos} {clientUser.email}
      <br />
      {clientUser.comment && <small>{clientUser.comment}</small>}
    </div>
  );
};

const ClientUserOption = ({
  clientUser,
  selected,
  onSelect,
}: {
  clientUser: OrderAdminClientUserDTO;
  selected: boolean;
  onSelect: () => void;
}) => {
  return (
    <AppInputSelectOptionWrapper
      onSelect={onSelect}
      selected={selected}
      css={{ padding: 10, cursor: 'pointer' }}
    >
      <ClientUserDisplay clientUser={clientUser} />
    </AppInputSelectOptionWrapper>
  );
};

const ClientUserOptionList = ({
  clientUsers,
  selectedClientUser,
  onClientUserSelected,
}: {
  clientUsers: OrderAdminClientUserDTO[];
  selectedClientUser: OrderAdminClientUserDTO | undefined;
  onClientUserSelected: (clientUser: OrderAdminClientUserDTO) => void;
}) => {
  return (
    <AppInputSelectOptionListWrapper>
      {clientUsers.map(clientUser => (
        <ClientUserOption
          key={clientUser.userId}
          clientUser={clientUser}
          selected={
            !!selectedClientUser &&
            selectedClientUser.userId === clientUser.userId
          }
          onSelect={() => onClientUserSelected(clientUser)}
        />
      ))}
    </AppInputSelectOptionListWrapper>
  );
};

const AdminClientAutocompleteInput = ({
  selectedClientUser,
  onClientUserSelected,
}: {
  selectedClientUser: OrderAdminClientUserDTO | undefined;
  onClientUserSelected: (clientUser: OrderAdminClientUserDTO) => void;
}) => {
  const [inputText, setInputText] = useState('');
  const clientUsers = useAdminClientUserAutocomplete(inputText);
  return (
    <AppInputTextAutocompleteDropdown
      placeholder={'Select a client'}
      modifier="large"
      value={inputText}
      onChange={e => setInputText(e.target.value)}
      dropdownContent={
        clientUsers.length > 0 ? (
          <ClientUserOptionList
            clientUsers={clientUsers}
            selectedClientUser={selectedClientUser}
            onClientUserSelected={onClientUserSelected}
          />
        ) : (
          undefined
        )
      }
    />
  );
};

const StepOrderAdminClientSelection = () => {
  const {
    state: { admin },
    api: { setAdminClientUser },
  } = useOrderPageContext();

  return (
    <div
      css={{
        marginBottom: '1em',
      }}
    >
      {admin && !admin.clientUser && (
        <AdminClientAutocompleteInput
          selectedClientUser={admin!.clientUser}
          onClientUserSelected={setAdminClientUser}
        />
      )}

      {admin && admin.clientUser && (
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <ClientUserDisplay clientUser={admin.clientUser} />
          <small
            onClick={() => {
              setAdminClientUser(undefined);
            }}
          >
            Change client
          </small>
        </div>
      )}
    </div>
  );
};
export default StepOrderAdminClientSelection;
