import React from 'react';
import ChevronPrevSvg from 'components/svg/picto/chevronPrevSvg';
import './orderBack.less';
import { useOrderPageContext } from '../context/orderPageContext';
import { ShText } from '@shoootin/translations';

const OrderBack = () => {
  const { steps } = useOrderPageContext();
  return (
    <div className="order-back">
      <a
        onClick={(e) => {
          e.preventDefault();
          steps.previous();
        }}
      >
        <ChevronPrevSvg /> <ShText message={'common_actions_previous'} />
      </a>
    </div>
  );
};

export default OrderBack;
