import React, { useEffect, useState } from 'react';
import { OrderSummaryDTO } from '../../../appAPITypes';
import { AppButton } from '../../../primitives/appButton';
import { getFrontMessage } from '../../../appMessageDefinitions';
import { useIsOrderAdminMode } from '../utils/orderPageAdminUtils';
import { useOrderPageContext } from '../context/orderPageContext';
import { CartDiscount, CartExtraCharge } from './cartProgress';
import { ShText, useShTranslate } from '@shoootin/translations';
import { OrderStepPayment } from '../orderPageTranslations';
import { useAsyncCallback } from 'react-async-hook';
import { ShApiUtils } from '@shoootin/api';
import { FormError } from '../../../primitives/input/formError';
import { getStickyStyle } from '../utils/orderPageUtils';
import { Sticky } from 'react-sticky';
import { ShCurrency, ShPaymentMethod } from '@shoootin/config';
import {
  ShButtonAsync,
  ShInputCheckbox,
  ShInputNumber,
  ShInputText,
  ShPaymentMethodTilesConfig,
  ShPrice,
} from '@shoootin/components-web';
import { ShMediaQueriesDynamic } from '@shoootin/design-tokens';

const CartSummary = ({
  summary,
  currency,
  onValidate,
  selectedPaymentMethod,
  setCreditReduction,
  reduceTotalWithCredit,
}: {
  summary: OrderSummaryDTO;
  currency: ShCurrency;
  onValidate?: () => void;
  selectedPaymentMethod?: ShPaymentMethod;
  setCreditReduction: (reduction: boolean) => void;
  reduceTotalWithCredit: boolean;
}) => {
  const isAdmin = useIsOrderAdminMode();
  return (
    <section className="cart" id="cart">
      <header className="cart__header">
        <p className="cart__total title">
          <ShText message={getFrontMessage('common_order_totalPriceWithTax')} />
          <span className="cart__price">
            <ShPrice price={summary.priceWithTax} currency={currency} />
          </span>
        </p>
      </header>
      <div className="cart__panel">
        <CartSummaryPrices summary={summary} currency={currency} />
        {summary.canReduceTotalWithCredit && (
          <CartSummaryCredit
            amount={summary.reduceWithCreditAmount}
            currency={currency}
            setCreditReduction={setCreditReduction}
            reduceTotalWithCredit={reduceTotalWithCredit}
          />
        )}
        {!isAdmin && <CartSummaryVoucher />}
        {isAdmin && <PaymentSummaryExtraPrice />}
        {isAdmin && <PaymentSummaryExtraPayment />}
        <AppButton
          className="cart-button"
          modifier="large"
          onClick={onValidate}
          disabled={!onValidate}
        >
          <ShText message={getFrontMessage('common_actions_validate')} />{' '}
          {selectedPaymentMethod && (
            <small>
              ({ShPaymentMethodTilesConfig[selectedPaymentMethod].label})
            </small>
          )}
        </AppButton>
      </div>
    </section>
  );
};

export const CartSummaryPrices = ({
  summary,
  currency,
}: {
  summary: OrderSummaryDTO;
  currency: ShCurrency;
}) => {
  return (
    <article className="cart-item">
      <p className="cart-item__line">
        <ShText
          message={getFrontMessage('common_order_totalPriceWithoutTax')}
        />
        <span>
          <ShPrice price={summary.priceWithoutTax} currency={currency} />
        </span>
      </p>
      {summary.combinedDiscount !== 0 && (
        <CartDiscount discount={summary.combinedDiscount} />
      )}

      {summary.extraCharge !== 0 && (
        <CartExtraCharge extraCharge={summary.extraCharge} />
      )}
      {summary.discountCode && (
        <DiscountCodeApplied summary={summary} currency={currency} />
      )}
      <p className="cart-item__line">
        <ShText message={getFrontMessage('common_order_tax')} /> (
        {summary.vatRate * 100.0}
        %)
        <span>
          <ShPrice price={summary.tax} currency={currency} />
        </span>
      </p>
      <p className="cart-item__line">
        <ShText message={getFrontMessage('common_order_totalPriceWithTax')} />
        <span>
          <ShPrice price={summary.priceWithTax} currency={currency} />
        </span>
      </p>
    </article>
  );
};

export const CartSummaryCredit = ({
  amount,
  currency,
  reduceTotalWithCredit,
  setCreditReduction,
}: {
  amount: number;
  currency: ShCurrency;
  reduceTotalWithCredit: boolean;
  setCreditReduction: (reduction: boolean) => void;
}) => {
  return (
    <article className="cart-item cart-item-credit-reduce">
      <div className="cart-item__line">
        <p>
          <ShText
            message={OrderStepPayment.useCreditToReduce}
            values={{ credit: <ShPrice price={amount} currency={currency} /> }}
          />
        </p>
        <span>
          <ShInputCheckbox
            value={reduceTotalWithCredit}
            onChange={(value) => setCreditReduction(value)}
            size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
          />
        </span>
      </div>
    </article>
  );
};

const DiscountCodeApplied = ({
  summary,
  currency,
}: {
  summary: OrderSummaryDTO;
  currency: ShCurrency;
}) => {
  return (
    <p className="cart-item__line">
      <ShText message={getFrontMessage('form_offer_coupon_Label')} />{' '}
      {summary.discountCode}
      <span>
        <ShPrice price={summary.discountAmount} currency={currency} />
      </span>
    </p>
  );
};

const extractCouponError = (error?: Error): string | undefined => {
  return (error && ShApiUtils.getApiFormErrors(error).coupon) ?? undefined;
};

const useDiscountCodeControls = () => {
  const {
    api: { validateDiscount },
  } = useOrderPageContext();
  const [input, setInput] = useState('');
  const validateDiscountAsync = useAsyncCallback(() => validateDiscount(input));
  const errorMessage = extractCouponError(validateDiscountAsync.error);

  // Reset input on submit error
  useEffect(() => {
    if (errorMessage) {
      console.debug('coupon error', errorMessage);
      setInput('');
    }
  }, [errorMessage]);

  // Remove the error message on input text change
  useEffect(() => {
    if (input.length > 0 && errorMessage) {
      validateDiscountAsync.reset();
    }
  }, [input]);

  return {
    input,
    setInput,
    errorMessage,
    validate: () => validateDiscountAsync.execute(),
  };
};

export const CartSummaryVoucher = () => {
  const discountCodeControls = useDiscountCodeControls();
  const t = useShTranslate();
  return (
    <article className="cart-item">
      <p className="cart-item__detail">
        <ShText message={getFrontMessage('form_offer_coupon_Label')} />{' '}
      </p>

      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div
          css={{
            flex: 1,
            margin: 0,
            width: 'auto',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <ShInputText
            placeholder={t(getFrontMessage('form_offer_coupon_PlaceHolder'))} // "Code"
            themeSize={'l'}
            value={discountCodeControls.input}
            onChange={(e) => discountCodeControls.setInput(e.target.value)}
          />
        </div>
        <AppButton
          onClick={() => discountCodeControls.validate()}
          disabled={discountCodeControls.input.trim().length === 0}
          css={{
            margin: 0,
            width: 'auto',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <small>
            <ShText message={getFrontMessage('common_actions_validate')} />
          </small>
        </AppButton>
      </div>
      {discountCodeControls.errorMessage && (
        <FormError error={discountCodeControls.errorMessage} />
      )}
    </article>
  );
};

export const CartSummaryVoucherMobile = () => {
  const { state } = useOrderPageContext();
  const summary = state.orderSummary!;
  const currency = state.currency;
  const discountCodeControls = useDiscountCodeControls();
  const t = useShTranslate();
  return (
    <div className="cart__voucher">
      <h3 className="title">
        <ShText message={getFrontMessage('form_offer_coupon_Label')} />{' '}
      </h3>
      {summary.discountCode ? (
        <DiscountCodeApplied summary={summary} currency={currency} />
      ) : (
        <div className="cart__voucher__wrapper">
          <ShInputText
            placeholder={t(getFrontMessage('form_offer_coupon_PlaceHolder'))} // "Code"
            themeSize={'l'}
            value={discountCodeControls.input}
            onChange={(e) => discountCodeControls.setInput(e.target.value)}
          />
          <AppButton
            modifier="large"
            onClick={() => discountCodeControls.validate()}
            disabled={discountCodeControls.input.trim().length === 0}
          >
            <ShText message={getFrontMessage('common_actions_validate')} />
          </AppButton>
          {discountCodeControls.errorMessage && (
            <FormError error={discountCodeControls.errorMessage} />
          )}
        </div>
      )}
    </div>
  );
};

const PaymentSummaryExtraPrice = () => {
  const {
    state: { extraCharge },
    api: { setExtraCharge },
  } = useOrderPageContext();

  // use local state as we will need to validate with Backend
  const [input, setInput] = useState(extraCharge / 100.0);

  return (
    <article className="cart-item">
      <b css={{ marginBottom: 10, display: 'block' }}>Client extra price </b>
      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div
          css={{
            flex: 1,
            margin: 0,
            width: 'auto',
          }}
        >
          <ShInputNumber
            value={input}
            themeSize={'m'}
            onChange={(e) => setInput(e)}
          />
        </div>

        <ShButtonAsync onClick={() => setExtraCharge(input * 100)}>
          Ok
        </ShButtonAsync>
      </div>

      {extraCharge / 100.0 !== input && (
        <small css={{ color: 'red' }}>
          Don't forget to validate extra charge
        </small>
      )}
    </article>
  );
};

const PaymentSummaryExtraPayment = () => {
  const {
    state: { admin },
    api: { setExtraPayment },
  } = useOrderPageContext();
  const extraPayment = admin!.extraPayment;

  // use local state as we will need to validate with Backend
  const [input, setInput] = useState(extraPayment / 100.0);

  return (
    <article className="cart-item">
      <b css={{ marginBottom: 10, display: 'block' }}>
        Photographer extra payment{' '}
      </b>
      <div css={{ display: 'flex', flexDirection: 'row' }}>
        <div
          css={{
            flex: 1,
            margin: 0,
            width: 'auto',
          }}
        >
          <ShInputNumber
            value={input}
            themeSize={'m'}
            onChange={(e) => setInput(e)}
          />
        </div>
        <ShButtonAsync onClick={() => setExtraPayment(input * 100)}>
          Ok
        </ShButtonAsync>
      </div>
      {extraPayment / 100.0 !== input && (
        <small css={{ color: 'red' }}>
          Don't forget to validate extra payment
        </small>
      )}
    </article>
  );
};

export const CartSummarySticky = ({
  sticky,
  canBeSticky,
  ...props
}: React.ComponentProps<typeof CartSummary> & {
  sticky: Parameters<typeof getStickyStyle>[0];
  canBeSticky: boolean;
}) => (
  <Sticky disableCompensation>
    {() => {
      const stickyStyle = getStickyStyle(sticky);
      return (
        <div style={canBeSticky ? stickyStyle : {}}>
          <CartSummary {...props} />
        </div>
      );
    }}
  </Sticky>
);

export default CartSummary;
