import React from 'react';
import { AppSection } from 'primitives/appSection';
import { H2, H3 } from 'primitives/appTitle';

import {
  CartSummaryPrices,
  CartSummarySticky,
  CartSummaryVoucherMobile,
} from 'page/order/components/cartSummary';
import ChevronPrevSvg from 'components/svg/picto/chevronPrevSvg';
import { useOrderPageContext } from '../../context/orderPageContext';
import { useOrderCurrency } from 'page/order/utils/orderPageUtils';
import {
  Elements,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
} from 'react-stripe-elements';
import { StickyContainer } from 'react-sticky';
// @ts-ignore
import { device } from 'helpers/helpers';
import { OrderSummaryWrapper } from './stepPaymentSummary';
import { StepPaymentMethods } from './stepPaymentMethods';
import { ShText, useShIntlLocale } from '@shoootin/translations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { OrderStepLabels, OrderStepPayment } from '../../orderPageTranslations';
import { AppButton } from '../../../../primitives/appButton';
import OrderBack from '../../components/orderBack';
import { ShCheckboxField, useStripeLoader } from '@shoootin/components-web';
import { ShMediaQueriesDynamic } from '@shoootin/design-tokens';

const CGVSection = () => (
  <div className="form-section border form-payment-legal">
    <ShCheckboxField
      value={true}
      onChange={(value) => console.log(value)}
      label={<ShText message={OrderStepPayment.cgv} />}
      bold={true}
      size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
    />
  </div>
);

const StepPaymentContent = ({
  stripe,
}: {
  stripe?: ReactStripeElements.StripeProps;
}) => {
  const {
    state,
    steps,
    api: { confirmShooting, setCreditReduction, setPaymentError },
    isCheckout,
  } = useOrderPageContext();
  const currency = useOrderCurrency();
  const { selectedPaymentMethod, selectedCreditCardId, saveCreditCard } = state;
  const orderSummary = state.orderSummary!;

  const onConfirmClick = async () => {
    const payWithStripe =
      selectedPaymentMethod === 'CREDIT_CARD' && !selectedCreditCardId;

    const payWithStripeSavedCreditCard =
      selectedPaymentMethod === 'CREDIT_CARD' && selectedCreditCardId;

    if (payWithStripe) {
      const createTokenResult = await stripe!.createPaymentMethod('card');
      if (createTokenResult.paymentMethod) {
        console.debug(
          'Will pay with stripe paymentMethod',
          createTokenResult.paymentMethod,
        );
        await confirmShooting({
          paymentMethodId: createTokenResult.paymentMethod.id,
          stripeElements: stripe,
          saveCreditCard,
          payWithNewCreditCard: true,
        });
      } else {
        setPaymentError(createTokenResult?.error?.message);
        console.error(
          'Unable to create stripe paymentMethod',
          createTokenResult,
        );
      }
    } else if (payWithStripeSavedCreditCard) {
      await confirmShooting({
        stripeElements: stripe,
        paymentMethodId: selectedCreditCardId,
        payWithNewCreditCard: false,
      });
    } else {
      await confirmShooting();
    }
  };

  const canConfirm = !!selectedPaymentMethod;

  return (
    <StickyContainer>
      <AppSection
        className="form-payment left"
        header={
          <H2>
            <ShText message={OrderStepLabels.payment} />
          </H2>
        }
      >
        <OrderBack />

        <p className="form-payment-intro">
          <ShText message={OrderStepPayment.intro} />
        </p>

        <div className="form-wrapper" id="form-order-payment">
          <div className="form-details">
            <div className="form-section">
              <H3>
                <ShText
                  message={getFrontMessage('common_shooting_yourShoot')}
                />
              </H3>
              <OrderSummaryWrapper />
              <div className="cart__recap">
                <CartSummaryPrices summary={orderSummary} currency={currency} />
              </div>
              <CartSummaryVoucherMobile />
            </div>
            <StepPaymentMethods summary={orderSummary} />
            <CGVSection />
          </div>
          <div className="form-cart form-cart-payment opened">
            <CartSummarySticky
              sticky={{
                wrapper: '#form-order-payment',
                wrapperFirstElement: '.form-payment-summary',
                header: '.form-breadcrumbs',
                cart: '#cart',
              }}
              canBeSticky={!device().isMobile && !isCheckout}
              summary={orderSummary}
              currency={currency}
              onValidate={canConfirm ? onConfirmClick : undefined}
              selectedPaymentMethod={selectedPaymentMethod}
              reduceTotalWithCredit={state.reduceTotalWithCredit}
              setCreditReduction={setCreditReduction}
            />
          </div>

          <div className="form-navigation opened">
            <a
              className="form-navigation__link"
              onClick={() => steps.previous()}
            >
              <ChevronPrevSvg />{' '}
              <ShText message={getFrontMessage('common_actions_previous')} />
            </a>
            <AppButton
              modifier="large"
              onClick={canConfirm ? onConfirmClick : undefined}
              disabled={!canConfirm}
            >
              <ShText message={getFrontMessage('common_actions_pay')} />
            </AppButton>
          </div>
        </div>
      </AppSection>
    </StickyContainer>
  );
};

// It's important to inject stripe here, because the injected stripe is not exactly the same as the "useStripeLoader" returned stripe
// The injected stripe seems to allow calling stripe.createToken() without any arg, as long as there is a credit card input being rendered
const StepPaymentStripeInjector = injectStripe(StepPaymentContent);

const StepPayment = () => {
  const { state } = useOrderPageContext();
  const locale = useShIntlLocale();
  const stripeKey = state.orderSummary!.stripeKey;
  const stripe = useStripeLoader(stripeKey);
  return (
    <StripeProvider stripe={stripe || null}>
      <Elements locale={locale}>
        <StepPaymentStripeInjector />
      </Elements>
    </StripeProvider>
  );
};

export default StepPayment;
