import React from 'react';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { ShShootingFieldDTO, ShShootingFieldDTOComponent } from '@shoootin/api';
import { ShShootingField } from '@shoootin/components-web';

export const ShootingFields = ({
  fields,
  typeOfComponent,
}: {
  fields: ShShootingFieldDTO[];
  typeOfComponent: ShShootingFieldDTOComponent;
}) => {
  const {
    state: {
      validationErrors,
      selectedOffers: { shootingFields },
      clientExtraFieldsMap,
    },
    api: { setShootingField, setClientExtraField },
  } = useOrderPageContext();

  return (
    <>
      {fields
        .filter((field) => field.typeOfComponent === typeOfComponent)
        .map((field) => {
          const clientField = field.typeOfComponent === 'client';
          const value = clientField
            ? (clientExtraFieldsMap[field.id] as string)
            : (shootingFields[field.id] as string);

          const onChange = clientField ? setClientExtraField : setShootingField;

          const validationError = clientField
            ? validationErrors &&
              validationErrors.clientExtraFields &&
              validationErrors.clientExtraFields![field.id]
            : validationErrors &&
              validationErrors.offers &&
              validationErrors.offers!.shootingFields &&
              validationErrors.offers!.shootingFields![field.id];

          return (
            <ShShootingField
              key={field.id}
              field={field}
              value={value}
              onChange={onChange}
              validationError={validationError}
            />
          );
        })}
    </>
  );
};
