import React from 'react';
import { H4 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';

import { ShInputSelect } from '@shoootin/components-web';
import { ShText } from '@shoootin/translations';
import { useCurrentClientUser } from 'state/currentUserState';
import { OrderUserDTO } from '../../context/orderPageContextState';
import { OrderStepOrder } from '../../orderPageTranslations';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';

const StepOrderUserSelect = () => {
  const {
    state: { users, userId, clientBillingEntities },
    api: { setUserId, setBillingEntity },
  } = useOrderPageContext();

  const defaultBillingEntityId =
    clientBillingEntities.find((be) => be.defaultEntity)?.id ??
    clientBillingEntities[0].id;

  const options = users.map((user: OrderUserDTO) => ({
    value: user.id,
    label: user.name + ' - ' + user.email + ' - ' + user.mobilePhone,
  }));

  return (
    <div>
      {users.length > 0 && (
        <div className="form-order__section">
          <H4>
            <ShText message={OrderStepOrder.userInfos} />
          </H4>

          <ShInputSelect<string | undefined>
            options={options}
            value={userId}
            onChange={(value) => {
              setUserId(value);
              const user = users.find((u) => u.id === value);
              if (user && user.billingEntityId) {
                setBillingEntity(user.billingEntityId);
              } else {
                setBillingEntity(defaultBillingEntityId);
              }
            }}
            size={'l'}
          />
        </div>
      )}
    </div>
  );
};

const StepOrderUser = ({}: {}) => {
  const currentUser = useCurrentClientUser();
  const isAdmin = useIsOrderAdminMode();
  if (isAdmin || !currentUser || !currentUser.orderShootingsForUsers)
    return null;
  else return <StepOrderUserSelect />;
};

export default StepOrderUser;
