import React, { useMemo } from 'react';

import { ShShootingType, ShPaymentMethod, ShCountry } from '@shoootin/config';
import {
  AuthData,
  ShootingOrderDTO,
  ConfirmShootingResult,
  OrderAdminClientUserDTO,
  ConfirmShootingResultError,
} from 'appAPITypes';
import { mapValues, cloneDeep, merge, isEqual } from 'lodash';
import {
  EmptyFloorPlanConfig,
  EmptyPhotoConfig,
  EmptyScanConfig,
  EmptySelectedOffers,
  EmptyVideoConfig,
  FloorPlanConfig,
  OrderAddress,
  OrderAdminSettings,
  OrderPageState,
  OrderValidationErrors,
  ScanConfig,
} from './orderPageContextState';
import { OrderAPI } from 'appAPI';
import { OrderPageStepsAPI } from './orderPageContextSteps';
import { Moment } from 'moment';
import { RegisterFormValues } from '../../login/components/registerForm';
import { LoginAction, useLoginAction } from '../../../appAuth';
import {
  ShApiUtils,
  ShBillingEntityDTO,
  ShInfosDTO,
  ShShootingFieldDTO,
  ShShootingSlotAdminDetailsPhotographerDTO,
  ShShootingSlotDTO,
  ShOfferCustomDTO,
  ShOfferFloorPlanDTO,
  ShOfferOptionDTO,
  ShOfferPhotoDTO,
  ShOfferScanDTO,
  ShOfferVideoDTO,
} from '@shoootin/api';
import { useFormErrorSubmitCount } from '../../../primitives/input/formError';
import {
  createConfirmOrderDTO,
  createValidateAddressDTO,
  createValidateOffersDroneDTO,
  createValidateOffersDTO,
  createValidateOrderDTO,
} from '../utils/orderPagePayloadUtils';
import { onlyResolvesLast } from 'awesome-only-resolves-last-promise';
import { AwesomeState } from '../../../hooks/useAwesomeState';
import {
  ConfirmShootingResultNeedAction,
  ConfirmShootingResultNotAvailableAnymore,
  ConfirmShootingResultSuccess,
} from '../../../appAPITypes';
import idx from 'idx';
import { useSetCurrentUser } from '../../../state/currentUserState';
import { MaybeCurrentUser } from '../../../appTypes';
import { CurrentUserStorage } from '../../../appStorage';

import { ReactStripeElements } from 'react-stripe-elements';
import {
  defaultDroneState,
  DroneState,
} from './orderPageContextSpecificDroneState';

// With hooks, there is no "setState callback" so we need to perform state mutations at once in stead of chaining them
// These "mutation fragments" have been extracted because they are shared between other more complex mutations
// See also https://github.com/reactjs/rfcs/issues/98
const mutations = {
  deleteOffers: (draft: OrderPageState) => {
    // cloning is important because further mutations could be performed and we might
    draft.selectedOffers = cloneDeep(EmptySelectedOffers);
  },
  // when switching from a custom to a basic offer, we must erase all previously set custom configs
  deleteOffersIfCustom: (draft: OrderPageState) => {
    if (draft.selectedOffers.customOfferId) {
      mutations.deleteOffers(draft);
    }
  },
  // we need to ensure the selected payment method data is consistent with the rest of the state
  syncPaymentMethodData: (draft: OrderPageState) => {
    if (draft.orderSummary) {
      const methods = draft.orderSummary.paymentMethods;
      // If only one method
      if (methods.length === 1) {
        draft.selectedPaymentMethod = methods[0]; // always at least one method
      }
      // Erase method if it is not available anymore
      if (
        draft.selectedPaymentMethod &&
        !methods.includes(draft.selectedPaymentMethod)
      ) {
        draft.selectedPaymentMethod = undefined;
      }
      // Erase selected card if method is not card anymore
      if (draft.selectedPaymentMethod !== 'CREDIT_CARD') {
        draft.selectedCreditCardId = undefined;
      }
    }
    // Erase everything if no orderSummary available (if user goes backward and select a new address etc...)
    else {
      draft.selectedPaymentMethod = undefined;
      draft.selectedCreditCardId = undefined;
    }
  },
};

type LoadAvailabilitiesOptions = {
  day?: Moment;
  timeZone?: string;
};

const buildOrderPageApi = (
  awesomeState: AwesomeState<OrderPageState>,
  steps: OrderPageStepsAPI,
  loginAction: LoginAction,
  incrementSubmitCount: () => void,
  setCurrentUser: (user: MaybeCurrentUser) => void,
) => {
  const {
    getState,
    setStateAsync,
    produceStateAsync,
    produceState,
  } = awesomeState;

  // intercept any async method that can return validation errors
  // and store those errors in state
  const handleValidationErrors = <R, Args extends any[]>(
    asyncFunction: (...args: Args) => Promise<R>,
  ): ((...args: Args) => Promise<R>) => {
    return async (...args: Args) => {
      try {
        const result = await asyncFunction(...args);
        // A successful call erase former errors
        await produceStateAsync((draft) => {
          draft.validationErrors = undefined;
        });
        return result;
      } catch (e) {
        if (ShApiUtils.isApiResponseDataError(e)) {
          const validationErrors = e.response.data as OrderValidationErrors;
          console.warn('OrderValidationErrors', validationErrors);
          await produceStateAsync((draft) => {
            draft.validationErrors = validationErrors;
          });
          if (validationErrors.offerForbiddenForUser) {
            await handleOfferForbiddenForUserError();
          }
        }
        throw e;
      } finally {
        incrementSubmitCount(); // Permit to reveal again form errors if needed
      }
    };
  };

  // Sometimes user picks an offer at step1, then log-in at step3, and we detect he selected
  // an offer that's not allowed for his credentials, in such case we redirect him to step1
  // Hopefully it doesn't happen often and it's better UX than presenting login/signup form at step1...
  const handleOfferForbiddenForUserError = async () => {
    steps.goTo('order');
    // We erase everything except address/country/shootingType in such case
    await setStateAsync((state) => ({
      ...awesomeState.initialState,
      admin: state.admin,
      country: state.country,
      address: state.address,
      shootingType: state.shootingType,
      validationErrors: state.validationErrors, // Permit to show the error banner
    }));
    // validate address permits to reload the offers to be sure we display offers that belong to authenticated user
    await validateAddress();
    // unfortunately validateAddress() erase validationErrors.offerForbiddenForUser so we restore it
    await produceStateAsync((draft) => {
      draft.validationErrors = {
        ...draft.validationErrors,
        offerForbiddenForUser: true,
      };
    });
  };

  // handleValidationErrors is a generic way to store form errors into state
  // onlyResolvesLast permit to handle potential concurrency issues
  // (onlyResolvesLast must be applied first because otherwise stale form errors might get displayed)
  const SafeOrderValidators = {
    validateAddress: handleValidationErrors(
      onlyResolvesLast(OrderAPI.validateAddress),
    ),
    validateOffers: handleValidationErrors(
      onlyResolvesLast(OrderAPI.validateOffers),
    ),
    validateOrder: handleValidationErrors(
      onlyResolvesLast(OrderAPI.validateOrder),
    ),
    validateDrone: handleValidationErrors(
      onlyResolvesLast(OrderAPI.validateDrone),
    ),
  };

  const getShootingId = () =>
    getState().restoration ? getState().restoration!.shootingId : undefined;

  const setCountry = (country: ShCountry) =>
    awesomeState.setState((state) => ({
      ...awesomeState.initialState,
      admin: state.admin,
      address: undefined, // Force null address (mostly for DEV because we may "prefill" it)
      country,
    }));

  const setAdminClientUser = (client: OrderAdminClientUserDTO | undefined) => {
    awesomeState.setState((state) => ({
      ...awesomeState.initialState,
      country: client ? client.countryCode : state.country,
      admin: {
        clientUser: client,
        extraPayment: idx(state, (_) => _.admin.extraPayment) || 0,
      },
    }));
  };

  const setAdminSettings = (settings: OrderAdminSettings) => {
    produceState((draft) => {
      draft.admin!.settings = settings;
    });
  };

  const selectAddress = async (address: OrderAddress) => {
    // If the user selects again the address he already selected
    // we don't want/need to revalidate that address!
    if (isEqual(address, getState().address)) {
      console.log("address didn't change");
      return;
    }
    await setStateAsync((state) => ({
      ...awesomeState.initialState,
      admin: state.admin,
      country: state.country,
      address,
    }));
    await validateAddress();
  };
  const deleteAddress = async () => {
    await setStateAsync((state) => ({
      ...awesomeState.initialState,
      admin: state.admin,
      country: state.country,
      address: undefined,
    }));
  };

  const getDefaultBillingEntityId = (
    clientBillingEntities: ShBillingEntityDTO[],
  ) => {
    const state = getState();
    if (state) {
      if (state.billingEntity) return state.billingEntity;

      const defaultBillingEntity: ShBillingEntityDTO | undefined =
        clientBillingEntities.length > 0
          ? clientBillingEntities.find((each) => each.defaultEntity)
          : undefined;

      return defaultBillingEntity ? defaultBillingEntity.id : undefined;
    }
    return undefined;
  };

  const validateAddress = async () => {
    const state = getState();
    if (state) {
      const payload = createValidateAddressDTO(getState());
      const addressValidationResult = await SafeOrderValidators.validateAddress(
        payload,
      );
      console.debug(addressValidationResult.clientExtraFields);

      await produceStateAsync((state) => {
        state.offers = addressValidationResult.offers;
        state.options = addressValidationResult.options;
        state.offerDiscount = addressValidationResult.offerDiscount;
        state.currency = addressValidationResult.currency;
        state.timeZone = addressValidationResult.timeZone;
        state.clientExtraFields = addressValidationResult.clientExtraFields;
        state.clientBillingEntities =
          addressValidationResult.clientBillingEntities;
        state.billingEntity = getDefaultBillingEntityId(
          addressValidationResult.clientBillingEntities,
        );
        state.canAddBillingEntity = addressValidationResult.canAddBillingEntity;
        state.users = addressValidationResult.users;
      });
    }
  };

  const setUserId = (userId?: string) =>
    produceState((draft) => {
      draft.userId = userId;
    });

  const setShootingType = (shootingType?: ShShootingType) =>
    produceState((state) => ({
      ...awesomeState.initialState,
      // Erase most of the user inputs, but keep remote offers data
      admin: state.admin,
      restoration: state.restoration,
      offers: state.offers,
      options: state.options,
      offerDiscount: state.offerDiscount,
      currency: state.currency,
      shootingType,
      address: state.address,
      country: state.country,
      timeZone: state.timeZone,
      clientExtraFields: state.clientExtraFields,
      clientExtraFieldsMap: state.clientExtraFieldsMap,
      clientBillingEntities: state.clientBillingEntities,
      billingEntity: state.billingEntity,
      canAddBillingEntity: state.canAddBillingEntity,
      users: state.users,
      userId: state.userId,
    }));

  const setInfos = (infos: ShInfosDTO) =>
    produceState((draft) => {
      draft.infos = infos;
    });

  const setBillingEntities = (billingEntities: ShBillingEntityDTO[]) =>
    produceState((draft) => {
      draft.clientBillingEntities = billingEntities;
    });

  const setBillingEntity = (billingEntityId?: string) =>
    produceState((draft) => {
      draft.billingEntity = billingEntityId;
    });

  const selectPhotoOffer = (offer: ShOfferPhotoDTO) =>
    produceState((draft) => {
      mutations.deleteOffersIfCustom(draft);
      draft.selectedOffers.photoOfferId = offer.id;
      draft.selectedOffers.photoConfig = {
        options: mapValues(offer.photo.defaultOptions, (o) =>
          o ? o.id : undefined,
        ),
      };
    });

  const togglePhotoOfferOption = (option: ShOfferOptionDTO) =>
    produceStateAsync((draft) => {
      const options = draft.selectedOffers.photoConfig.options;
      const currentId = options[option.category];
      const nextId = currentId === option.id ? undefined : option.id;
      options[option.category] = nextId;
    });

  const deletePhotoOffer = () =>
    produceStateAsync((draft) => {
      draft.selectedOffers.photoOfferId = undefined;
      draft.selectedOffers.photoConfig = EmptyPhotoConfig;
    });

  const selectVideoOffer = (offer: ShOfferVideoDTO) =>
    produceStateAsync((draft) => {
      mutations.deleteOffersIfCustom(draft);
      draft.selectedOffers.videoOfferId = offer.id;
    });

  const deleteVideoOffer = () =>
    produceStateAsync((draft) => {
      draft.selectedOffers.videoOfferId = undefined;
      draft.selectedOffers.videoConfig = EmptyVideoConfig;
    });

  const selectScanOffer = (offer: ShOfferScanDTO) =>
    produceStateAsync((draft) => {
      mutations.deleteOffersIfCustom(draft);
      draft.selectedOffers.scanOfferId = offer.id;
    });

  const mutateScanConfig = (updater: (scanConfig: ScanConfig) => void) =>
    produceState((draft) => {
      updater(draft.selectedOffers.scanConfig);
    });

  const deleteScanOffer = () =>
    produceState((draft) => {
      draft.selectedOffers.scanOfferId = undefined;
      draft.selectedOffers.scanConfig = EmptyScanConfig;
    });

  const selectFloorPlanOffer = (offer: ShOfferFloorPlanDTO) =>
    produceStateAsync((draft) => {
      mutations.deleteOffersIfCustom(draft);
      draft.selectedOffers.floorPlanOfferId = offer.id;
    });

  const mutateFloorPlanConfig = (
    updater: (floorPlanConfig: FloorPlanConfig) => void,
  ) =>
    produceState((draft) => {
      updater(draft.selectedOffers.floorPlanConfig);
    });

  const deleteFloorPlanOffer = () =>
    produceState((draft) => {
      draft.selectedOffers.floorPlanOfferId = undefined;
      draft.selectedOffers.floorPlanConfig = EmptyFloorPlanConfig;
    });

  const selectCustomOffer = (offer: ShOfferCustomDTO) =>
    produceState((draft) => {
      // for now, it's safer to delete all configs,
      // even if this implies potentially loosing user-provided data
      // because the configs that were fine for one offer may not be fine for another
      // (for example, the scan max surface, or the
      mutations.deleteOffers(draft);
      draft.selectedOffers.customOfferId = offer.id;
    });

  const deleteCustomOffer = () =>
    produceState((draft) => {
      draft.selectedOffers.customOfferId = undefined;
      draft.selectedOffers.shootingFields = {};
    });

  const setShootingField = (
    field: ShShootingFieldDTO,
    value: string | boolean,
  ) => {
    produceState((draft) => {
      draft.selectedOffers.shootingFields[field.id] = value;

      const setShootingFieldValidationError = (id: string, error: string) => {
        const singleFieldError: OrderValidationErrors = {
          offers: { shootingFields: { [id]: error } },
        };
        draft.validationErrors = merge(
          getState().validationErrors,
          singleFieldError,
        );
      };

      const isRegexValidationError =
        !!field.regex && !new RegExp(field.regex).test(value as string);
      if (isRegexValidationError) {
        setShootingFieldValidationError(field.id, field.regexError);
      } else {
        setShootingFieldValidationError(field.id, '');
      }
    });
  };

  const setClientExtraField = (
    field: ShShootingFieldDTO,
    value: string | boolean,
  ) => {
    produceState((draft) => {
      draft.clientExtraFieldsMap[field.id] = value;

      const setShootingFieldValidationError = (id: string, error: string) => {
        const singleFieldError: OrderValidationErrors = {
          clientExtraFields: { [id]: error },
        };
        draft.validationErrors = merge(
          getState().validationErrors,
          singleFieldError,
        );
      };

      const isRegexValidationError =
        !!field.regex && !new RegExp(field.regex).test(value as string);
      if (isRegexValidationError) {
        setShootingFieldValidationError(field.id, field.regexError);
      } else {
        setShootingFieldValidationError(field.id, '');
      }
    });
  };

  const setShootingId = (shootingId: string) =>
    produceState((draft) => {
      draft.restoration = { shootingId };
    });

  const validateOffers = async () => {
    const payload = createValidateOffersDTO(getState());
    const availabilities = await SafeOrderValidators.validateOffers(payload);
    await produceStateAsync((draft) => {
      draft.availabilities = availabilities;
    });
  };

  const refreshAvailabilities = async (
    options: LoadAvailabilitiesOptions = {},
  ) => {
    const day = options.day || getState().selectedDay;
    // const timeZone = options.timeZone || getState().timeZone;
    await produceStateAsync((draft) => {
      draft.selectedDay = day;
      // draft.timeZone = timeZone;
      draft.selectedSlot = undefined;
      draft.isSelectedSlotValid = false;
      draft.unavailableSlot = undefined;
    });
    await validateOffers();
  };

  const validateStepOrder = async () => {
    try {
      await validateOffers();
      steps.next();
    } catch (e) {
      console.error(e);
      // alert('error'); // TODO show user feedback
    }
  };

  const selectDay = async (day: Moment) => {
    try {
      await refreshAvailabilities({ day });
    } catch (e) {
      console.error(e);
      // alert('error'); // TODO show user feedback
    }
  };

  const getAdminSlotDetails = async (shootingSlot: ShShootingSlotDTO) => {
    const payload = createValidateOrderDTO(getState());
    const slotDetailsResult = await OrderAPI.getAdminSlotDetails({
      ...payload,
      shootingSlot,
    });
    return slotDetailsResult;
  };

  const validateOrderAPI = async () => {
    const payload = createValidateOrderDTO(getState());
    const validateOrderResult = await SafeOrderValidators.validateOrder(
      payload,
    );
    if (validateOrderResult.success) {
      await produceStateAsync((draft) => {
        draft.isSelectedSlotValid = true;
        draft.orderSummary = validateOrderResult.summary;
        draft.offerDiscount = validateOrderResult.offerDiscount;
        draft.unavailableSlot = undefined;
        mutations.syncPaymentMethodData(draft);
      });
      return true;
    } else {
      await produceStateAsync((draft) => {
        draft.isSelectedSlotValid = false;
        draft.availabilities = {
          availabilities: validateOrderResult.availabilities,
          emptyDays: validateOrderResult.emptyDays,
          canChange: true,
        };
        draft.unavailableSlot = payload.shootingSlot;
      });
      return false;
    }
  };

  const validateOrderAndGoToAppropriateStep = async () => {
    const success = await validateOrderAPI();
    if (success) {
      if (steps.currentStep === 'book') {
        steps.next(); // go to login (if needed), or payment
      } else {
        steps.goTo('payment');
      }
    } else {
      // tell the user that his shooting slot is not valid anymore before going back
      steps.goTo('book');
    }
  };

  const selectShootingSlot = async (
    day: Moment,
    slot: ShShootingSlotDTO,
    photographer?: ShShootingSlotAdminDetailsPhotographerDTO,
  ) => {
    await produceStateAsync((draft) => {
      draft.selectedSlot = slot;
      draft.isSelectedSlotValid = false;
      if (photographer) {
        draft.admin!.photographer = photographer;
      }
    });
    await validateOrderAPI();
  };

  const validateStepBook = async () => {
    await validateOrderAndGoToAppropriateStep();
  };

  const handleLoginSubmit = async (authData: AuthData) => {
    await loginAction(authData, { redirect: false, userType: 'CLIENT_USER' });
    // After a successful login, we revalidate to get updated order summary
    // (discounts and payment methods may change after authentication)
    await validateOrderAndGoToAppropriateStep();
  };

  const handleRegistrationSubmit = async (registration: RegisterFormValues) => {
    await produceStateAsync((draft) => {
      draft.registration = registration;
    });
    await validateOrderAndGoToAppropriateStep();
  };

  const changeBillingEntityInSummary = async (billingEntityId?: string) => {
    await produceStateAsync((draft) => {
      draft.billingEntity = billingEntityId;
    });

    try {
      await validateOrderAPI();
    } catch (e) {
      throw e;
    }
  };

  const validateDiscount = async (discountCode: string) => {
    await produceStateAsync((draft) => {
      draft.discountCode = discountCode;
    });
    try {
      await validateOrderAPI();
    } catch (e) {
      // Remove discount code from state if there is any error
      // because we don't want it to be sent in payload if user perform payment
      // TODO maybe we should only put discountCode in state if the discount has been validated?
      console.debug('removing discount code from state');
      await produceStateAsync((draft) => {
        draft.discountCode = undefined;
      });
      // Important to rethrow here, because the coupon errors are handled locally (see useDiscountCodeControls)
      throw e;
    }
  };

  const setCreditReduction = async (reduceTotalWithCredit: boolean) => {
    await produceStateAsync((draft) => {
      draft.reduceTotalWithCredit = reduceTotalWithCredit;
    });
    await validateOrderAndGoToAppropriateStep();
  };

  const setExtraCharge = async (extraCharge: number) => {
    await produceStateAsync((draft) => {
      draft.extraCharge = extraCharge;
    });
    await validateOrderAPI();
  };

  const setExtraPayment = async (extraPayment: number) => {
    await produceStateAsync((draft) => {
      draft.admin!.extraPayment = extraPayment;
    });
    await validateOrderAPI();
  };

  const selectPaymentMethod = (method: ShPaymentMethod) => {
    produceState((draft) => {
      draft.selectedPaymentMethod = method;
      if (method !== 'CREDIT_CARD') {
        draft.selectedCreditCardId = undefined;
      }
    });
  };

  const selectCreditCard = (creditCardId: string) => {
    produceState((draft) => {
      draft.selectedCreditCardId = creditCardId;
    });
  };
  const deleteCreditCard = () => {
    produceState((draft) => {
      if (draft.selectedCreditCardId) {
        draft.selectedCreditCardId = undefined;
      }
    });
  };

  const setSaveCreditCard = (saveCreditCard: boolean) => {
    produceState((draft) => {
      draft.saveCreditCard = saveCreditCard;
    });
  };

  const setPaymentError = (error?: string) => {
    produceState((draft) => {
      draft.validationErrors = {
        ...draft.validationErrors,
        paymentErrors: error,
      };
    });
  };

  type ConfirmShootingPayloadWithNewCreditCard = {
    paymentMethodId?: string;
    paymentIntentId?: string;
    stripeElements?: ReactStripeElements.StripeProps;
    saveCreditCard?: boolean;
    payWithNewCreditCard: true;
  };
  type ConfirmShootingPayloadWithSavedCreditCard = {
    paymentMethodId?: string;
    paymentIntentId?: string;
    stripeElements?: ReactStripeElements.StripeProps;
    payWithNewCreditCard: false;
  };

  type ConfirmShootingPayload =
    | ConfirmShootingPayloadWithNewCreditCard
    | ConfirmShootingPayloadWithSavedCreditCard;

  const confirmShooting = async (payload?: ConfirmShootingPayload) => {
    const method = getState().selectedPaymentMethod;
    // TODO selectedCreditCardId -> payload.paymentMethodId
    // const selectedCreditCardId = getState().selectedCreditCardId;
    const order: ShootingOrderDTO = createConfirmOrderDTO(getState());
    console.debug('confirm shooting', {
      shootingId: getShootingId(),
      method,
      order,
      payload,
    });

    const doConfirm = (): Promise<ConfirmShootingResult> => {
      if (method === 'CREDIT_CARD') {
        if (payload && (payload.paymentMethodId || payload.paymentIntentId)) {
          if (payload.payWithNewCreditCard) {
            return OrderAPI.confirmShootingNewCB(
              order,
              payload.paymentMethodId,
              payload.paymentIntentId,
              payload.saveCreditCard,
            );
          } else if (!payload.payWithNewCreditCard) {
            return OrderAPI.confirmShootingExistingCB(
              order,
              payload.paymentMethodId,
              payload.paymentIntentId,
            );
          }
        }
      } else if (method === 'UNLIMITED') {
        return OrderAPI.confirmShootingUnlimited(order);
      } else if (method === 'CREDIT') {
        return OrderAPI.confirmShootingCredit(order);
      } else if (method === 'FREE') {
        return OrderAPI.confirmShootingFree(order);
      } else if (method === 'ORDER') {
        return OrderAPI.confirmShootingOrder(order);
      } else if (method === 'INVOICE') {
        return OrderAPI.confirmShootingInvoice(order);
      }

      // if we don't match these cases
      throw new Error(
        `Unexpected: can't confirm shooting with payment method=${method}`,
      );
    };

    const isNotAvailableAnymore = (
      result: ConfirmShootingResult,
    ): result is ConfirmShootingResultNotAvailableAnymore => {
      return !result.success && !!result.step && result.step === 'book';
    };

    const result = await doConfirm();

    const authenticateNewUser = (
      result:
        | ConfirmShootingResultSuccess
        | ConfirmShootingResultError
        | ConfirmShootingResultNeedAction,
    ) => {
      if (result.createdUser) {
        console.debug(
          'Authenticating new user created during the order process',
        );
        setCurrentUser(result.createdUser);
        CurrentUserStorage.set(result.createdUser);
      }
    };

    const successCallback = async (result: ConfirmShootingResultSuccess) => {
      console.debug('shooting confirm success!', result);
      await produceStateAsync((draft) => {
        draft.orderSummary = result.summary;
      });
      console.debug(
        'shooting confirm summary :',
        result.summary,
        getState().orderSummary,
      );

      // Ensure created user becomes authenticated
      authenticateNewUser(result);

      steps.next();
    };

    const errorCallback = async (result: ConfirmShootingResultError) => {
      console.error('Shooting confirmation error', result);

      if (isNotAvailableAnymore(result)) {
        await produceStateAsync((draft) => {
          draft.isSelectedSlotValid = false;
          draft.availabilities = {
            availabilities: result.availabilities!,
            emptyDays: result.emptyDays!,
            canChange: true,
          };
          draft.unavailableSlot = order.shootingSlot;
        });

        steps.goTo('book');
      } else {
        authenticateNewUser(result);
        displayErrorMessage(result.message);
      }
    };

    const displayErrorMessage = (message?: string) => {
      // TODO handle error correctly in iframe maybe
      // for the moment display errors in Stripe form
      console.error(message);
      // alert('Shooting confirmation error :  ' + message);
      setPaymentError(message);
    };

    const needActionCallback = async (
      result: ConfirmShootingResultNeedAction,
    ) => {
      console.debug('need Action ', result);
      authenticateNewUser(result);
      // TODO use this if manual handling of 3D secure
      // const iframe = document.createElesment('iframe');
      // iframe.src = result.clientSecret;
      // iframe.width = "600";
      // iframe.height = "400";
      // document.body.appendChild(iframe);

      // TODO add loading state
      // Note that stripe.handleCardAction may take several seconds to complete.
      // During that time, you should disable your form from being resubmitted
      // and show a waiting indicator like a spinner. If you receive an error result,
      // you should be sure to show that error to the customer, re-enable the form,
      // and hide the waiting indicator.
      const {
        error: errorAction,
        paymentIntent,
        // TODO strange that stripe types doesn't contain handleCardAction...
        // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-action
        // @ts-ignore
      } = await payload!.stripeElements.handleCardAction(result.clientSecret);

      if (errorAction) {
        displayErrorMessage(errorAction.message);
      } else {
        await confirmShooting(
          {
            ...payload!,
            paymentMethodId: undefined,
            paymentIntentId: paymentIntent!.id,
          },
          //     {
          //   paymentMethodId: undefined,
          //   paymentIntentId: paymentIntent.id,
          //   stripeElements: payload!.stripeElements,
          //   saveCreditCard: payload!.saveCreditCard,
          //   payWithNewCreditCard: payload!.payWithNewCreditCard,
          // }
        );
      }
    };

    // we set the shootingId after confirmation if shooting is saved in db
    // to avoid duplicates but to keep draft of shooting
    if (result.shootingId) {
      setShootingId(result.shootingId);
    }
    if (result.success) {
      if (result.needAction) {
        await needActionCallback(result);
      } else {
        await successCallback(result);
      }
    } else {
      await errorCallback(result as ConfirmShootingResultError);
    }
  };

  // Drone state API

  const setDroneState = (partial: Partial<DroneState>) => {
    produceState((draft) => {
      const droneState = draft.droneState ?? defaultDroneState;
      draft.droneState = { ...droneState, ...partial };
    });
  };

  const validateDrone = async () => {
    const payload = createValidateOffersDroneDTO(getState());
    console.log('validateDrone', { payload });
    const result = await SafeOrderValidators.validateDrone(payload);
    await produceStateAsync((draft) => {
      draft.droneState!.success = true;
    });
  };

  const validateStepDrone = async () => {
    try {
      await validateDrone();
    } catch (e) {
      console.error(e);
      // alert('error'); // TODO show user feedback
    }
  };

  return {
    setAdminClientUser,
    setCountry,
    selectAddress,
    deleteAddress,
    validateAddress,
    setShootingField,
    setClientExtraField,
    setUserId,
    setShootingType,
    setInfos,
    setBillingEntity,
    setBillingEntities,
    changeBillingEntityInSummary,
    selectPhotoOffer,
    togglePhotoOfferOption,
    deletePhotoOffer,
    selectVideoOffer,
    deleteVideoOffer,
    selectScanOffer,
    mutateScanConfig,
    deleteScanOffer,

    selectFloorPlanOffer,
    mutateFloorPlanConfig,
    deleteFloorPlanOffer,

    selectCustomOffer,
    deleteCustomOffer,
    validateStepOrder,
    // setTimeZone,
    selectDay,
    selectShootingSlot,
    validateStepBook,
    handleRegistrationSubmit,
    handleLoginSubmit,
    selectPaymentMethod,
    selectCreditCard,
    deleteCreditCard,
    setSaveCreditCard,
    validateDiscount,
    confirmShooting,
    setCreditReduction,
    setPaymentError,
    // Admin
    getAdminSlotDetails,
    setAdminSettings,
    setExtraCharge,
    setExtraPayment,

    // DRONE
    setDroneState,
    validateStepDrone,
  };
};

// We rely on getState because it's convenient to use in callbacks,
// particularly if we want to avoid capturing stale state values in api closures
export const useOrderPageAPI = (
  awesomeState: AwesomeState<OrderPageState>,
  steps: OrderPageStepsAPI,
) => {
  const loginAction = useLoginAction();
  const setCurrentUser = useSetCurrentUser();
  const { increment } = useFormErrorSubmitCount();
  return useMemo(() => {
    console.debug('building order page api');
    return buildOrderPageApi(
      awesomeState,
      steps,
      loginAction,
      increment,
      setCurrentUser,
    );
  }, [awesomeState, steps, setCurrentUser]);
};

export type OrderPageAPI = ReturnType<typeof useOrderPageAPI>;
