import React from 'react';

const TrashSvg = (props: any) => (
  <svg
    className="trash"
    height={28}
    width={28}
    viewBox="0 0 28 28"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <g className="trash-cap">
      <rect x={7.5} y={6.5} width={14} height={2} />
      <polyline points="11.5,6.5 11.5,4.5 17.5,4.5 17.5,6.5 " />
    </g>
    <polygon points="20.5,8.5 8.5,8.5 9.5,23.5 19.5,23.5 " />
    <line x1={14.5} y1={10.5} x2={14.5} y2={21.5} />
    <line x1={12.5} y1={10.5} x2={12.5} y2={21.5} />
    <line x1={16.5} y1={10.5} x2={16.5} y2={21.5} />
  </svg>
);

export default TrashSvg;
