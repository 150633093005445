/** @jsx jsx */
import { jsx } from '@emotion/core';
import './ShDateInit';
import React, { ComponentProps, useState } from 'react';
import { SingleDatePicker } from 'react-dates';

import useHtmlForId from '../../utils/hooks/useHtmlForId';
import { ShMoment, ShMomentType } from '@shoootin/utils';
import { ShButton } from '../../designSystem/primitives/button/ShButton/ShButton';
import { ShText } from '@shoootin/translations';
import { injectGlobal } from 'emotion';
import {
  ShInputSize,
  ShInputSizeConfigs,
  useShInputSize,
} from '../../designSystem/primitives/input/ShInputTheme';
import { Moment } from 'moment';

type SingleDatePickerProps = ComponentProps<typeof SingleDatePicker>;

export type ShSingleDatePickerProps = Pick<
  SingleDatePickerProps,
  'date' | 'onDateChange' | 'numberOfMonths' | 'placeholder' | 'isOutsideRange'
> & { themeSize?: ShInputSize; presets?: ShSingleDatePickerPresetProps[] };

export type ShSingleDatePickerPresetProps = {
  translation: string;
  date: ShMomentType;
};

export const ShSingleDateFuturePresets: ShSingleDatePickerPresetProps[] = [
  {
    translation: 'form_general_filterDateIn3Weeks',
    date: ShMoment().add(3, 'week'),
  },
  {
    translation: 'form_general_filterDateIn3Months',
    date: ShMoment().add(3, 'months'),
  },
  {
    translation: 'form_general_filterDateIn6Months',
    date: ShMoment().add(6, 'months'),
  },
];

export const ShSingleDatePastPresets: ShSingleDatePickerPresetProps[] = [
  {
    translation: 'form_general_filterDate3WeeksAgo',
    date: ShMoment().subtract(3, 'week'),
  },
  {
    translation: 'form_general_filterDate3MonthsAgo',
    date: ShMoment().subtract(3, 'months'),
  },
  {
    translation: 'form_general_filterDate6MonthsAgo',
    date: ShMoment().subtract(6, 'months'),
  },
];

const SingleDatePresets = ({
  presets,
  onDateChange,
  setFocused,
}: {
  presets: ShSingleDatePickerPresetProps[];
  onDateChange: (value: Moment | null) => void;
  setFocused: (value: { focused: boolean | null }) => void;
}) => {
  if (presets.length == 0) return null;
  return (
    <div
      css={{
        padding: '0px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      {presets.map((preset: ShSingleDatePickerPresetProps, i: number) => {
        return (
          <ShButton
            key={i}
            size={'s'}
            variant={'light'}
            onClick={() => {
              onDateChange(preset.date);
              setFocused({ focused: null });
            }}
          >
            <ShText message={preset.translation} />
          </ShButton>
        );
      })}
    </div>
  );
};

export const ShSingleDatePicker = ({
  themeSize,
  date,
  onDateChange,
  numberOfMonths = 2,
  placeholder,
  presets = [],
  isOutsideRange = () => false, // allow past+future dates by default
}: ShSingleDatePickerProps) => {
  const [focused, setFocused] = useState<{ focused: boolean | null }>({
    focused: null,
  });
  const startId = useHtmlForId();

  const size = useShInputSize(themeSize);
  injectGlobal`
  .DateInput_input {
       ${ShInputSizeConfigs[size].dateRangePickerInputCss}
  }`;

  // see https://github.com/airbnb/react-dates/blob/master/examples/DateRangePickerWrapper.jsx
  return (
    <SingleDatePicker
      renderCalendarInfo={() => (
        <SingleDatePresets
          presets={presets}
          onDateChange={onDateChange}
          setFocused={setFocused}
        />
      )}
      date={date}
      onDateChange={onDateChange}
      id={startId ?? 'ShDateRangePicker-startId'}
      focused={focused.focused}
      onFocusChange={setFocused}
      noBorder={true}
      showClearDate={true}
      showDefaultInputIcon={true}
      placeholder={placeholder}
      hideKeyboardShortcutsPanel={true}
      displayFormat={'D MMM'}
      firstDayOfWeek={1}
      numberOfMonths={numberOfMonths}
      isOutsideRange={isOutsideRange}
    />
  );
};
