import React from 'react';
import { H4 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import classnames from 'classnames';
import idx from 'idx';
import { ShText, useShTranslate } from '@shoootin/translations';
import { OrderStepOrder } from '../../orderPageTranslations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { ShCheckboxField, ShInputText } from '@shoootin/components-web';
import { ShMediaQueries, ShMediaQueriesDynamic } from '@shoootin/design-tokens';

const StepOrderMeet = () => {
  const {
    state: { infos, validationErrors },
    api: { setInfos },
  } = useOrderPageContext();

  const t = useShTranslate();
  return (
    <div className="form-order__section border meet">
      <H4>
        <ShText message={OrderStepOrder.contactInfos} />
      </H4>
      <div
        className={classnames(
          'form-order-collapse collapse-checkbox',
          infos.meetDownstairs ? '' : 'opened',
        )}
      >
        <ShCheckboxField
          value={infos.meetDownstairs}
          onChange={(value) => {
            setInfos({ ...infos, meetDownstairs: value });
          }}
          label={
            <ShText
              message={getFrontMessage('form_shootingInfos_meetDownstairs')}
            />
          }
          size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
        />

        <div className="collapse-panel">
          <div className="form-order-collapse__panel">
            <label className="field-label">
              <ShText
                message={getFrontMessage(
                  'form_shootingInfos_accessInformationsLabel',
                )}
              />
            </label>
            <ShInputText
              placeholder={t(
                getFrontMessage(
                  'form_shootingInfos_accessInformations_PlaceHolder',
                ),
              )}
              themeSize={'l'}
              value={infos.accessInformations ?? ''}
              onChange={(e) => {
                setInfos({ ...infos, accessInformations: e.target.value });
              }}
              error={
                idx(validationErrors, (_) => _.infos.accessInformations) ??
                undefined
              }
            />
          </div>
        </div>
      </div>
      <div
        className={classnames(
          'form-order-collapse collapse-checkbox',
          infos.contactOnSite ? '' : 'opened',
        )}
      >
        <ShCheckboxField
          value={infos.contactOnSite}
          onChange={(value) => {
            setInfos({ ...infos, contactOnSite: value });
          }}
          label={
            <ShText
              message={getFrontMessage('form_shootingInfos_contactOnSite')}
            />
          }
          size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
        />

        <div className="collapse-panel">
          <div className="form-order-collapse__panel">
            <label className="field-label">
              <ShText
                message={getFrontMessage(
                  'form_shootingInfos_contactCoordinates',
                )}
              />
            </label>
            <div
              css={{
                display: 'flex',
                paddingBottom: 10,
                justifyContent: 'space-between',
                [ShMediaQueries.ShDesktopsOnly]: {
                  flexWrap: 'wrap',
                },
              }}
            >
              <div
                css={{
                  flex: 1,
                  [ShMediaQueries.ShMobileOnly]: { marginRight: 5 },
                  [ShMediaQueries.ShDesktopsOnly]: { marginRight: 10 },
                }}
              >
                <ShInputText
                  placeholder={t(
                    getFrontMessage(
                      'form_shootingInfos_contactName_PlaceHolder',
                    ),
                  )}
                  themeSize={'l'}
                  value={infos.contactName ?? ''}
                  onChange={(e) => {
                    setInfos({ ...infos, contactName: e.target.value });
                  }}
                  error={
                    idx(validationErrors, (_) => _.infos.contactName) ??
                    undefined
                  }
                />
              </div>
              <div
                css={{
                  flex: 1,
                  [ShMediaQueries.ShMobileOnly]: { marginLeft: 5 },
                  [ShMediaQueries.ShDesktopsOnly]: { marginLeft: 10 },
                }}
              >
                <ShInputText
                  placeholder={t(
                    getFrontMessage(
                      'form_shootingInfos_contactPhoneNumber_PlaceHolder',
                    ),
                  )}
                  themeSize={'l'}
                  value={infos.contactPhoneNumber ?? ''}
                  onChange={(e) => {
                    setInfos({ ...infos, contactPhoneNumber: e.target.value });
                  }}
                  error={
                    idx(validationErrors, (_) => _.infos.contactPhoneNumber) ??
                    undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StepOrderMeet;
