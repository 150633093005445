import React from 'react';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { useOrderCurrency } from 'page/order/utils/orderPageUtils';
import {
  ShHelpModalDiv,
  ShIntercomArticles,
  ShOfferCollapse,
  ShPrice,
  ShSwitchBoxElementWrapper,
  ShSwitchHelp,
  ShTitle,
} from '@shoootin/components-web';
import StepOrderSwitchBox, {
  useStepOrderBasicOfferSwitchBox,
} from './stepOrderSwitchBox';
import { ShootingFields } from './stepOrderFields';
import { FrontCommonMessages } from '../../../../appCommonTranslations';
import { ShText } from '@shoootin/translations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import StepOrderOfferAdminInformations from './stepOrderOfferAdminInformations';
import { ShOfferVideoDTO } from '@shoootin/api';
import { minuteToHours } from '@shoootin/utils';

const VideoOffer = ({ offer }: { offer: ShOfferVideoDTO }) => {
  const currency = useOrderCurrency();
  const {
    state: { selectedOffers },
    api: { selectVideoOffer },
  } = useOrderPageContext();

  const opened = offer.id === selectedOffers.videoOfferId;
  const isAdmin = useIsOrderAdminMode();

  return (
    <ShOfferCollapse
      label={offer.name}
      value={opened}
      service={'video'}
      onChange={(value) => {
        value && selectVideoOffer(offer);
      }}
      sublabel={
        <>
          <div>
            <div>
              <ShText message={getFrontMessage('common_order_duration')} />{' '}
              {minuteToHours(offer.baseTime)}
            </div>
            {offer.description && (
              <div>
                <small>{offer.description}</small>
              </div>
            )}
            {isAdmin && <StepOrderOfferAdminInformations offer={offer} />}
          </div>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {offer.video.clientArticleId && (
              <span css={{ marginRight: 10 }}>
                <ShIntercomArticles id={offer.video.clientArticleId}>
                  <ShHelpModalDiv />
                </ShIntercomArticles>
              </span>
            )}
            <span className="text-price">
              <ShPrice price={offer.basePrice} currency={currency} />
            </span>
          </div>
        </>
      }
    >
      <ShSwitchBoxElementWrapper>
        <ShootingFields typeOfComponent="video" fields={offer.shootingFields} />
      </ShSwitchBoxElementWrapper>
      {/* <ul className="collapse-radio__select"> */}
      {/* <li className="collapse-radio__option">1h</li> */}
      {/* <li className="collapse-radio__option">1h15</li> */}
      {/* <li className="collapse-radio__option">1h30</li> */}
      {/* <li className="collapse-radio__option">1h45</li> */}
      {/* <li className="collapse-radio__option">2h00</li> */}
      {/* <li className="collapse-radio__option">2h15</li> */}
      {/* <li className="collapse-radio__option">2h30</li> */}
      {/* </ul> */}
    </ShOfferCollapse>
  );
};

const StepOrderVideo = ({
  videoOffers,
}: {
  videoOffers: ShOfferVideoDTO[];
}) => {
  const {
    state: { selectedOffers },
    api: { deleteVideoOffer, deleteCustomOffer },
    computed,
  } = useOrderPageContext();
  const [opened, setOpened] = useStepOrderBasicOfferSwitchBox(
    selectedOffers,
    'video',
  );
  const orderSummary = computed.orderSummary!;
  const selectedVideoOffer = orderSummary.offers.video;
  const switchBoxLabel = selectedVideoOffer ? (
    <>
      <ShText message={FrontCommonMessages.video} /> {selectedVideoOffer.name}
    </>
  ) : (
    <ShText message={FrontCommonMessages.video} />
  );

  return (
    <StepOrderSwitchBox
      key="video"
      service="video"
      opened={opened}
      onOpenedChange={(opened: boolean) => {
        setOpened(opened);
        if (!opened) {
          deleteVideoOffer();
        }
        if (opened) {
          deleteCustomOffer();
        } else {
          deleteVideoOffer();
        }
      }}
      label={
        <ShTitle size={4} color={opened ? 'white' : 'black'}>
          {switchBoxLabel}
        </ShTitle>
      }
    >
      {videoOffers &&
        videoOffers.map((offer) => <VideoOffer key={offer.id} offer={offer} />)}
      {/* <AppInputTextarea
        name="videoComment"
        placeholder="Commentaires"
        modifier="large"
      /> */}

      {/* <div className="switchbox-panel__option">
        <AppInputCheckbox label="Option musique" value={true} />
        <span className="text-price">100€</span> TODO
      </div> */}
      <ShSwitchHelp modalName="offerVideo" />
    </StepOrderSwitchBox>
  );
};

export default StepOrderVideo;
