import React, { ReactNode } from 'react';

import {
  ShAutoLink,
  ShButton,
  ShCol,
  ShDatePicker,
  ShInputCheckbox,
  ShInputError,
  ShInputFile,
  ShInputFileUploadResult,
  ShInputText,
  ShRow,
  ShSvgTrashSvg,
  ShTitle,
} from '@shoootin/components-web';
import { useCurrentClientUser } from '../../../../../../state/currentUserState';
import { ShColors } from '@shoootin/design-tokens';
import { FrontKeys, ShText, useShTranslate } from '@shoootin/translations';
import {
  nextDroneWorkingDayForEurope,
  nextDroneWorkingDayForUS,
  ShMomentType,
} from '@shoootin/utils';
import {
  AppMap,
  AppMapMarker,
  AppMapPolygon,
} from '../../../../../../primitives/maps/appMapElements';
import { useOrderPageContext } from '../../../../context/orderPageContext';
import {
  defaultDroneState,
  DroneErrors,
  DroneState,
} from '../../../../context/orderPageContextSpecificDroneState';
import { Marker } from '@slorber/react-google-maps';
import { OrderAddress } from '../../../../context/orderPageContextState';
import { getFrontMessage } from '../../../../../../appMessageDefinitions';
import { OrderStepOrder } from '../../../../orderPageTranslations';
import { H2 } from '../../../../../../primitives/appTitle';
import AppSection from '../../../../../../primitives/appSection';
import { ShCountryConfigs } from '@shoootin/config';

export const StepOrderDroneSuccess = () => {
  return (
    <AppSection
      header={
        <H2>
          <ShText message={OrderStepOrder.droneFormSuccessTitle} />
        </H2>
      }
      className="form-payment left"
    >
      <p className="form-payment-intro">
        <ShText message={OrderStepOrder.droneFormSuccessDescription} />
      </p>
    </AppSection>
  );
};

const FileUpload = ({
  title,
  description,
  setLoading,
  setFile,
  error,
}: {
  title: ReactNode;
  description: ReactNode;
  setLoading: (value: boolean) => void;
  setFile: (value: ShInputFileUploadResult | undefined) => void;
  error?: string;
}) => {
  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShTitle size={5}>{title}</ShTitle>
      </div>
      <div css={{ marginBottom: 10 }}>{description}</div>
      <ShInputFile
        onFileSelect={() => {
          setLoading(true);
        }}
        onFileUploaded={(uploadedFile: ShInputFileUploadResult) => {
          setFile(uploadedFile);
          setLoading(false);
        }}
        autoUpload={true}
        onFileDelete={() => {
          setFile(undefined);
          setLoading(false);
        }}
      >
        Charger
      </ShInputFile>
      {error && <ShInputError> {error}</ShInputError>}
    </div>
  );
};

const DroneMap = ({
  address,
  error,
  mapBounds,
  setDroneState,
}: {
  address: OrderAddress;
  error?: string;
  mapBounds: google.maps.LatLngLiteral[];
  setDroneState: (partial: Partial<DroneState>) => void;
}) => {
  const addPolyline = (latLng: google.maps.LatLngLiteral) => {
    const bounds = [...mapBounds, latLng];
    setDroneState({ bounds });
  };
  const removePolyline = (latLng: google.maps.LatLngLiteral) => {
    const bounds = mapBounds.filter(
      (l: google.maps.LatLngLiteral) => l !== latLng,
    );
    setDroneState({ bounds });
  };

  const polygon =
    mapBounds.length > 1 ? [...mapBounds, mapBounds[0]] : mapBounds;

  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShTitle size={5}>
          <ShText<FrontKeys> message={'form_drone_offer_mapBounds_Label'} />
        </ShTitle>
      </div>
      <div css={{ marginBottom: 10 }}>
        <ShText<FrontKeys> message={'form_drone_offer_mapBoundsDescription'} />
      </div>

      <div css={{ height: 500, width: '100%' }}>
        <AppMap
          key={'droneMap'}
          center={{
            lat: address!.latitude,
            lng: address!.longitude,
          }}
          zoom={17}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            mapTypeId: google!.maps.MapTypeId.SATELLITE,
          }}
          onClick={(e) =>
            addPolyline({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            })
          }
        >
          {mapBounds.length > 0 && (
            <div css={{ position: 'absolute', top: 10, left: 10 }}>
              <ShButton
                onClick={() => setDroneState({ bounds: [] })}
                variant={'ghost'}
              >
                <ShText message={OrderStepOrder.droneFormMapReset} />
              </ShButton>
            </div>
          )}
          {address && (
            <AppMapMarker
              position={{
                lat: address.latitude,
                lng: address.longitude,
              }}
            />
          )}
          <AppMapPolygon options={{ strokeColor: 'red' }} paths={[polygon]} />

          {mapBounds.map((marker: google.maps.LatLngLiteral) => {
            return (
              <Marker
                key={`${marker.lat}-${marker.lng}`}
                position={marker}
                icon={{
                  // ...ShDefaultMarkerIconSm(ShColors.error),
                  path: google.maps.SymbolPath.CIRCLE,
                  // @ts-ignore
                  color: ShColors.base,
                  fillColor: ShColors.base,
                  opacity: 1,
                  fillOpacity: 1,
                  scale: 7,
                  strokeColor: ShColors.base,
                  strokeWeight: 1,
                }}
                onClick={() => removePolyline(marker)}
              />
            );
          })}
        </AppMap>
      </div>
      {error && <ShInputError> {error}</ShInputError>}
    </div>
  );
};

const DroneDates = ({
  error,
  droneDates,
  setDroneState,
  europe,
}: {
  error?: string;
  droneDates: ShMomentType[];
  setDroneState: (partial: Partial<DroneState>) => void;
  europe: boolean;
}) => {
  const addDate = (date: ShMomentType) => {
    // need to  be unique dates to avoid duplicates dates in droneState.dates
    const dates = Array.from(new Set([...droneDates, date]));
    setDroneState({ dates });
  };
  const removeDate = (date: ShMomentType) => {
    setDroneState({
      dates: droneDates.filter((d: ShMomentType) => d !== date),
    });
  };

  const firstAvailableDate = europe
    ? nextDroneWorkingDayForEurope()
    : nextDroneWorkingDayForUS();

  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShTitle size={5}>
          <ShText<FrontKeys> message={'form_drone_offer_dates_Label'} />
        </ShTitle>
      </div>
      <div css={{ marginBottom: 10 }}>
        <ShText message={OrderStepOrder.droneFormDatesDescription} />
      </div>
      <div css={{ display: 'flex' }}>
        <div css={{ maxWidth: 400, width: '100%', minHeight: 320 }}>
          <ShDatePicker
            date={null}
            onDateChange={(date: ShMomentType) => {
              addDate(date);
            }}
            isDayBlocked={undefined}
            firstAvailableDate={firstAvailableDate}
          />
        </div>
        <div
          css={{
            paddingTop: 20,
            paddingLeft: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '100%',
          }}
        >
          {droneDates.map((date: ShMomentType) => (
            <div
              key={date.toString()}
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: `1px solid ${ShColors.pattern}`,
                paddingBottom: 5,
              }}
            >
              <div css={{ paddingRight: 20 }}>
                <b>{date.format('dddd DD MMMM YYYY')}</b>
              </div>
              <div onClick={() => removeDate(date)} css={{ cursor: 'pointer' }}>
                <ShSvgTrashSvg width={20} color={'blackLL'} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {error && <ShInputError> {error}</ShInputError>}
    </div>
  );
};

const DroneCGV = ({
  cgv,
  setCgv,
  error,
}: {
  cgv: boolean;
  setCgv: (value: boolean) => void;
  error?: string;
}) => {
  return (
    <div>
      <div css={{ marginBottom: 10 }}>
        <ShTitle size={5}>
          <ShText message={OrderStepOrder.droneFormCGVTitle} />
        </ShTitle>
      </div>
      <div css={{ marginBottom: 10 }}>
        <ShText message={OrderStepOrder.droneFormCGVContent} />
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ marginRight: 10 }}>
          <ShInputCheckbox value={cgv} onChange={setCgv} />
        </div>
        <div>
          <ShText message={OrderStepOrder.droneFormCGVCheckbox} />
        </div>
      </div>
      {error && <ShInputError> {error}</ShInputError>}
    </div>
  );
};

const DroneUserInformations = ({
  droneState,
  setDroneState,
  errors,
}: {
  droneState?: DroneState;
  setDroneState: (partial: Partial<DroneState>) => void;
  errors?: DroneErrors;
}) => {
  const tr = useShTranslate();

  return (
    <div>
      <div css={{ marginBottom: 20 }}>
        <ShTitle size={5}>
          <ShText message={OrderStepOrder.droneFormUserTitle} />
        </ShTitle>
        <ShRow className="field-row wrap-s">
          <ShCol xs={60} md={30}>
            <ShInputText
              placeholder={tr(
                getFrontMessage('form_userInfos_firstName_PlaceHolder'),
              )}
              required
              value={droneState?.firstName ?? ''}
              onChange={(e) => setDroneState({ firstName: e.target.value })}
            />
            {errors?.firstName && (
              <ShInputError> {errors.firstName}</ShInputError>
            )}
          </ShCol>
          <ShCol xs={60} md={30}>
            <ShInputText
              value={droneState?.lastName ?? ''}
              onChange={(e) => setDroneState({ lastName: e.target.value })}
              placeholder={tr(
                getFrontMessage('form_userInfos_lastName_PlaceHolder'),
              )}
              required
            />
            {errors?.lastName && (
              <ShInputError> {errors.lastName}</ShInputError>
            )}
          </ShCol>
        </ShRow>
        <ShRow className="field-row wrap-s">
          <ShCol xs={60} md={35}>
            <ShInputText
              value={droneState?.email ?? ''}
              onChange={(e) => setDroneState({ email: e.target.value })}
              placeholder={tr(
                getFrontMessage('form_userInfos_email_PlaceHolder'),
              )}
              required
            />
            {errors?.email && <ShInputError> {errors.email}</ShInputError>}
          </ShCol>
          <ShCol xs={60} md={25}>
            <ShInputText
              value={droneState?.mobilePhone ?? ''}
              onChange={(e) => setDroneState({ mobilePhone: e.target.value })}
              placeholder={tr(
                getFrontMessage('form_userInfos_mobilePhone_PlaceHolder'),
              )}
              required
            />
            {errors?.mobilePhone && (
              <ShInputError> {errors.mobilePhone}</ShInputError>
            )}
          </ShCol>
        </ShRow>
      </div>
    </div>
  );
};

const StepOrderDroneForm = () => {
  const currentUser = useCurrentClientUser();

  const {
    state: { droneState, address, country, validationErrors },
    api: { setDroneState },
  } = useOrderPageContext();

  const EU = ShCountryConfigs[country].europe;

  const droneStateValues = droneState ?? defaultDroneState;
  const droneErrors = validationErrors?.droneErrors ?? {};

  const setAutorisationFile = (autorisationFile?: ShInputFileUploadResult) => {
    setDroneState({ autorisationFile });
  };
  const setAutorisationFileLoading = (autorisationFileLoading: boolean) => {
    setDroneState({ autorisationFileLoading });
  };

  const setMissionLetterFile = (
    missionLetterFile?: ShInputFileUploadResult,
  ) => {
    setDroneState({ missionLetterFile });
  };
  const setMissionLetterFileLoading = (missionLetterFileLoading: boolean) => {
    setDroneState({ missionLetterFileLoading });
  };
  const setExtraInfosFile = (extraInfosFile?: ShInputFileUploadResult) => {
    setDroneState({ extraInfosFile });
  };
  const setExtraInfosFileLoading = (extraInfosFileLoading: boolean) => {
    setDroneState({ extraInfosFileLoading });
  };

  const setCgv = (cgv: boolean) => {
    setDroneState({ cgv });
  };
  const tr = useShTranslate();
  return (
    <div
      css={{
        borderTop: `1px solid ${ShColors.pattern}`,
        marginRight: '1.5rem',
        marginLeft: '1.5rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 40,
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <DroneMap
          setDroneState={setDroneState}
          address={address!}
          error={droneErrors?.bounds}
          mapBounds={droneStateValues.bounds}
        />

        <div
          css={{
            borderTop: `1px solid ${ShColors.pattern}`,
          }}
        />
        {/*{EU && (
          <div>
            <FileUpload
              setFile={setAutorisationFile}
              title={
                <ShText<FrontKeys>
                  message={'form_drone_offer_autorisationFile_Label'}
                />
              }
              setLoading={setAutorisationFileLoading}
              error={droneErrors?.autorisationFile}
              description={
                <ShAutoLink
                  text={tr(
                    getFrontMessage(
                      OrderStepOrder.droneFormFileAutorisationDescription
                        .id as FrontKeys,
                    ),
                  )}
                  withBlankIcon={false}
                />
              }
            />
          </div>
        )}
        {EU && (
          <div>
            <FileUpload
              title={
                <ShText<FrontKeys>
                  message={'form_drone_offer_missionLetterFile_Label'}
                />
              }
              description={
                <ShAutoLink
                  text={tr(
                    getFrontMessage(
                      OrderStepOrder.droneFormFileMissionDescription
                        .id as FrontKeys,
                    ),
                  )}
                  withBlankIcon={false}
                />
              }
              setLoading={setMissionLetterFileLoading}
              setFile={setMissionLetterFile}
              error={droneErrors?.missionLetterFile}
            />
          </div>
        )}*/}

        <div>
          <FileUpload
            title={
              <ShText<FrontKeys>
                message={'form_drone_offer_extraInfosFile_Label'}
              />
            }
            description={
              <ShAutoLink
                text={tr(
                  getFrontMessage(
                    OrderStepOrder.droneFormFileExtraInfosDescription
                      .id as FrontKeys,
                  ),
                )}
                withBlankIcon={false}
              />
            }
            setLoading={setExtraInfosFileLoading}
            setFile={setExtraInfosFile}
            error={droneErrors?.extraInfosFile}
          />
        </div>
        <div
          css={{
            borderTop: `1px solid ${ShColors.pattern}`,
          }}
        />
        <div>
          <DroneDates
            droneDates={droneStateValues.dates}
            setDroneState={setDroneState}
            error={droneErrors?.dates}
            europe={EU}
          />
        </div>
        {!currentUser && (
          <div
            css={{
              borderTop: `1px solid ${ShColors.pattern}`,
              paddingTop: 40,
            }}
          >
            <DroneUserInformations
              droneState={droneState}
              setDroneState={setDroneState}
              errors={droneErrors}
            />
          </div>
        )}
        <div
          css={{
            borderTop: `1px solid ${ShColors.pattern}`,
            paddingTop: 40,
          }}
        >
          <DroneCGV
            cgv={droneStateValues.cgv}
            setCgv={setCgv}
            error={droneErrors?.cgv}
          />
        </div>
      </div>
    </div>
  );
};

export default StepOrderDroneForm;
