import React, { ReactNode } from 'react';
import { ShCurrency } from '@shoootin/config';
import { ShIntlMessageDefinition, ShText } from '@shoootin/translations';
import { ShPrice } from '@shoootin/components-web';

const CartItem = ({
  label,
  price,
  currency,
  children,
}: {
  label: ShIntlMessageDefinition;
  price: number;
  currency: ShCurrency;
  children: ReactNode;
}) => (
  <article className="cart-item">
    <p className="cart-item__title">
      <b>
        <ShText message={label} />
      </b>
      {price > 0 && (
        <span className="cart-item__price">
          <ShPrice currency={currency} price={price} />
        </span>
      )}
    </p>
    <p className="cart-item__detail">{children}</p>
  </article>
);

export default CartItem;
