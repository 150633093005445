import React from 'react';
import { H4 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import idx from 'idx';
import { ShootingFields } from './stepOrderFields';
import { ShText, useShTranslate } from '@shoootin/translations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { ShInputText, ShInputTextarea } from '@shoootin/components-web';

const StepOrderInfos = () => {
  const {
    state: { infos, clientExtraFields, validationErrors },
    api: { setInfos },
  } = useOrderPageContext();

  const t = useShTranslate();
  return (
    <div className="form-order__section">
      <H4>
        <ShText message={getFrontMessage('form_shootingInfos_comment_Label')} />
      </H4>
      <ShInputTextarea
        placeholder={t(
          getFrontMessage('form_shootingInfos_comment_PlaceHolder'),
        )}
        themeSize={'l'}
        value={infos.comment ?? ''}
        onChange={(e) => {
          setInfos({ ...infos, comment: e.target.value });
        }}
      />
      <H4>
        <ShText
          message={getFrontMessage('form_shootingInfos_referenceLabel_Label')}
        />
      </H4>
      <ShInputText
        placeholder={t(
          getFrontMessage('form_shootingInfos_reference_PlaceHolder'),
        )}
        themeSize={'l'}
        value={infos.reference ?? ''}
        onChange={(e) => {
          setInfos({ ...infos, reference: e.target.value });
        }}
        error={idx(validationErrors, (_) => _.infos.reference) ?? undefined}
      />
      <div css={{ paddingTop: '0.75rem' }}>
        {clientExtraFields.length > 0 && (
          <ShootingFields typeOfComponent="client" fields={clientExtraFields} />
        )}
      </div>
    </div>
  );
};
export default StepOrderInfos;
