import React from 'react';

import {
  computeScanMaxSurface,
  computeScanSurfacePrice,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import {
  ShCheckboxField,
  ShInputTextarea,
  ShNumberRangeField,
  ShPrice,
  ShSwitchBoxElementOption,
} from '@shoootin/components-web';
import {
  OrderOffersScanValidationErrors,
  ScanConfig,
} from '../../context/orderPageContextState';
import idx from 'idx';
import { ShText, useShTranslate } from '@shoootin/translations';
import { OrderStepOrder } from '../../orderPageTranslations';
import { getFrontMessage } from 'appMessageDefinitions';
import { useIntercom } from 'utils/intercomUtils';
import { ShCountryConfigs } from '@shoootin/config';
import { ShOfferScanDTO } from '@shoootin/api';
import { ShMediaQueriesDynamic } from '@shoootin/design-tokens';
import { ShSwitchBoxElementField } from '@shoootin/components-web/src';

const MaxSpaces = 200;

const StepOrderScanForm = ({
  scanOffer,
  scanConfig,
  mutateScanConfig,
  scanValidationErrors,
}: {
  scanOffer: ShOfferScanDTO;
  scanConfig: ScanConfig;
  mutateScanConfig: (updater: (scanConfig: ScanConfig) => void) => void;
  scanValidationErrors?: OrderOffersScanValidationErrors | null;
}) => {
  const intercom = useIntercom();
  const currency = useOrderCurrency();
  const extraSpacesPrice =
    scanConfig.extraSpace * scanOffer.scan.extraModelPrice;
  const surfacePrice = computeScanSurfacePrice(scanOffer, scanConfig);
  const maxSurface = computeScanMaxSurface(scanOffer);
  const translate = useShTranslate();
  const metricSystem = ShCountryConfigs[scanOffer.country].metricSystem;
  return (
    <>
      {scanOffer.scan.canOrderFloorPlan && (
        <ShSwitchBoxElementOption
          option={
            <ShCheckboxField
              value={scanConfig.floorPlan}
              onChange={(value) =>
                mutateScanConfig((config) => {
                  config.floorPlan = value;
                })
              }
              label={
                <ShText message={getFrontMessage('common_order_floorPlan')} />
              }
              bold={true}
              size={ShMediaQueriesDynamic.ShMobileOnlyDynamic() ? 's' : 'm'}
              service={'matterport'}
              style={{ marginBottom: 0 }}
            />
          }
          price={
            <ShPrice
              price={scanOffer.scan.floorPlanPrice}
              currency={currency}
            />
          }
        />
      )}
      <ShSwitchBoxElementField>
        <ShNumberRangeField
          value={scanConfig.surface}
          onChange={(value: number) =>
            mutateScanConfig((config) => {
              config.surface = value;
            })
          }
          label={{
            label: translate(getFrontMessage('common_order_surfaceToScan'), {
              metric: metricSystem ? 'm\u00B2' : 'sqft',
            }),
            legend: <ShText message={OrderStepOrder.scanSurfaceLegend} />,
            error: scanConfig.surface >= maxSurface && (
              <>
                <ShText message={OrderStepOrder.scanSurfaceError} />{' '}
                <a
                  css={{ cursor: 'pointer' }}
                  onClick={intercom.openWithEmptyMessage}
                >
                  <ShText
                    message={getFrontMessage('common_actions_contactUs')}
                  />
                </a>
              </>
            ),
            price: surfacePrice > 0 && (
              <ShPrice price={surfacePrice} currency={currency} />
            ),
          }}
          max={maxSurface}
          step={50}
          error={idx(scanValidationErrors, (_) => _.surface)}
          service={'matterport'}
        />
      </ShSwitchBoxElementField>

      {scanOffer.scan.addExtraModel && (
        <ShSwitchBoxElementField>
          <ShNumberRangeField
            value={scanConfig.extraSpace}
            onChange={(value) =>
              mutateScanConfig((config) => {
                config.extraSpace = value;
              })
            }
            label={{
              label: translate(getFrontMessage('common_order_extraModel')),
              legend: <ShText message={OrderStepOrder.scanExtraModelLegend} />,
              error: scanConfig.extraSpace >= MaxSpaces && (
                <>
                  <ShText message={OrderStepOrder.scanExtraModelError} />{' '}
                  <a
                    css={{ cursor: 'pointer' }}
                    onClick={intercom.openWithEmptyMessage}
                  >
                    <ShText
                      message={getFrontMessage('common_actions_contactUs')}
                    />
                  </a>
                </>
              ),
              price: extraSpacesPrice > 0 && (
                <ShPrice price={extraSpacesPrice} currency={currency} />
              ),
            }}
            max={MaxSpaces}
            service={'matterport'}
          />
        </ShSwitchBoxElementField>
      )}

      <ShSwitchBoxElementField>
        <ShInputTextarea
          value={scanConfig.zonesToScan}
          required={true}
          onChange={(e) => {
            const comment = e.target.value;
            mutateScanConfig((config) => {
              config.zonesToScan = comment;
            });
          }}
          placeholder={translate(
            getFrontMessage('form_offer_zonesToScan_PlaceHolder'),
          )}
          error={idx(scanValidationErrors, (_) => _.zonesToScan)}
        />
      </ShSwitchBoxElementField>

      {/* <AppInputTextarea
        value={scanConfig.zonesToScan}
        name="zonesToScan"
        required={true}
        onChange={(e) => {
          const comment = e.target.value;
          mutateScanConfig((config) => {
            config.zonesToScan = comment;
          });
        }}
        placeholderMessage={getFrontMessage(
          'form_offer_zonesToScan_PlaceHolder',
        )}
        error={idx(scanValidationErrors, (_) => _.zonesToScan)}
      /> */}
    </>
  );
};

export default StepOrderScanForm;
