import React from 'react';
import { H3 } from 'primitives/appTitle';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import {
  useOfferCustomComponentsString,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import StepOrderScanForm from './stepOrderScanForm';
import StepOrderSwitchBox, {
  useStepOrderCustomOfferSwitchBox,
} from './stepOrderSwitchBox';
import {
  ShHelpModalButton,
  ShPrice,
  ShSwitchBoxElementCustomComponentHeader,
  ShSwitchBoxElementPanelHeader,
  ShSwitchBoxElementWrapper,
  ShSwitchHelp,
  ShTitle,
} from '@shoootin/components-web';
import idx from 'idx';
import { ShootingFields } from './stepOrderFields';
import { ShText } from '@shoootin/translations';
import { OrderStepOrder } from '../../orderPageTranslations';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import { FrontCommonMessages } from '../../../../appCommonTranslations';

import StepOrderFloorPlanForm from './stepOrderFloorPlanForm';
import StepOrderOfferAdminInformations from './stepOrderOfferAdminInformations';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import {
  ShOfferCustomDTO,
  ShOfferFloorPlanDTO,
  ShOfferScanDTO,
} from '@shoootin/api';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import { minuteToHours } from '@shoootin/utils';

const CustomOffer = ({ offer }: { offer: ShOfferCustomDTO }) => {
  const {
    state: { selectedOffers, validationErrors },
    api: {
      selectCustomOffer,
      deleteCustomOffer,
      mutateScanConfig,
      mutateFloorPlanConfig,
    },
    computed: { orderSummary },
  } = useOrderPageContext();
  const isAdmin = useIsOrderAdminMode();
  const currency = useOrderCurrency();
  const customOfferComponents = useOfferCustomComponentsString(offer);
  const [opened, setOpened] = useStepOrderCustomOfferSwitchBox(
    selectedOffers,
    offer,
  );
  return (
    <StepOrderSwitchBox
      key="photo-scan"
      service={'custom'}
      opened={opened}
      onOpenedChange={(opened: boolean) => {
        if (opened) {
          selectCustomOffer(offer);
          setOpened(true);
        } else {
          deleteCustomOffer();
        }
      }}
      label={
        <div
          css={{
            color: opened ? ShColors.white : ShColors.black,
          }}
        >
          <p
            css={{
              marginBottom: '0.25rem',
              [ShMediaQueries.ShMobileOnly]: {
                fontSize: 14,
              },
            }}
          >
            {offer.name}
          </p>
          <ShTitle size={4} color={opened ? 'white' : 'black'}>
            {customOfferComponents}
          </ShTitle>
          <p
            css={{
              marginTop: '0.25rem',
              opacity: opened ? 0 : 1,
              [ShMediaQueries.ShMobileOnly]: {
                fontSize: 14,
              },
            }}
          >
            <ShText
              message={getFrontMessage('common_order_priceFrom')}
              values={{
                price: <ShPrice price={offer.basePrice} currency={currency} />,
              }}
            />
          </p>
        </div>
      }
    >
      <ShSwitchBoxElementPanelHeader>
        <ShText
          message={getFrontMessage('common_order_durationFrom')}
          values={{
            duration: minuteToHours(offer.baseTime),
          }}
        />
      </ShSwitchBoxElementPanelHeader>
      {isAdmin && (
        <ShSwitchBoxElementPanelHeader>
          <StepOrderOfferAdminInformations offer={offer} />
        </ShSwitchBoxElementPanelHeader>
      )}
      {offer.photo && (
        <>
          <ShSwitchBoxElementCustomComponentHeader
            title={
              <ShText
                message={getFrontMessage('front_common_nPhoto')}
                values={{ n: offer.photo.photosNb }}
              />
            }
            help={<ShHelpModalButton modalName="offerPhoto" />}
            service={'photo'}
          />

          <ShSwitchBoxElementWrapper>
            <ShootingFields
              typeOfComponent="photo"
              fields={offer.shootingFields}
            />
          </ShSwitchBoxElementWrapper>
        </>
      )}
      {/* <Collapse label="Options">
      <div className="form-options">
        <p className="form-options__label">Hébergement</p>
        <p className="form-options__title">
          <span className="title">32 photos — Pro</span>
          <TrashSvg />
          <span className="text-price">115€</span>
        </p>
        <p>Durée 1h30</p>
      </div>
      <div className="form-options">
        <p className="form-options__label">Service de l’hôtel</p>
        <p className="form-options__title">
          <span className="title">48 photos — Pro</span>
          <TrashSvg />
          <span className="text-price">180€</span>
        </p>
        <p>Durée 1h30</p>
      </div>
      <Options />
    </Collapse> */}

      {offer.scan && (
        <>
          <ShSwitchBoxElementCustomComponentHeader
            title={<ShText message={FrontCommonMessages.scan} />}
            service={'matterport'}
          />

          <StepOrderScanForm
            scanOffer={offer as ShOfferScanDTO}
            scanConfig={selectedOffers.scanConfig}
            mutateScanConfig={mutateScanConfig}
            scanValidationErrors={idx(
              validationErrors,
              (_) => _.offers.scanErrors,
            )}
          />
          <ShSwitchBoxElementWrapper>
            <ShootingFields
              typeOfComponent="scan"
              fields={offer.shootingFields}
            />
          </ShSwitchBoxElementWrapper>
        </>
      )}
      {offer.floorPlan && (
        <>
          <StepOrderFloorPlanForm
            floorPlanOffer={offer as ShOfferFloorPlanDTO}
            floorPlanConfig={selectedOffers.floorPlanConfig}
            mutateFloorPlanConfig={mutateFloorPlanConfig}
            floorPlanValidationErrors={idx(
              validationErrors,
              (_) => _.offers.floorPlanErrors,
            )}
          />
        </>
      )}
      {offer.video && (
        <>
          <ShSwitchBoxElementCustomComponentHeader
            title={<ShText message={FrontCommonMessages.video} />}
            help={<ShHelpModalButton modalName="offerVideo" />}
            service={'video'}
          />

          {/* <AppInputTextarea name="videoComment" placeholder="Commentaires" />
          <div className="switchbox-panel__option">
            <AppInputCheckbox label="Option musique TODO" value={true} />
            <span className="text-price">100€</span>
          </div> */}
          <ShSwitchBoxElementWrapper>
            <ShootingFields
              typeOfComponent="video"
              fields={offer.shootingFields}
            />
          </ShSwitchBoxElementWrapper>
        </>
      )}

      <ShSwitchHelp modalName="offerCustom">{offer.description}</ShSwitchHelp>
    </StepOrderSwitchBox>
  );
};

const StepOrderCustom = ({
  customOffers,
}: {
  customOffers: ShOfferCustomDTO[];
}) => (
  <>
    <H3>
      <ShText message={OrderStepOrder.customOffers} />
      <ShHelpModalButton modalName="offerCustom" />
    </H3>
    {customOffers.map((offer) => (
      <CustomOffer key={offer.id} offer={offer} />
    ))}
  </>
);

export default StepOrderCustom;
