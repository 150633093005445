import { Moment } from 'moment';
import React from 'react';
import { last } from 'lodash';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import { ShText } from '@shoootin/translations';
import { useIntercom } from '../../../../utils/intercomUtils';
import { IntercomMessageNoAvailability } from './stepBook';
import { ShOrderStepBook } from '@shoootin/components-web';
import { ShMoment } from '@shoootin/utils';

const computeNextAvailability = (emptyDays: number[]) =>
  ShMoment(last(emptyDays)).add(1, 'day');
//TODO refactor with appjs
export const NextAvailability = ({
  timeZone,
  selectedDay,
  emptyDays,
  onNextAvailabilityPress,
  intercomNoAvailabilityValues,
}: {
  timeZone: string;
  selectedDay: Moment;
  emptyDays: number[];
  onNextAvailabilityPress: (moment: Moment) => void;
  intercomNoAvailabilityValues: IntercomMessageNoAvailability;
}) => {
  const intercom = useIntercom();
  const nextAvailability = computeNextAvailability(emptyDays);
  const isAdmin = useIsOrderAdminMode();

  return (
    <div css={{ marginBottom: '1em' }}>
      <div css={{ marginBottom: '0.5em' }}>
        <ShText
          message={ShOrderStepBook.noAvailabilityBefore}
          values={{
            selectedDay: (
              <a
                className="link"
                onClick={() => onNextAvailabilityPress(nextAvailability)}
              >
                {nextAvailability.tz(timeZone).format('LL')}
              </a>
            ),
          }}
        />
      </div>
      {!isAdmin && (
        <div css={{ marginBottom: '0.5em' }}>
          <small>
            <ShText
              message={ShOrderStepBook.noAvailabilityMustBeDone}
              values={{
                selectedDay: selectedDay.format('LL'),
                clickHere: (
                  <a
                    className="link"
                    onClick={() =>
                      intercom.showNoAvailabilityForSelectedDay(
                        intercomNoAvailabilityValues,
                      )
                    }
                  >
                    <ShText message={ShOrderStepBook.clickHere} />
                  </a>
                ),
              }}
            />
          </small>
        </div>
      )}
    </div>
  );
};
