import React, { ReactNode } from 'react';
import { H4 } from 'primitives/appTitle';
import CartItem from './cartItem';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import {
  computeFloorPlanElements,
  computeScanElements,
  getStickyStyle,
  hasAnyOffer,
  OrderSummaryComputed,
  useOfferCustomComponentsString,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import { animated } from 'react-spring';
import { SingleItemOpacityTransition } from '../../../utils/reactSpringUtils';
import { AppButton } from '../../../primitives/appButton';
import {
  FloorPlanConfig,
  ScanConfig,
  VideoConfig,
} from '../context/orderPageContextState';
import { FrontKeys, ShText } from '@shoootin/translations';
import { OrderStepOrder } from '../orderPageTranslations';
import { FrontCommonMessages } from '../../../appCommonTranslations';
import { Sticky } from 'react-sticky';
import { ShPrice } from '@shoootin/components-web';
import {
  getFrontMessage,
  getFrontMessageUnsafe,
} from '../../../appMessageDefinitions';
import {
  ShOfferCustomDTO,
  ShOfferFloorPlanDTO,
  ShOfferOptionDTO,
  ShOfferPhotoDTO,
  ShOfferScanDTO,
  ShOfferVideoDTO,
} from '@shoootin/api';
import { minuteToHours } from '@shoootin/utils';

const CartPhotoOffer = ({
  offerPhoto,
  options,
  price,
  time,
  nb,
}: {
  offerPhoto: ShOfferPhotoDTO;
  options: ShOfferOptionDTO[];
  price: number;
  time: number;
  nb: number;
}) => {
  const currency = useOrderCurrency();
  return (
    <CartItem
      label={FrontCommonMessages.photo}
      price={price}
      currency={currency}
    >
      <ShText
        message={FrontCommonMessages.nPhotos}
        values={{ n: offerPhoto.photo.photosNb }}
      />{' '}
      — {offerPhoto.name}
      <br />
      {options.map((option) => (
        <CartPhotoOption key={option.id} option={option} />
      ))}
    </CartItem>
  );
};

const CartPhotoOption = ({ option }: { option: ShOfferOptionDTO }) => (
  <>
    +{' '}
    <ShText
      message={FrontCommonMessages.nPhotos}
      values={{ n: option.photosNb }}
    />{' '}
    —{' '}
    <ShText
      message={getFrontMessageUnsafe(
        `enums_optionCategories_${option.category}`,
      )}
    />
    <br />
  </>
);

const CartVideoOffer = ({
  offerVideo,
  offerVideoConfig,
  price,
  time,
}: {
  offerVideo: ShOfferVideoDTO;
  offerVideoConfig: VideoConfig;
  price: number;
  time: number;
}) => {
  const currency = useOrderCurrency();
  return (
    <CartItem
      label={FrontCommonMessages.video}
      price={price}
      currency={currency}
    >
      {offerVideo.name}
      {offerVideoConfig.music && (
        <>
          <br />+ Option musique
        </>
      )}
    </CartItem>
  );
};

const CartScanOffer = ({
  offerScan,
  offerScanConfig,
  price,
}: {
  offerScan: ShOfferScanDTO;
  offerScanConfig: ScanConfig;
  price: number;
}) => {
  const currency = useOrderCurrency();
  const elements = computeScanElements(offerScan, offerScanConfig);
  return (
    <CartItem
      label={FrontCommonMessages.scan}
      price={price}
      currency={currency}
    >
      {elements.map((element, i) => (
        <React.Fragment key={i}>
          {element}
          <br />
        </React.Fragment>
      ))}
    </CartItem>
  );
};

const CartFloorPlanOffer = ({
  offerFloorPlan,
  offerFloorPlanConfig,
  price,
}: {
  offerFloorPlan: ShOfferFloorPlanDTO;
  offerFloorPlanConfig: FloorPlanConfig;
  price: number;
}) => {
  const currency = useOrderCurrency();
  const elements = computeFloorPlanElements(
    offerFloorPlan,
    offerFloorPlanConfig,
  );
  return (
    <CartItem
      label={FrontCommonMessages.floorPlan}
      price={price}
      currency={currency}
    >
      {elements.map((element, i) => (
        <React.Fragment key={i}>
          {element}
          <br />
        </React.Fragment>
      ))}
    </CartItem>
  );
};

const CartCustomOffer = ({
  offerCustom,
  offerScanConfig,
}: {
  offerCustom: ShOfferCustomDTO;
  offerScanConfig: ScanConfig | undefined;
}) => {
  const currency = useOrderCurrency();
  const offerComponents = useOfferCustomComponentsString(offerCustom);
  const scanElements =
    !!offerCustom.scan &&
    !!offerScanConfig &&
    computeScanElements(offerCustom, offerScanConfig);
  const isDrone = offerCustom.offerType === 'aerial';
  return (
    <CartItem
      label={
        !isDrone
          ? getFrontMessage('form_offer_tailorMadeOffer')
          : getFrontMessage(FrontCommonMessages.drone.id as FrontKeys)
      }
      price={offerCustom.basePrice}
      currency={currency}
    >
      {offerCustom.name} - {offerComponents} <br />
      {scanElements &&
        scanElements.map((element, i) => (
          <React.Fragment key={i}>
            {element}
            <br />
          </React.Fragment>
        ))}
    </CartItem>
  );
};

export const CartDiscount = ({ discount }: { discount: number }) => {
  const currency = useOrderCurrency();
  return (
    <p className="cart-item__discount">
      <ShText message={OrderStepOrder.cartDiscount} />
      <span>
        <ShPrice price={discount} currency={currency} />
      </span>
    </p>
  );
};
export const CartExtraCharge = ({ extraCharge }: { extraCharge: number }) => {
  const currency = useOrderCurrency();
  return (
    <p className="cart-item__discount">
      <ShText message={OrderStepOrder.cartExtraCharge} />
      <span>
        <ShPrice price={extraCharge} currency={currency} />
      </span>
    </p>
  );
};

export const CartDuration = ({ totalTime }: { totalTime: number }) => {
  return (
    <p className="cart-item__duration">
      <ShText message={getFrontMessage('common_order_duration')} />
      <span>{minuteToHours(totalTime)}</span>
    </p>
  );
};
export const CartCustom = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  return (
    <p className="cart-item__line">
      <b>{label}</b>
      <span>{value}</span>
    </p>
  );
};

const CartProgressContent = ({
  canValidate,
  orderSummary,
  onValidate,
}: {
  canValidate: boolean;
  orderSummary: OrderSummaryComputed;
  onValidate?: () => void;
}) => {
  const currency = useOrderCurrency();
  return (
    <>
      <H4 className="cart__header">
        <ShText message={OrderStepOrder.cartTitle} />
      </H4>
      <div className="cart__panel">
        <CartOffersPart orderSummary={orderSummary} />
        {orderSummary.discountValue !== 0 && (
          <CartDiscount discount={orderSummary.discountValue} />
        )}
        {orderSummary.extraCharge !== 0 && (
          <CartExtraCharge extraCharge={orderSummary.extraCharge} />
        )}
        {orderSummary.totalTime !== 0 && (
          <CartDuration totalTime={orderSummary.totalTime} />
        )}
        {orderSummary.totalPrice > 0 && (
          <footer className="cart__footer">
            <p className="cart__total">
              <span className="cart__label">
                <ShText
                  message={getFrontMessage('common_order_totalPriceWithoutTax')}
                />
              </span>
              <span className="cart__price">
                <b>
                  <ShPrice
                    price={orderSummary.totalPrice}
                    currency={currency}
                  />
                </b>
              </span>
            </p>
            <AppButton
              className="cart-button"
              modifier="large"
              onClick={onValidate}
              disabled={!canValidate}
            >
              <ShText message={getFrontMessage('common_actions_validate')} />
            </AppButton>
          </footer>
        )}
      </div>
    </>
  );
};

const CartProgress = ({
  canValidate,
  onValidate,
}: {
  canValidate: boolean;
  onValidate?: () => void;
}) => {
  const {
    state: { selectedOffers },
    computed,
  } = useOrderPageContext();
  const orderSummary = computed.orderSummary!;
  const visible = hasAnyOffer(selectedOffers);
  return (
    <SingleItemOpacityTransition visible={visible}>
      {(opacity) => (
        <animated.section id="cart" className="cart" style={{ opacity }}>
          <CartProgressContent
            canValidate={canValidate}
            onValidate={onValidate}
            orderSummary={orderSummary}
          />
        </animated.section>
      )}
    </SingleItemOpacityTransition>
  );
};

export const CartOffersPart = ({
  orderSummary,
}: {
  orderSummary: OrderSummaryComputed;
}) => {
  return (
    <>
      {orderSummary.offers.photo && (
        <CartPhotoOffer
          offerPhoto={orderSummary.offers.photo}
          options={orderSummary.offers.photoOptions}
          price={orderSummary.photoTotalPrice}
          time={orderSummary.photoTotalTime}
          nb={orderSummary.photoTotalNb}
        />
      )}
      {orderSummary.offers.video && (
        <CartVideoOffer
          offerVideo={orderSummary.offers.video}
          offerVideoConfig={orderSummary.offers.videoConfig!}
          price={orderSummary.videoTotalPrice}
          time={orderSummary.videoTotalTime}
        />
      )}
      {orderSummary.offers.scan && (
        <CartScanOffer
          offerScan={orderSummary.offers.scan}
          offerScanConfig={orderSummary.offers.scanConfig!}
          price={orderSummary.scanTotalPrice}
        />
      )}

      {orderSummary.offers.floorPlan && (
        <CartFloorPlanOffer
          offerFloorPlan={orderSummary.offers.floorPlan}
          offerFloorPlanConfig={orderSummary.offers.floorPlanConfig!}
          price={orderSummary.floorPlanTotalPrice}
        />
      )}
      {orderSummary.offers.custom && (
        <CartCustomOffer
          offerCustom={orderSummary.offers.custom}
          offerScanConfig={orderSummary.offers.scanConfig}
        />
      )}
    </>
  );
};

export default CartProgress;

export const CartProgressSticky = ({
  sticky,
  canBeSticky,
  ...props
}: React.ComponentProps<typeof CartProgress> & {
  sticky: Parameters<typeof getStickyStyle>[0];
  canBeSticky: boolean;
}) => (
  <Sticky disableCompensation>
    {() => {
      const stickyStyle = getStickyStyle(sticky);
      return (
        <div style={canBeSticky ? stickyStyle : {}}>
          <CartProgress {...props} />
        </div>
      );
    }}
  </Sticky>
);
