import React, { ReactNode, useContext } from 'react';
const AdminModeContext = React.createContext(false);

export const AdminModeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AdminModeContext.Provider value={true}>
      {children}
    </AdminModeContext.Provider>
  );
};

// Weither the order is in "admin" mode
// In this mode the admin:
// - does not need to log-in
// - must select the client/user for which he's ordering
// - can select the photographer
// - have different payment options
export const useIsOrderAdminMode = () => {
  return useContext(AdminModeContext);
};
