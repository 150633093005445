import React, { useEffect } from 'react';
import { AppSection } from 'primitives/appSection';
import { H2 } from 'primitives/appTitle';
import { CartProgressSticky } from 'page/order/components/cartProgress';
import ChevronPrevSvg from 'components/svg/picto/chevronPrevSvg';
import { useOrderPageContext } from '../../context/orderPageContext';
import { AppButton } from 'primitives/appButton';
import { StickyContainer } from 'react-sticky';
// @ts-ignore
import { device } from 'helpers/helpers';
import { OrderSummaryComputed } from 'page/order/utils/orderPageUtils';
import { useIntercom } from '../../../../utils/intercomUtils';
import { useAsyncCallback } from 'react-async-hook';
import { NextAvailability } from './stepBookNextAvailability';
import { UnavailableSlotBanner } from './stepBookUnavailableSlotBanner';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import { ShText, useShTranslate } from '@shoootin/translations';
import {
  getFrontMessage,
  getFrontMessageUnsafe,
} from '../../../../appMessageDefinitions';
import { OrderStepLabels } from '../../orderPageTranslations';
import OrderBack from '../../components/orderBack';
import { FrontCommonMessages } from '../../../../appCommonTranslations';
import { join } from 'lodash';
import { ShCountryConfigs, ShShootingType } from '@shoootin/config';

import {
  ShButton,
  ShDatePicker,
  ShInputCheckbox,
  ShInputNumberRange,
  ShInputText,
  ShOrderStepBook,
  ShPrice,
  ShShootingSlot,
  ShSpinner,
  StarSvg,
} from '@shoootin/components-web';
import { ShColors, ShFonts } from '@shoootin/design-tokens';

import { ShInfosDTO, ShShootingFieldDTO } from '@shoootin/api';
import { useCurrentClientUser } from '../../../../state/currentUserState';
import { minuteToHours, ShMoment } from '@shoootin/utils';

const getOfferElementsForIntercomSummary = (
  orderSummary: OrderSummaryComputed,
  shootingType: ShShootingType,
  infos: ShInfosDTO,
  clientExtraFields: ShShootingFieldDTO[],
  clientExtraFieldsMap: Record<string, string | boolean>,
) => {
  const translate = useShTranslate();
  const elements = [];

  elements.push(
    `Type : ${translate({
      id: 'enums_typesOfShooting_short_' + shootingType,
    })}`,
  );

  if (orderSummary.offers.photo) {
    elements.push(`-----   ${translate(FrontCommonMessages.photo)}`);
    elements.push(
      `${orderSummary.offers.photo.name} : ${translate(
        FrontCommonMessages.nPhotos,
        {
          n: orderSummary.offers.photo.photo.photosNb,
        },
      )}`,
    );
    if (orderSummary.offers.photoOptions) {
      orderSummary.offers.photoOptions.map((option) =>
        elements.push(
          `${translate(
            getFrontMessageUnsafe(`enums_optionCategories_${option.category}`),
          )} : ${translate(FrontCommonMessages.nPhotos, {
            n: option.photosNb,
          })}`,
        ),
      );
    }
  }
  if (orderSummary.offers.video) {
    elements.push(`-----   ${translate(FrontCommonMessages.video)}`);
    elements.push(orderSummary.offers.video.name);
  }
  if (orderSummary.offers.scan && orderSummary.offers.scanConfig) {
    const metricSystem =
      ShCountryConfigs[orderSummary.offers.scan.country].metricSystem;
    elements.push(`-----   ${translate(FrontCommonMessages.scan)}`);
    elements.push(orderSummary.offers.scan.name);
    elements.push(
      `Surface : ${orderSummary.offers.scanConfig.surface}${
        metricSystem ? 'm2' : 'sqft'
      }`,
    );
    elements.push(`Zones : ${orderSummary.offers.scanConfig.zonesToScan}`);
  }
  if (orderSummary.offers.custom) {
    elements.push(
      `-----   ${translate(getFrontMessage('form_offer_tailorMadeOffer'))}`,
    );
    elements.push(orderSummary.offers.custom.name);

    if (orderSummary.offers.custom.photo) {
      elements.push(
        `--   ${translate(FrontCommonMessages.nPhotos, {
          n: orderSummary.offers.custom.photo.photosNb,
        })}`,
      );
    }
    if (orderSummary.offers.custom.video) {
      elements.push(`--   ${translate(FrontCommonMessages.video)}`);
    }
    if (orderSummary.offers.custom.scan && orderSummary.offers.scanConfig) {
      const metricSystem =
        ShCountryConfigs[orderSummary.offers.custom.country].metricSystem;

      elements.push(`--   ${translate(FrontCommonMessages.scan)}`);
      elements.push(
        `Surface : ${orderSummary.offers.scanConfig.surface}${
          metricSystem ? 'm2' : 'sqft'
        }`,
      );
      elements.push(`Zones : ${orderSummary.offers.scanConfig.zonesToScan}`);
    }
  }

  if (clientExtraFieldsMap && clientExtraFields) {
    Object.keys(clientExtraFieldsMap).forEach((key: string) => {
      clientExtraFields
        .filter((each) => each.id === key)
        .forEach((each) => {
          elements.push(`${each.name} : ${clientExtraFieldsMap[key]}`);
        });
    });
    console.debug(clientExtraFieldsMap, clientExtraFields);
  }
  // TODO shootingFields

  if (infos.comment) {
    elements.push(`Comment : ${infos.comment}`);
  }
  if (infos.reference) {
    elements.push(`Ref : ${infos.reference}`);
  }
  if (!infos.contactOnSite && infos.contactName && infos.contactPhoneNumber) {
    elements.push(`Contact : ${infos.contactName} ${infos.contactPhoneNumber}`);
  }
  if (!infos.meetDownstairs && infos.accessInformations) {
    elements.push(`Access : ${infos.accessInformations}`);
  }

  console.debug('getOfferElementsForIntercomSummary : ', orderSummary);
  console.log(join(elements, '\n'));
  return join(elements, '\n');
};

export type IntercomMessageNoAvailability = {
  selectedDay: string;
  address: string;
  offer: string;
  preferredDates?: string;
};

const IfNoAvailability = ({
  intercomNoAvailabilityValues,
}: {
  intercomNoAvailabilityValues: IntercomMessageNoAvailability;
}) => {
  const intercom = useIntercom();
  return (
    <div
      className="form-calendar-ifNoAvailability"
      // css={{ fontSize: 14, marginTop: 24, marginBottom: 24 }}
    >
      <ShText message={ShOrderStepBook.ifNoAvailability} />
      <br />
      <a
        css={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={() =>
          intercom.showNoAvailabilityForSelectedDay(
            intercomNoAvailabilityValues,
          )
        }
      >
        <ShText message={getFrontMessage('common_actions_contactUs')} />
      </a>
    </div>
  );
};

const AvailabilityLoading = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 200,
      }}
    >
      <ShSpinner size="m" />
    </div>
  );
};

const SendRequest = ({
  preferredDate,
  setPreferredDateAtIndex,
  hasAtLeastOneDate,
  intercomNoAvailabilityValues,
}: {
  preferredDate: string[];
  setPreferredDateAtIndex: (index: number, value: string) => void;
  hasAtLeastOneDate: boolean;
  intercomNoAvailabilityValues: IntercomMessageNoAvailability;
}) => {
  const intercom = useIntercom();

  return (
    <>
      <div
        css={{
          fontFamily: ShFonts.secondary,
          fontSize: 18,
          marginBottom: 10,
        }}
      >
        <ShText message={ShOrderStepBook.noAvailabilityAtAllTitle} />
      </div>
      <div css={{ marginBottom: 20 }}>
        <ShText message={ShOrderStepBook.noAvailabilityAtAll} />
      </div>

      <div css={{ width: '100%', marginBottom: 10 }}>
        {[0, 1, 2].map((each) => {
          return (
            <div css={{ marginBottom: 5 }} key={each}>
              <ShInputText
                value={preferredDate[each]}
                onChange={(e) => setPreferredDateAtIndex(each, e.target.value)}
                placeholder={`${each} preferred date and time`}
              />
            </div>
          );
        })}
      </div>

      <div css={{ textAlign: 'right', width: '100%' }}>
        <ShButton
          disabled={!hasAtLeastOneDate}
          onClick={() =>
            intercom.showNoAvailabilityForSelectedDay(
              intercomNoAvailabilityValues,
            )
          }
        >
          <ShText message={'common_actions_noAvailabilityContact'} />
        </ShButton>
      </div>
    </>
  );
};

const Calendar = () => {
  const {
    api: { getAdminSlotDetails },
    state: {
      admin,
      shootingType,
      selectedDay,
      availabilities,
      selectedSlot,
      timeZone,
      currency,
      address,
      clientExtraFields,
      clientExtraFieldsMap,
      infos,
    },
    api: { selectDay, selectShootingSlot },
    computed,
  } = useOrderPageContext();
  const orderSummary = computed.orderSummary!;

  const selectDayAsync = useAsyncCallback(selectDay);
  const isAdmin = useIsOrderAdminMode();

  // Due to cancellation in order api (using onlyResolvesLast() helper), some "selectShootingSlot" calls
  // never resolves, and slot spinners keep being rendered indefinitely
  // We need to store the async callback here so that at most
  // one shooting slot has a spinner
  const selectShootingSlotAsyncCallback = useAsyncCallback(selectShootingSlot);
  // Reset the async slot selection state on day change
  useEffect(() => {
    selectShootingSlotAsyncCallback.reset();
  }, [selectDayAsync.result]);

  // const [preferredDate, setPreferredDate] = useState<string[]>([
  //   '', // selectedDay.format('LL'),
  //   '',
  //   '',
  // ]);
  // const setPreferredDateAtIndex = (index: number, value: string) => {
  //   setPreferredDate((s) => {
  //     const newArray = [...s];
  //     newArray[index] = value;
  //     return newArray;
  //   });
  // };
  //
  // const hasAtLeastOneDate =
  //   preferredDate.filter((each) => each !== '').length > 0;

  const intercomNoAvailabilityValues: IntercomMessageNoAvailability = {
    selectedDay: selectedDay.format('LL'),
    address: address!.address,
    offer: getOfferElementsForIntercomSummary(
      orderSummary,
      shootingType!,
      infos,
      clientExtraFields,
      clientExtraFieldsMap,
    ),
    // preferredDates: preferredDate.filter((each) => each !== '').join(','),
  };

  const clientUser = useCurrentClientUser();
  console.log('ClientUser For StepBook', { clientUser });
  const intercom = useIntercom();

  return (
    <div className="calendar" id="form-calendar">
      <div id="form-calendar-datepicker">
        <ShDatePicker
          date={ShMoment(selectedDay)}
          onDateChange={(newDate) => selectDayAsync.execute(newDate)}
          firstAvailableDate={isAdmin ? undefined : ShMoment()} // TODO isAdmin
        />
        {clientUser && clientUser.canSeePreferredPhotographers && (
          <div
            css={{
              fontSize: 14,
              marginTop: 12,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span css={{ marginRight: 5, marginTop: 5 }}>
              <StarSvg width={14} color={'black'} />
            </span>
            <ShText message={ShOrderStepBook.preferredPhotographers} />
          </div>
        )}
        <div css={{ fontSize: 14, marginTop: 12, marginBottom: 12 }}>
          <ShText
            message={ShOrderStepBook.currentTimezone}
            values={{ timeZone: <b>{timeZone}</b> }}
          />
        </div>
        {isAdmin && <AdminBookSettings />}
        {!isAdmin && availabilities!.availabilities.length > 0 && (
          <IfNoAvailability
            intercomNoAvailabilityValues={intercomNoAvailabilityValues}
          />
        )}
      </div>

      <div id="form-calendar-timepicker">
        <div className="picker-time">
          {selectDayAsync.loading ? (
            <AvailabilityLoading />
          ) : (
            <>
              {availabilities!.availabilities.length > 0 && (
                <>
                  {availabilities!.emptyDays.length > 0 && (
                    <NextAvailability
                      timeZone={timeZone}
                      selectedDay={ShMoment(selectedDay)}
                      emptyDays={availabilities!.emptyDays}
                      intercomNoAvailabilityValues={
                        intercomNoAvailabilityValues
                      }
                      onNextAvailabilityPress={(nextAvailability) => {
                        selectDay(nextAvailability);
                      }}
                    />
                  )}
                  <div
                    css={{
                      marginBottom: '1em',
                      width: '100%',
                      // fontFamily: ShFonts.secondary,
                      // textAlign: 'center',
                    }}
                  >
                    <ShText
                      message={ShOrderStepBook.availabilityOf}
                      values={{
                        day: ShMoment(availabilities!.availabilities[0].start)
                          .tz(timeZone)
                          .format('LL'),
                      }}
                    />
                  </div>

                  {availabilities!.availabilities.map((shootingSlot) => {
                    const loading: boolean = !!(
                      selectShootingSlotAsyncCallback.loading &&
                      selectShootingSlotAsyncCallback.currentParams &&
                      selectShootingSlotAsyncCallback.currentParams[1] ===
                        shootingSlot
                    );
                    return (
                      <ShShootingSlot
                        loading={loading}
                        timeZone={timeZone}
                        key={shootingSlot.start}
                        active={
                          !!selectedSlot &&
                          selectedSlot.start === shootingSlot.start
                        }
                        shootingSlot={shootingSlot}
                        onSlotSelected={(photographer) =>
                          selectShootingSlotAsyncCallback.execute(
                            selectedDay,
                            shootingSlot,
                            photographer,
                          )
                        }
                        getAdminSlotDetails={getAdminSlotDetails}
                        photographerId={admin?.photographer?.id}
                      />
                    );
                  })}
                </>
              )}
              {availabilities!.availabilities.length === 0 && (
                <>
                  {/* <SendRequest
                    intercomNoAvailabilityValues={intercomNoAvailabilityValues}
                    hasAtLeastOneDate={hasAtLeastOneDate}
                    preferredDate={preferredDate}
                    setPreferredDateAtIndex={setPreferredDateAtIndex}
                  /> */}
                  <div
                    css={{
                      fontFamily: ShFonts.secondary,
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    <ShText
                      message={ShOrderStepBook.noAvailabilityAtAllTitle}
                    />
                  </div>
                  <div css={{ marginBottom: 20 }}>
                    <ShText message={ShOrderStepBook.noAvailabilityAtAll} />
                  </div>
                  <div css={{ textAlign: 'center', width: '100%' }}>
                    <a
                      css={{ cursor: 'pointer', fontWeight: 'bold' }}
                      onClick={() =>
                        intercom.showNoAvailabilityForSelectedDay(
                          intercomNoAvailabilityValues,
                        )
                      }
                    >
                      <ShButton>
                        <ShText
                          message={'common_actions_noAvailabilityContact'}
                        />
                      </ShButton>
                    </a>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="calendar__more">
          <div className="calendar__time">
            <ShText message={getFrontMessage('common_order_duration')} />{' '}
            {minuteToHours(orderSummary.totalTime)}
          </div>
          <div className="calendar__price">
            <ShPrice price={orderSummary.totalPrice} currency={currency} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminBookSettings = () => {
  const {
    state: { admin },
    api: { setAdminSettings },
  } = useOrderPageContext();
  const settings = admin!.settings;

  return (
    <div
      css={{
        padding: 10,
        marginTop: 20,
        border: `1px solid ${ShColors.disabled}`,
      }}
    >
      <div css={{ marginBottom: 5 }}>
        <b>Search radius</b>
        <ShInputNumberRange
          value={settings ? settings.photographerRadiusSearch : 35}
          onChange={(value) =>
            setAdminSettings({
              ...settings!,
              photographerRadiusSearch: value,
            })
          }
          step={50}
          min={0}
          max={10000}
        />
      </div>
      <div css={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <ShInputCheckbox
          value={settings ? settings!.adminConstraintsActive : true}
          onChange={(value) =>
            setAdminSettings({
              ...settings!,
              adminConstraintsActive: value,
            })
          }
        />
        <b>Active constraints</b>
      </div>
    </div>
  );
};

const StepBook = () => {
  const {
    state: { timeZone, unavailableSlot, isSelectedSlotValid },
    api: { validateStepBook },
    steps,
    isCheckout,
  } = useOrderPageContext();

  const canValidateStep = !!isSelectedSlotValid;
  return (
    <>
      <AppSection
        className="left form-book"
        header={
          <H2>
            <ShText message={OrderStepLabels.book} />
          </H2>
        }
      >
        <OrderBack />
        {unavailableSlot && (
          <UnavailableSlotBanner
            unavailableSlot={unavailableSlot}
            timeZone={timeZone}
          />
        )}
        {!unavailableSlot && (
          <p className="form-payment-intro">
            <ShText message={ShOrderStepBook.intro} />
          </p>
        )}
        <StickyContainer>
          <div className="form-wrapper" id="form-order-book">
            <div className="form-details">
              <Calendar />
            </div>
            <div className="form-cart form-cart-book">
              <CartProgressSticky
                sticky={{
                  wrapper: '#form-order-book',
                  wrapperFirstElement: '.form-details',
                  wrapperFirstElementSetMinHeight: true,
                  header: '.form-breadcrumbs',
                  cart: '#cart',
                }}
                canBeSticky={!device().isMobile && !isCheckout}
                canValidate={canValidateStep}
                onValidate={validateStepBook}
              />
            </div>

            <div className="form-navigation opened">
              <a
                className="form-navigation__link"
                onClick={() => steps.previous()}
              >
                <ChevronPrevSvg />{' '}
                <ShText message={getFrontMessage('common_actions_previous')} />
              </a>
              <AppButton
                modifier="large"
                onClick={validateStepBook}
                disabled={!canValidateStep}
              >
                <ShText message={getFrontMessage('common_actions_validate')} />
              </AppButton>
            </div>
          </div>
        </StickyContainer>
      </AppSection>
    </>
  );
};

export default StepBook;
