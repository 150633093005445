import React, { useEffect, useState } from 'react';

import { H3, H4 } from 'primitives/appTitle';

import { useOrderPageContext } from '../../context/orderPageContext';
import classnames from 'classnames';

import { ShText } from '@shoootin/translations';
import {
  ShHelpModalButton,
  ShPaymentMethodTilesConfig,
  ShPrice,
  ShSavedCreditCard,
  ShCreditCardInput,
  ShOrderStepPayment,
  ShTiles,
} from '@shoootin/components-web';
import { OrderSummaryDTO } from 'appAPITypes';
import { getFrontMessage } from 'appMessageDefinitions';
import { OrderStepPayment } from '../../orderPageTranslations';
import { ShPaymentMethod } from '@shoootin/config';

import { ShCreditCard, ShUnlimited, ShCredit } from '@shoootin/api';
import { isDev } from '../../../../appEnv';
import { ShColors } from '@shoootin/design-tokens';

// TODO use ShTiles
const PaymentMethodTiles = ({
  availableMethods,
}: {
  availableMethods: ShPaymentMethod[];
}) => {
  const {
    state: { selectedPaymentMethod },
    api: { selectPaymentMethod },
  } = useOrderPageContext();
  return (
    <ShTiles<ShPaymentMethod>
      tiles={availableMethods}
      activeTiles={selectedPaymentMethod ? [selectedPaymentMethod] : []}
      onTilePress={selectPaymentMethod}
      renderTileLabel={(tile) => ShPaymentMethodTilesConfig[tile].label}
    />
  );
};

const StepPaymentHeader = ({
  availableMethods,
}: {
  availableMethods: ShPaymentMethod[];
}) => {
  return (
    <div className="form-section">
      <H3>
        <ShText message={ShOrderStepPayment.paymentMethodTitle} />
      </H3>
      {availableMethods.length > 1 && (
        <>
          <p className="form-payment-intro">
            <ShText message={ShOrderStepPayment.paymentMethodIntro} />
          </p>
          <PaymentMethodTiles availableMethods={availableMethods} />
        </>
      )}
    </div>
  );
};

export const StepPaymentMethods = ({
  summary,
}: {
  summary: OrderSummaryDTO;
}) => {
  const {
    state: { selectedPaymentMethod },
  } = useOrderPageContext();

  return (
    <>
      <StepPaymentHeader availableMethods={summary.paymentMethods} />

      {selectedPaymentMethod === 'CREDIT_CARD' && (
        <>
          {summary.cards.length > 0 && (
            <PaymentBySavedCard cards={summary.cards} />
          )}
          <PaymentByNewCard />
        </>
      )}

      {selectedPaymentMethod === 'CREDIT' && (
        <PaymentByCredit credit={summary.credit} />
      )}
      {selectedPaymentMethod === 'UNLIMITED' && (
        <PaymentALaCarte unlimited={summary.unlimited} />
      )}
      {selectedPaymentMethod === 'ORDER' && <PaymentOrder />}
      {selectedPaymentMethod === 'INVOICE' && <PaymentInvoice />}
    </>
  );
};

const PaymentBySavedCard = ({ cards }: { cards: ShCreditCard[] }) => {
  const {
    api: { selectCreditCard },
    state: { selectedCreditCardId },
  } = useOrderPageContext();

  return (
    <div className="form-section">
      <H4>
        <ShText
          message={ShOrderStepPayment.paymentMethodSavedCreditCardTitle}
        />
        <ShHelpModalButton modalName="paymentExistingCreditCard" />
      </H4>
      <div className="input-accordion form-payment-card">
        {cards.map((card, index) => (
          <div key={card.id} css={index > 0 ? { marginTop: 20 } : {}}>
            <ShSavedCreditCard
              selected={
                !!selectedCreditCardId && selectedCreditCardId === card.id
              }
              onSelect={() => selectCreditCard(card.id)}
              card={card}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const PaymentByNewCard = () => {
  const {
    api: { deleteCreditCard, setPaymentError, setSaveCreditCard },
    state: { selectedCreditCardId, saveCreditCard, validationErrors },
  } = useOrderPageContext();

  // Hacky way to "reset" the stripe input when an existing credit card is selected
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (selectedCreditCardId) {
      setKey((k) => k + 1);
    }
    setPaymentError(undefined);
  }, [selectedCreditCardId]);

  return (
    <div className="form-section border">
      <H4>
        <ShText
          message={getFrontMessage('enums_PaymentMethods_title_CREDIT_CARD')}
        />
        {/* <HelpModalButton modalName="paymentNewCreditCard" /> */}
      </H4>
      <ShCreditCardInput
        key={key}
        onChange={() => {
          deleteCreditCard();
          setPaymentError(undefined);
        }}
        setSaveCreditCard={setSaveCreditCard}
        saveCreditCard={saveCreditCard}
        paymentErrors={validationErrors?.paymentErrors}
        isDev={isDev}
      />
      {/* <CreditCardInput
        key={key}
        onChange={() => {
          deleteCreditCard();
          setPaymentError(undefined);
        }}
      /> */}
    </div>
  );
};

const PaymentByCredit = ({ credit }: { credit: ShCredit }) => {
  return (
    <div className="form-section border">
      <H4>
        <ShText
          message={getFrontMessage('enums_PaymentMethods_title_CREDIT')}
        />
        <ShHelpModalButton modalName="paymentCredit" />
      </H4>
      <div>
        <ShText
          message={ShOrderStepPayment.paymentMethodCreditDescription}
          values={{
            credit: (
              <ShPrice
                price={credit.currentWithoutTax}
                currency={credit.currency}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

const PaymentALaCarte = ({ unlimited }: { unlimited: ShUnlimited }) => {
  return (
    <div className="form-section border">
      <H4>
        <ShText
          message={getFrontMessage('enums_PaymentMethods_title_UNLIMITED')}
        />
        <ShHelpModalButton modalName="paymentUnlimited" />
      </H4>
      <div>
        <ShText
          message={ShOrderStepPayment.paymentMethodUnlimitedDescription}
          values={{
            current: (
              <ShPrice
                price={unlimited.current}
                currency={unlimited.currency}
              />
            ),
            limit: (
              <ShPrice price={unlimited.limit} currency={unlimited.currency} />
            ),
          }}
        />
      </div>
    </div>
  );
};

const PaymentOrder = () => {
  return (
    <div className="form-section border">
      <H4>
        <ShText message={getFrontMessage('enums_PaymentMethods_title_ORDER')} />
        <ShHelpModalButton modalName="paymentOrder" />
      </H4>
      <div>
        <ShText message={OrderStepPayment.paymentMethodOrderInstructions} />
      </div>
    </div>
  );
};

const PaymentInvoice = () => {
  return (
    <div className="form-section border">
      <H4>
        <span css={{ color: ShColors.error }}>Warning - Invoice payment</span>
      </H4>
      <div>
        This payment method <b css={{ color: ShColors.error }}>must</b> be used
        in <b css={{ color: ShColors.error }}>last resort</b> and{' '}
        <b css={{ color: ShColors.error }}>must be validated</b> by an
        administrator. <br />
        <br />
        Thanks.
      </div>
    </div>
  );
};
