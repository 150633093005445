import React, { useEffect } from 'react';
import { AppSection } from 'primitives/appSection';
import { H3, H4 } from 'primitives/appTitle';
import { CartProgressSticky } from 'page/order/components/cartProgress';
import { useOrderPageContext } from 'page/order/context/orderPageContext';
import { OffersByType } from 'page/order/utils/orderPageUtils';
import StepOrderScan from './stepOrderScan';
import StepOrderPhoto from './stepOrderPhoto';
import StepOrderVideo from './stepOrderVideo';
import StepOrderCustom from './stepOrderCustom';
import StepOrderMap from './stepOrderMap';
import StepOrderInfos from './stepOrderInfos';
import StepOrderMeet from './stepOrderMeet';
import StepOrderShootingType from './stepOrderShootingType';
import { StickyContainer } from 'react-sticky';
// @ts-ignore
import { device } from 'helpers/helpers';
import { ShHelpModalButton, ShSpinner } from '@shoootin/components-web';
import { ShText } from '@shoootin/translations';

import { OrderCheckout, OrderStepOrder } from '../../orderPageTranslations';
import OrderErrorBanner from '../../components/orderErrorBanner';
import { useCurrentClientUser } from '../../../../state/currentUserState';
import { useLogoutAction } from '../../../../appAuth';
import { AppButton } from '../../../../primitives/appButton';

import classnames from 'classnames';
import { useOrderPageBranding } from '../../utils/orderPageBrandings';
import StepOrderBilling from './stepOrderBilling';
import StepOrderFloorPlan from './stepOrderFloorplan';
import StepOrderPortraitForm from './specials/portrait/stepOrderPortraitForm';
import { gtaEventBeginCheckout } from '../../../../appAnalytics';
import { StepOrderDroneSuccess } from './specials/drone/stepOrderDroneForm';
import StepOrderDrone from './stepOrderDrone';
import StepOrderBranding from './stepOrderBranding';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import StepOrderUser from './stepOrderUser';

const ShootingOfferSection = ({
  offersByType,
  onValidate,
  formCartOpened,
}: {
  offersByType: OffersByType;
  onValidate: () => void;
  formCartOpened: boolean;
}) => {
  const hasBasicOffers =
    offersByType.photo.length > 0 ||
    offersByType.video.length > 0 ||
    offersByType.scan;

  const hasDroneOffers = offersByType.drone.length > 0;

  const hasAddons = !!offersByType.floorPlan;

  const { isCheckout } = useOrderPageContext();

  return (
    <StickyContainer>
      <AppSection className="left form-order-prestations">
        <div className="form-wrapper" id="form-order-offers">
          <div className="form-order-prestations__content form-details">
            {offersByType.custom.length > 0 && (
              <StepOrderCustom customOffers={offersByType.custom} />
            )}
            {hasBasicOffers && (
              <>
                <H3>
                  <ShText message={OrderStepOrder.classicOffers} />
                  <ShHelpModalButton modalName="offerBasic" />
                </H3>
                {offersByType.photo.length > 0 && (
                  <StepOrderPhoto photoOffers={offersByType.photo} />
                )}
                {offersByType.video.length > 0 && (
                  <StepOrderVideo videoOffers={offersByType.video} />
                )}
                {offersByType.scan && (
                  <StepOrderScan scanOffer={offersByType.scan} />
                )}
              </>
            )}
            {hasAddons && (
              <>
                <H4>
                  <ShText message={OrderStepOrder.addOns} />
                  {/* <ShHelpModalButton modalName="offerBasic" /> */}
                </H4>
                {offersByType.floorPlan && (
                  <StepOrderFloorPlan floorPlanOffer={offersByType.floorPlan} />
                )}
              </>
            )}
            {hasDroneOffers && (
              <>
                <H3>
                  <ShText message={OrderStepOrder.droneOffers} />
                  {/* <ShHelpModalButton modalName="offerBasic" /> TODO intercom article */}
                </H3>

                {offersByType.drone.length > 0 && (
                  <StepOrderDrone offers={offersByType.drone} />
                )}
              </>
            )}

            <StepOrderMeet />
            <StepOrderInfos />
            <StepOrderUser />
            <StepOrderBilling />
          </div>

          <div
            className={classnames('form-cart form-cart-order', {
              opened: formCartOpened, // this seems to be used to open the mobile sticky footer...
            })}
          >
            <CartProgressSticky
              sticky={{
                wrapper: '#form-order-offers',
                wrapperFirstElement: '.ShSwitchBox',
                header: '.form-breadcrumbs',
                cart: '#cart',
              }}
              canBeSticky={
                !device().isMobile && !device().isTablet && !isCheckout
              }
              canValidate={true}
              onValidate={onValidate}
            />
          </div>
        </div>
      </AppSection>
    </StickyContainer>
  );
};

const StepOrder = () => {
  const {
    state: {
      validationErrors,
      address,
      country,
      shootingType,
      offers,
      currency,
      droneState,
    },
    api: { setShootingType, validateStepOrder, validateStepDrone },
    computed: { offersByType, orderSummary, canDisplayPortraitTile },
    isCheckout,
  } = useOrderPageContext();

  const currentUser = useCurrentClientUser();
  const logoutAction = useLogoutAction();
  const branding = useOrderPageBranding();

  const orderShootingsForUsers = !!currentUser?.orderShootingsForUsers;

  const isPortrait = shootingType === 'portrait';
  const isDrone = orderSummary?.offers.custom?.offerType === 'aerial';
  const isAdmin = useIsOrderAdminMode();

  const showCustomDroneForm = isDrone && !isAdmin;

  const onValidate = !showCustomDroneForm
    ? validateStepOrder
    : validateStepDrone;

  const showDroneSuccess = showCustomDroneForm && droneState?.success;

  const dontDisplayOffers = isPortrait;

  useEffect(() => {
    if (orderSummary) {
      gtaEventBeginCheckout(orderSummary, currency);
    }
  }, [orderSummary]);

  if (showDroneSuccess) {
    return (
      <>
        <AppSection>
          <StepOrderDroneSuccess />
        </AppSection>
      </>
    );
  }
  return (
    <>
      {validationErrors && validationErrors.offerForbiddenForUser && (
        <AppSection>
          <OrderErrorBanner>
            <ShText message={OrderStepOrder.offerForbiddenForUser} />
          </OrderErrorBanner>
        </AppSection>
      )}
      {isCheckout && currentUser && (
        <OrderErrorBanner>
          <div css={{ textAlign: 'center' }}>
            <ShText
              message={OrderCheckout.loggedBanner}
              values={{
                firstName: currentUser.firstName,
                email: currentUser.email,
              }}
            />
            <AppButton
              onClick={async () => {
                await logoutAction();
                // TODO this is a bit hacky: for now we need to refresh the page to ensure steps are reset
                // (otherwise the login step do not appear
                window.location.reload();
              }}
            >
              <ShText message={OrderCheckout.loggedChangeAccount} />
            </AppButton>
          </div>
        </OrderErrorBanner>
      )}

      {branding && <StepOrderBranding branding={branding} />}
      <StepOrderMap />

      {address && !offers && <ShSpinner />}

      {offers && (
        <StepOrderShootingType
          offers={offers}
          shootingType={shootingType}
          setShootingType={setShootingType}
          canDisplayPortraitTile={canDisplayPortraitTile}
        />
      )}
      {!dontDisplayOffers && offers && shootingType && (
        <ShootingOfferSection
          key={shootingType} // Ensure full reset on shootingType change
          offersByType={offersByType!}
          onValidate={onValidate}
          formCartOpened={orderSummary ? orderSummary.totalPrice > 0 : false}
        />
      )}
      {isPortrait && (
        <StepOrderPortraitForm
          key={shootingType}
          address={address!}
          country={country}
        />
      )}
    </>
  );
};

export default StepOrder;
