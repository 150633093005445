import React, { ComponentType, useMemo, useState } from 'react';
import { StepItem } from '../components/steps';
import { useHeaderSize } from 'layout/mainLayout';
import { useShTranslate } from '@shoootin/translations';
import { OrderStepLabels } from '../orderPageTranslations';
import { useCurrentClientUser } from 'state/currentUserState';
import useConstant from 'use-constant';
import StepOrder from '../steps/order/stepOrder';
import StepBook from '../steps/book/stepBook';
import StepLogin from '../steps/stepLogin';
import StepPayment from '../steps/payment/stepPayment';
import StepConfirmation from '../steps/stepConfirmation';
import { useIsOrderAdminMode } from '../utils/orderPageAdminUtils';

const OrderStepList = [
  'order',
  'book',
  'login',
  'payment',
  'confirmation',
] as const;

export type OrderStep = typeof OrderStepList[number];

export type StepComponent = ComponentType<{}>;

export const StepComponents: { [key in OrderStep]: StepComponent } = {
  order: StepOrder,
  book: StepBook,
  login: StepLogin,
  payment: StepPayment,
  confirmation: StepConfirmation,
};

// We guarantee that the list of steps remains the same across the whole order process
// It would be weird if after login, the login step disappeared from the steps breadcrumb
const useConstantStepList = (): Readonly<OrderStep[]> => {
  const currentUser = useCurrentClientUser();

  const isAdminMode = useIsOrderAdminMode();

  return useConstant(() => {
    if (currentUser || isAdminMode) {
      return OrderStepList.filter(step => step !== 'login');
    } else {
      return OrderStepList;
    }
  });
};

export const useOrderSteps = (initialStep?: OrderStep) => {
  const steps = useConstantStepList();
  const translate = useShTranslate();
  const { height: headerHeight } = useHeaderSize();
  const initialStepIndex = initialStep ? steps.indexOf(initialStep) : 0;
  const [stepIndex, setStepIndexState] = useState(initialStepIndex);

  const currentStep: OrderStep = steps[stepIndex];

  return useMemo(() => {
    const hasNext = stepIndex < steps.length - 1;
    const hasPrevious = stepIndex > 0;

    const setStepIndex = (index: number) => {
      window.scrollTo({
        top: headerHeight,
      });
      setStepIndexState(index);
    };

    const stepItems: StepItem[] = steps.map((step, index) => {
      const label = translate(OrderStepLabels[step]);
      const modifier =
        index === stepIndex
          ? 'current'
          : index > stepIndex
          ? 'next'
          : 'previous';
      const clickable =
        currentStep === 'confirmation' ? false : index < stepIndex;
      return {
        key: step,
        label,
        modifier,
        clickable,
      };
    });

    const handleStepItemClick = (stepItem: StepItem) =>
      setStepIndex(steps.indexOf(stepItem.key));

    const stepComponent = StepComponents[currentStep];

    const goTo = (step: OrderStep) => {
      const index = steps.indexOf(step);
      if (index !== -1) {
        setStepIndex(index);
      } else {
        // Can happen for login step
        console.error(`step ${step} does not exist`);
      }
    };

    return {
      currentStep,
      stepComponent,
      stepItems,
      handleStepItemClick,
      hasNext,
      hasPrevious,
      next: () => hasNext && setStepIndex(stepIndex + 1),
      previous: () => hasPrevious && setStepIndex(stepIndex - 1),
      goTo,
    };
  }, [currentStep, stepIndex, setStepIndexState, headerHeight]);
};

export type OrderPageStepsAPI = ReturnType<typeof useOrderSteps>;
