import React, { ReactNode } from 'react';
import { ShColors } from '@shoootin/design-tokens';

const OrderErrorBanner = ({ children }: { children: ReactNode }) => {
  return (
    <div
      css={{
        padding: 20,
        backgroundColor: ShColors.base,
        borderRadius: 3,
        color: 'white',
        marginTop: 10,
      }}
    >
      {children}
    </div>
  );
};

export default OrderErrorBanner;
