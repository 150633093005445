import React from 'react';

import { useOrderPageContext } from 'page/order/context/orderPageContext';
import {
  useOfferCustomComponentsString,
  useOrderCurrency,
} from 'page/order/utils/orderPageUtils';
import { ShText } from '@shoootin/translations';
import { FrontCommonMessages } from 'appCommonTranslations';
import StepOrderSwitchBox, {
  useStepOrderCustomDroneOfferSwitchBox,
} from './stepOrderSwitchBox';
import { ShootingFields } from './stepOrderFields';
import { getFrontMessage } from '../../../../appMessageDefinitions';
import {
  ShOfferCollapse,
  ShPrice,
  ShSwitchBoxElementCustomComponentHeader,
  ShSwitchBoxElementPanelHeader,
  ShSwitchBoxElementWrapper,
  ShTitle,
} from '@shoootin/components-web';
import StepOrderOfferAdminInformations from './stepOrderOfferAdminInformations';
import { useIsOrderAdminMode } from '../../utils/orderPageAdminUtils';
import StepOrderDroneForm from './specials/drone/stepOrderDroneForm';
import { ShOfferCustomDTO } from '@shoootin/api';
import { ShMediaQueries } from '@shoootin/design-tokens';
import { minuteToHours } from '@shoootin/utils';

const DroneOffer = ({ offer }: { offer: ShOfferCustomDTO }) => {
  const {
    state: { selectedOffers },
    api: { selectCustomOffer },
  } = useOrderPageContext();
  const isAdmin = useIsOrderAdminMode();
  const currency = useOrderCurrency();
  const customOfferComponents = useOfferCustomComponentsString(offer);
  const time = minuteToHours(offer.baseTime);
  const price = offer.basePrice;
  const checked = selectedOffers.customOfferId === offer.id;

  return (
    <ShOfferCollapse
      value={checked}
      service={'drone'}
      onChange={(value) => {
        value && selectCustomOffer(offer);
      }}
      label={offer.name}
      sublabel={
        <>
          <div>
            {customOfferComponents} - {time}
            {isAdmin && <StepOrderOfferAdminInformations offer={offer} />}
          </div>
          <span className="text-price">
            <ShPrice price={price} currency={currency} />
          </span>
        </>
      }
    >
      <div
        css={{
          // to cancel OfferCollapse padding
          [ShMediaQueries.ShDesktopsOnly]: {
            marginLeft: '-3.125rem',
            marginRight: '-3.125rem',
          },
        }}
      >
        {offer.description && (
          <ShSwitchBoxElementPanelHeader>
            {offer.description}
          </ShSwitchBoxElementPanelHeader>
        )}
        {offer.photo && (
          <>
            <ShSwitchBoxElementCustomComponentHeader
              title={
                <ShText
                  message={getFrontMessage('front_common_nPhoto')}
                  values={{ n: offer.photo.photosNb }}
                />
              }
              service={'photo'}
            />
            {/* <div
            className="switchbox-panel__preview photo"
            css={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <span className="title">
              <ShText
                message={getFrontMessage('front_common_nPhoto')}
                values={{ n: offer.photo.photosNb }}
              />
            </span>
          </div> */}
            <ShSwitchBoxElementWrapper>
              <ShootingFields
                typeOfComponent="photo"
                fields={offer.shootingFields}
              />
            </ShSwitchBoxElementWrapper>
          </>
        )}

        {offer.video && (
          <>
            <ShSwitchBoxElementCustomComponentHeader
              title={<ShText message={FrontCommonMessages.video} />}
              service={'video'}
            />
            {/* <div
            className="switchbox-panel__preview video"
            css={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <span className="title">
              <ShText message={FrontCommonMessages.video} />
            </span>
          </div> */}
            <ShSwitchBoxElementWrapper>
              <ShootingFields
                typeOfComponent="video"
                fields={offer.shootingFields}
              />
            </ShSwitchBoxElementWrapper>
          </>
        )}
        {/* {offer.description && (
        <div
          className="switchbox-panel__help"
          css={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          {offer.description}
        </div>
      )} */}
      </div>
    </ShOfferCollapse>
  );
};

const StepOrderDrone = ({ offers }: { offers: ShOfferCustomDTO[] }) => {
  const {
    state: { selectedOffers },
    api: {
      deletePhotoOffer,
      deleteScanOffer,
      deleteVideoOffer,
      deleteCustomOffer,
    },
    computed: { orderSummary },
  } = useOrderPageContext();

  const [opened, setOpened] = useStepOrderCustomDroneOfferSwitchBox(
    selectedOffers,
  );

  const droneSelected = orderSummary?.offers.custom?.offerType === 'aerial';
  const isAdmin = useIsOrderAdminMode();

  return (
    <StepOrderSwitchBox
      key="drone"
      service="drone"
      onOpenedChange={(opened: boolean) => {
        setOpened(opened);
        if (opened) {
          deletePhotoOffer();
          deleteScanOffer();
          deleteVideoOffer();
        } else {
          deleteCustomOffer();
        }
      }}
      label={
        <ShTitle size={4} color={opened ? 'white' : 'black'}>
          <ShText message={FrontCommonMessages.drone} />
        </ShTitle>
      }
      opened={opened}
    >
      <div>
        {offers &&
          offers.map((offer) => <DroneOffer key={offer.id} offer={offer} />)}

        {droneSelected && !isAdmin && <StepOrderDroneForm />}
      </div>

      {/* <SwitchHelp modalName="offerPhoto" /> */}
    </StepOrderSwitchBox>
  );
};

export default StepOrderDrone;
